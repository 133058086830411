import React, { useState } from "react";
import "../../assets/styles/main.scss";

interface props {
  isConfirmationOpen: boolean;
  onConfirmationClose: () => void;
  handleAction: (state) => void;
  title: string;
  message: string;
   state:{
    uuid:string
    action:string,
    email:string
   }
}

export const ConfirmationPopupMakeCoshotOrBlock = ({
  isConfirmationOpen,
  onConfirmationClose,
  title,
  message,
  state,
  handleAction,
}: props) => {
  if (!isConfirmationOpen) {
    return;
  }

  return (
    <div
      className={`modal confirmation-popup ${
        isConfirmationOpen ? "active" : ""
      }`}
    >
      <div className="modal-background"></div>
      <div className="modal-container with-header">
        <div className="modal-header">
          <h3 className="flex">{title}</h3>
          <span className="close-btn" onClick={onConfirmationClose}>
            &times;
          </span>
        </div>
        <div className="modal-content flex">
          <div className="confirmation-info">
            <p>{message}</p>
          </div>
        </div>
        <div className="modal-footer flex">
          <div className="flex">
            <button
              type="button"
              className="default-btn"
              onClick={onConfirmationClose}
            >
              Cancel
            </button>
            <button
              type="button"
              className="warning-button"
              onClick={() => handleAction(state)}
            >
              {title}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
