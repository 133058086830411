import React, { useEffect, useState } from "react";
import Select from "react-select";
import tickMark from "../../assets/images/tick-mark.svg";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import loggly from "../ErrorsHandler/Logger";
import { updateCamera } from "../../Store/Actions/PublishStreamAction";

// set pros
interface props {
  isSetupPopup?: boolean;
}

const VideoInputSelector = ({ isSetupPopup }: props) => {
  const [videoDevices, setVideoDevices] = useState<MediaDeviceInfo[]>([]);
  const dispatch = useDispatch();
  const { state } = useLocation();
  const stateData = useSelector((state: any) => state.PublishStreamReducer);

  function isFirefox() {
    const userAgent = navigator.userAgent.toLowerCase();
    return userAgent.indexOf("firefox") > -1;
  }

  useEffect(() => {
    let deviceChangeTimeout: NodeJS.Timeout;

    const gotDevices = (deviceInfos: MediaDeviceInfo[]) => {
      try {
        const videoInputList: MediaDeviceInfo[] = deviceInfos.filter(
          (device) => device.kind === "videoinput"
        );
        setVideoDevices(videoInputList);
        if (
          stateData?.publishStreamObj?.cameraId === undefined &&
          videoInputList.length > 0
        ) {
          handleSelectChange(
            videoInputList[0].deviceId,
            videoInputList[0].label
          );
        }
      } catch (error) {
        console.error("Error processing video input devices:", error);
        loggly.push({
          tag: "error",
          message: "An error occurred while processing video input devices",
          error: error.message,
        });
      }
    };

    const handleDeviceChange = () => {
      try {
        if (isFirefox()) {
          clearTimeout(deviceChangeTimeout);
        }
        navigator.mediaDevices
          .enumerateDevices()
          .then(gotDevices)
          .catch((error) => {
            console.error("Error enumerating video input devices:", error);
            loggly.push({
              tag: "error",
              message:
                "An error occurred while enumerating video input devices",
              error: error.message,
            });
          });
      } catch (error) {
        console.error("Error handling video input device change:", error);
        loggly.push({
          tag: "error",
          message: "An error occurred while handling video input device change",
          error: error.message,
        });
      }
    };

    handleDeviceChange();

    navigator.mediaDevices.addEventListener("devicechange", handleDeviceChange);

    return () => {
      if (isFirefox()) {
        clearTimeout(deviceChangeTimeout);
      }
      navigator.mediaDevices.removeEventListener(
        "devicechange",
        handleDeviceChange
      );
    };
  }, []);

  const handleSelectChange = (cameraId: string, cameraLabel: string) => {
    try {
      loggly.push({ tag: "Camera", message: "Camera changed" });
      dispatch(updateCamera(cameraId, cameraLabel));
    } catch (error) {
      console.error("Error dispatching camera ID:", error);
      loggly.push({
        tag: "error",
        message: "An error occurred while dispatching camera ID",
        error: error.message,
      });
    }
  };

  return (
    <div className="ew-form-field">
      <label htmlFor="video-dropdown">
        Webcam{" "}
        <span>
          <img src={tickMark} alt="" />
        </span>
      </label>
      <span className="label-sub-heading">
        {isSetupPopup
          ? "Please select your desired webcam to be used in the webinar"
          : "Select your Preferred Camera to conduct this Webinar"}
      </span>
      <Select
        id="dropdown"
        options={videoDevices.map((device) => ({
          value: device.deviceId,
          label: device.label || `Camera ${videoDevices.length + 1}`,
        }))}
        value={
          stateData?.publishStreamObj?.cameraId === undefined
            ? {
                value: videoDevices[0]?.deviceId,
                label: videoDevices[0]?.label,
              }
            : {
                value: stateData?.publishStreamObj?.cameraId,
                label: stateData?.publishStreamObj?.cameraLabel,
              }
        }
        onChange={(selectedOption) => {
          if (selectedOption) {
            handleSelectChange(selectedOption.value, selectedOption.label);
          }
        }}
      />
    </div>
  );
};

export default VideoInputSelector;
