import { Center, Spinner } from "@chakra-ui/react";
import React, { memo } from "react";
import { useEffect, useRef, useState } from "react";
import { Tooltip as ReactTooltip, Tooltip } from "react-tooltip";
import fitScreenIcon from "../../assets/images/fit-screen-icon.svg";
import fillScreenIcon from "../../assets/images/fill-screen-icon.svg";
import refreshIcon from "../../assets/images/refresh-icon.svg";
import refreshLineIcon from "../../assets/images/refresh-line-icon.svg";
import verticallyDotsIcon from "../../assets/images/vertically-dots.svg";
import { useSelector } from "react-redux";

interface props {
  name: string;
  streams: any;
  sourceId: string;
  screenSharesourceId: string;
  mirror: boolean;
  videoTrack: boolean;
  remoteTrackSources: any;
  muteAllStream: boolean;
}

export const ParticipentForAttendeeScreen = ({
  name,
  streams,
  sourceId,
  mirror,
  videoTrack,
  remoteTrackSources,
  muteAllStream,
}: // camera,
props) => {
  //redux
  const stateData = useSelector((state: any) => state.PublishStreamReducer);
  const videoRef = useRef<HTMLVideoElement>(null);

  // Initialise video

  //video speaker update
  function attachSinkId(element: HTMLAudioElement, sinkId: string) {
    if ("sinkId" in element) {
      (element as any)
        .setSinkId(sinkId)
        .then(() => {
          // console.log(`Success, audio output device attached: ${sinkId}`);
        })
        .catch((error: Error) => {
          let errorMessage: string = error.message;
          if (error.name === "SecurityError") {
            errorMessage = `You need to use HTTPS for selecting audio output device: ${error.message}`;
          }
        });
    } else {
      console.warn("Browser does not support output device selection.");
      // Handle unsupported browser
    }
  }

  useEffect(() => {
    if (videoRef.current) {
      attachSinkId(
        videoRef.current,
        stateData?.publishStreamObj?.speakerdeviceId
      );
    }
  }, [stateData?.publishStreamObj?.speakerdeviceId]);

  // Initialise video
  const messageRef = useRef(true);

  useEffect(() => {
    console.log("init remoteTrackSources", remoteTrackSources);
    if (videoRef.current) {
      videoRef.current.srcObject = null;
      videoRef.current.srcObject = streams;
      console.log("Initilize stream", streams);
    }
  }, [remoteTrackSources]);

  //speaker volume change  start
  useEffect(() => {
    const newVolume = stateData?.publishStreamObj?.speakerVolume;
    if (videoRef.current) {
      videoRef.current.volume = newVolume;
    }
  }, [stateData?.publishStreamObj?.speakerVolume]);
  //end speaker volume change

  const getInitials = (name) => {
    const words = name.split(" ");
    const firstInitial = words[0]?.charAt(0).toUpperCase();
    const lastInitial =
      words.length > 1 ? words[words.length - 1].charAt(0).toUpperCase() : "";
    return `${firstInitial}${lastInitial}`;
  };
  const displayName = name === "main" ? "Host" : name;
  const initials = displayName && getInitials(displayName);

  const [loadingVideo, setLoadingVideo] = useState(true);

  // console.log("source is", sourceId, stateData?.publishStreamObj?.userName);
  // console.log("isAdmin", stateData?.publishStreamObj?.isAdmin);

  const checkMuted = () => {};

  const [isFillContent, setIsFillContent] = useState(true);

  const handleClick = () => {
    setIsFillContent(!isFillContent);
  };

  const reLoad = () => {
    if (videoRef.current) {
      videoRef.current.srcObject = null;
      videoRef.current.srcObject = streams;
    }
  };

  const randomValue1 = Math.random();
  const randomValue2 = Math.random();

  // Presenter dropdwon
  const dropdownRef = useRef(null);
  const [presenterMenuOpen, setPresenterMenuOpen] = useState(false);

  const handlePresenterDropdownClick = () => {
    setPresenterMenuOpen(!presenterMenuOpen);
  };

  const handleClickOutsideDropdown = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setPresenterMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutsideDropdown);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideDropdown);
    };
  }, []);

  return (
    <div
      className={`presenter-column ${
        isFillContent ? "fill-content" : "fit-content"
      } ${!videoTrack ? " cam-off" : ""}`}
    >
      <div className="ew-video-wrapper">
        {loadingVideo && videoTrack && (
          <div className="flex flex-center loader-bg">
            <div className="blur-bg"></div>
            <div className="flex flex-center section-loader">
              <div className="circle-loader"></div>
              <span>Loading...</span>
            </div>
          </div>
        )}

        {/* {loadingVideo && (
          <div className="refresh-screen" onClick={reLoad}>
            <img src={refreshIcon} alt="refresh" data-tooltip-place="top" />
          </div>
        )}
        <div className="video-aspect-ratio" onClick={handleClick}>
          <span
            className="flex flex-center fit-screen"
            data-tooltip-id={`tooltip-fit-screen-${randomValue1}`}
          >
            <img src={fitScreenIcon} alt="" />
          </span>
          <span
            className="flex flex-center fill-screen"
            data-tooltip-id={`tooltip-fill-screen-${randomValue2}`}
          >
            <img src={fillScreenIcon} alt="" />
          </span>
          <ReactTooltip
            id={`tooltip-fit-screen-${randomValue1}`}
            place="top"
            content="Fill to Screen"
          />
          <ReactTooltip
            id={`tooltip-fill-screen-${randomValue2}`}
            place="top"
            content="Fit to Screen"
          />
        </div> */}

        <div className="presenter-options-dropdown" ref={dropdownRef}>
          <span
            className="flex flex-center"
            onClick={handlePresenterDropdownClick}
          >
            <img src={verticallyDotsIcon} alt="" />
          </span>
          {presenterMenuOpen && (
            <ul>
              <li className="flex" onClick={reLoad}>
                <img src={refreshLineIcon} alt="" />
                <span>Refresh</span>
              </li>
              <li className="flex" onClick={handleClick}>
              <img src={isFillContent ? fitScreenIcon : fillScreenIcon} alt="" />
              <span>{isFillContent ? "Fit to screen" : "Fill to screen"}</span>
             
              </li>
            </ul>
          )}
        </div>
        {!videoTrack && (
          <div className="ew-presenter-profile flex flex-center">
            <div className="img-wrapper flex flex-center">{initials}</div>
            <div className="ew-presenter-title">
              <div className="name">{name == "main" ? "Host" : name}</div>
            </div>
          </div>
        )}
        <div className="ew-live-video">
          <video
            controls={muteAllStream}
            autoPlay
            loop
            ref={videoRef}
            id={sourceId}
            muted={muteAllStream}
            onError={() => {
              videoRef.current?.error &&
                console.error(`Video player error: ${videoRef.current?.error}`);
            }}
            onLoadStart={() => setLoadingVideo(true)}
            onPlaying={() => {
              setLoadingVideo(false);
              messageRef.current = false;
            }}
            onStalled={() => {
              console.error("Video is on stalled");
            }}
            onWaiting={() => setLoadingVideo(true)}
            playsInline
            style={{
              transform: mirror ? "scaleX(-1)" : "",
              // display: camera ? "" : "none",
            }}
          ></video>
        </div>

        <div
          className="presenter-name flex flex-center"
          style={{
            display: !!videoTrack ? "" : "none",
          }}
        >
          {sourceId == "main" ? "Host" : sourceId}
        </div>
      </div>
    </div>
  );
};

export default memo(ParticipentForAttendeeScreen);
