import { useEffect, useRef, useState } from "react";
import VideoInputSelector from "../SelectDeviceComponents/VideoInputSelector";
import StreamingQualitySelect from "../SelectDeviceComponents/StreamingQualitySelect";
import { useDispatch, useSelector } from "react-redux";
import PublishStream from "../../libs/DolbyStreaming/PublishStream";
import { userName } from "../../libs/Users/UsersName";

export const CameraSettings = () => {
  //redux
  const stateData = useSelector((state: any) => state.PublishStreamReducer);
  const dispatch = useDispatch();

  //video stream object

  //Video stream start
  const videoRef = useRef<HTMLVideoElement>(null);
  let obj: PublishStream = stateData?.publishStreamObj?.obj;

  useEffect(() => {
    (async () => {
      if (videoRef.current) {
        videoRef.current.srcObject = obj.getMediaStream();
        videoRef.current.muted = true;
        videoRef.current.volume = 1;
      }
    })();
  }, []);
  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.muted = true;
      videoRef.current.volume = 1;
    }
  }, [stateData?.publishStreamObj?.muted]);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.srcObject = obj.getMediaStream();
      videoRef.current.muted = true;
      videoRef.current.volume = 1;
    }
  }, [stateData?.publishStreamObj?.camera == true]);
  //end

  //Mirro button
  const [isMirrored, setIsMirrored] = useState(
    stateData?.publishStreamObj?.mirroCamera
  );
  const handleToggle = () => {
    setIsMirrored(!isMirrored);
    dispatch({
      type: "MIRROR_CAMERA_TOGGLE",
      payload: {
        ...stateData.publishStreamObj,
        mirroCamera: !stateData?.publishStreamObj?.mirroCamera,
      },
    });
  };

  //Quality code
  const qualityHandleChang = async (
    width: number,
    height: number,
    bitrate: number,
    qualityType: number
  ) => {
    const obj: PublishStream = stateData?.publishStreamObj?.obj;
    await obj.streamBitrateUpdate(bitrate, width, height);

    dispatch({
      type: "UPDATEVIDEOQUALITY",
      payload: {
        ...stateData.publishStreamObj,
        width: width,
        hieght: height,
        bitrate: bitrate,
        selectedQuality: qualityType,
      },
    });
  };

  //Video on/off start
  const [muted, setMuted] = useState(stateData?.publishStreamObj?.muted);
  const [video, setVideo] = useState(stateData?.publishStreamObj?.camera);
  const videoToggle = async () => {
    const newVideoState = !video;
    setVideo(newVideoState);
    const obj: PublishStream = stateData?.publishStreamObj?.obj;
    await obj.streamCameraUpdate(newVideoState);
    dispatch({
      type: "VIDEO_TOGGLE",
      payload: {
        ...stateData?.publishStreamObj,
        camera: !stateData?.publishStreamObj?.camera,
      },
    });
  };
  //end

  //Mic on/off start
  const muteToggle = async () => {
    setMuted(!muted);
    await obj.streamMicUpdate(muted);
    dispatch({
      type: "AUDIO_TOGGLE",
      payload: {
        ...stateData?.publishStreamObj,
        muted: !stateData?.publishStreamObj?.muted,
      },
    });
  };
  //end

  //Camera device id change
  useEffect(() => {
    (async () => {
      await obj.updateStreamCameraId(
        stateData?.publishStreamObj?.cameraId,
        stateData?.publishStreamObj?.deviceId,
        stateData?.publishStreamObj?.speakerdeviceId,
        stateData?.publishStreamObj?.camera
      );

      if (videoRef.current) {
        videoRef.current.srcObject = obj.getMediaStream();
        videoRef.current.muted = true;
        videoRef.current.volume = 1;
      }
    })();
  }, [stateData?.publishStreamObj?.cameraId]);

  return (
    <div className="ew-setting-area-section camera-settings-area">
      <div className="camera-check-box flex flex-center">
        {video == true && (
          <div className="camera-view">
            <video
              autoPlay
              id="myVidPlayer"
              test-id="videoView"
              muted={muted}
              ref={videoRef}
              style={{
                transform: stateData?.publishStreamObj?.mirroCamera
                  ? "scaleX(-1)"
                  : "",
              }}
            ></video>
          </div>
        )}

        {video == false && (
          <span className="username flex flex-center">
            {" "}
            {userName(stateData?.publishStreamObj?.userName)}
          </span>
        )}

        <div className="controls flex">
          <button
            className={`flex flex-center control-button mic-${
              muted == false ? "on" : "off"
            }`}
            onClick={muteToggle}
          >
            <span>
              <svg
                className="off"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.9978 1.71436C10.9748 1.71436 9.99371 2.12073 9.27036 2.84409C8.547 3.56744 8.14062 4.54852 8.14062 5.5715V12.4286C8.14062 12.9352 8.24039 13.4367 8.43423 13.9047C8.62807 14.3727 8.91219 14.7979 9.27036 15.1561C9.62853 15.5142 10.0537 15.7983 10.5217 15.9922C10.9897 16.186 11.4912 16.2858 11.9978 16.2858C12.5043 16.2858 13.0059 16.186 13.4738 15.9922C13.9418 15.7983 14.367 15.5142 14.7252 15.1561C15.0833 14.7979 15.3675 14.3727 15.5613 13.9047C15.7551 13.4367 15.8549 12.9352 15.8549 12.4286V5.5715C15.8549 4.54852 15.4485 3.56744 14.7252 2.84409C14.0018 2.12073 13.0207 1.71436 11.9978 1.71436ZM6.42634 11.7858C6.42634 11.6153 6.35861 11.4518 6.23805 11.3312C6.11749 11.2107 5.95398 11.1429 5.78348 11.1429C5.61299 11.1429 5.44947 11.2107 5.32891 11.3312C5.20835 11.4518 5.14063 11.6153 5.14063 11.7858V12.4286C5.14058 14.136 5.77748 15.782 6.92677 17.0446C8.07607 18.3073 9.65506 19.0957 11.3549 19.2558V21.6429C11.3549 21.8134 11.4226 21.9769 11.5432 22.0975C11.6638 22.2181 11.8273 22.2858 11.9978 22.2858C12.1683 22.2858 12.3318 22.2181 12.4523 22.0975C12.5729 21.9769 12.6406 21.8134 12.6406 21.6429V19.2558C14.3405 19.0957 15.9195 18.3073 17.0688 17.0446C18.2181 15.782 18.855 14.136 18.8549 12.4286V11.7858C18.8549 11.6153 18.7872 11.4518 18.6666 11.3312C18.5461 11.2107 18.3826 11.1429 18.2121 11.1429C18.0416 11.1429 17.878 11.2107 17.7575 11.3312C17.6369 11.4518 17.5692 11.6153 17.5692 11.7858V12.4286C17.5692 13.9063 16.9822 15.3234 15.9374 16.3682C14.8925 17.4131 13.4754 18.0001 11.9978 18.0001C10.5201 18.0001 9.10302 17.4131 8.05817 16.3682C7.01333 15.3234 6.42634 13.9063 6.42634 12.4286V11.7858Z"
                  fill="#fff"
                />
              </svg>
              <svg
                className="on"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.9978 1.71436C10.9748 1.71436 9.99371 2.12073 9.27036 2.84409C8.547 3.56744 8.14062 4.54852 8.14062 5.5715V12.4286C8.14062 12.9352 8.24039 13.4367 8.43423 13.9047C8.62807 14.3727 8.91219 14.7979 9.27036 15.1561C9.62852 15.5142 10.0537 15.7983 10.5217 15.9922C10.9897 16.186 11.4912 16.2858 11.9978 16.2858C12.5043 16.2858 13.0059 16.186 13.4738 15.9922C13.9418 15.7983 14.367 15.5142 14.7252 15.1561C15.0833 14.7979 15.3675 14.3727 15.5613 13.9047C15.7551 13.4367 15.8549 12.9352 15.8549 12.4286V5.5715C15.8549 4.54852 15.4485 3.56744 14.7252 2.84409C14.0018 2.12073 13.0207 1.71436 11.9978 1.71436ZM6.42634 11.7858C6.42634 11.6153 6.35861 11.4518 6.23805 11.3312C6.11749 11.2107 5.95398 11.1429 5.78348 11.1429C5.61299 11.1429 5.44947 11.2107 5.32891 11.3312C5.20835 11.4518 5.14063 11.6153 5.14063 11.7858V12.4286C5.14058 14.136 5.77748 15.782 6.92677 17.0446C8.07607 18.3073 9.65506 19.0957 11.3549 19.2558V21.6429C11.3549 21.8134 11.4226 21.9769 11.5432 22.0975C11.6638 22.2181 11.8273 22.2858 11.9978 22.2858C12.1683 22.2858 12.3318 22.2181 12.4523 22.0975C12.5729 21.9769 12.6406 21.8134 12.6406 21.6429V19.2558C14.3405 19.0957 15.9195 18.3073 17.0688 17.0446C18.2181 15.782 18.855 14.136 18.8549 12.4286V11.7858C18.8549 11.6153 18.7872 11.4518 18.6666 11.3312C18.5461 11.2107 18.3825 11.1429 18.2121 11.1429C18.0416 11.1429 17.878 11.2107 17.7575 11.3312C17.6369 11.4518 17.5692 11.6153 17.5692 11.7858V12.4286C17.5692 13.9063 16.9822 15.3234 15.9374 16.3682C14.8925 17.4131 13.4754 18.0001 11.9978 18.0001C10.5201 18.0001 9.10302 17.4131 8.05817 16.3682C7.01333 15.3234 6.42634 13.9063 6.42634 12.4286V11.7858Z"
                  fill="#fff"
                />
                <path
                  d="M3.63281 2.55371L20.5411 21.7165"
                  stroke="#333"
                  stroke-width="3"
                  stroke-linecap="round"
                />
                <path
                  d="M3.63281 2.55371L20.5411 21.7165"
                  className="line"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                />
              </svg>
            </span>
            {muted == false ? "Mute" : "Unmute"} mic
          </button>
          <button
            className={`flex flex-center control-button video-${
              video == true ? "on" : "off"
            }`}
            onClick={videoToggle}
          >
            <span>
              <svg
                className="off"
                width="20"
                height="14"
                viewBox="0 0 20 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect x="2" y="2" width="11" height="10" rx="1" fill="#fff" />
                <path
                  d="M7.305 5.48633C7.857 5.48633 8.305 5.03833 8.305 4.48633C8.305 3.93433 7.857 3.48633 7.305 3.48633H4C3.448 3.48633 3 3.93433 3 4.48633C3 5.03833 3.448 5.48633 4 5.48633H7.305Z"
                  fill="#333"
                  className="camera-line"
                />
                <path
                  d="M19.4932 0.561109C19.1762 0.384109 18.7862 0.394109 18.4782 0.587109L14.7412 2.92111C14.4952 1.29311 13.0992 0.0371094 11.4042 0.0371094H3.38019C1.51219 0.0371094 -0.0078125 1.55711 -0.0078125 3.42511V5.74211C-0.0078125 6.29411 0.440188 6.74211 0.992188 6.74211C1.54419 6.74211 1.99219 6.29411 1.99219 5.74211V3.42511C1.99219 2.65911 2.61519 2.03711 3.38019 2.03711H11.4052C12.1712 2.03711 12.7932 2.66011 12.7932 3.42511V10.5741C12.7932 11.3401 12.1702 11.9621 11.4052 11.9621H3.38019C2.61419 11.9621 1.99219 11.3391 1.99219 10.5741V9.15711C1.99219 8.60511 1.54419 8.15711 0.992188 8.15711C0.440188 8.15711 -0.0078125 8.60511 -0.0078125 9.15711V10.5741C-0.0078125 12.4421 1.51219 13.9621 3.38019 13.9621H11.4052C13.1012 13.9621 14.4972 12.7051 14.7422 11.0771L18.4782 13.4121C18.6402 13.5131 18.8242 13.5641 19.0082 13.5641C19.1752 13.5641 19.3412 13.5221 19.4932 13.4391C19.8112 13.2631 20.0082 12.9281 20.0082 12.5641V1.43511C20.0082 1.07211 19.8112 0.737109 19.4932 0.561109ZM18.0082 10.7601L14.7932 8.75011V5.24811L18.0082 3.23911V10.7601Z"
                  fill="#fff"
                />
              </svg>

              <svg
                className="on"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect x="4" y="7" width="11" height="10" rx="1" fill="#fff" />
                <path
                  d="M9.305 10.4863C9.857 10.4863 10.305 10.0383 10.305 9.48633C10.305 8.93433 9.857 8.48633 9.305 8.48633H6C5.448 8.48633 5 8.93433 5 9.48633C5 10.0383 5.448 10.4863 6 10.4863H9.305Z"
                  fill="white"
                />
                <path
                  d="M21.4932 5.56111C21.1762 5.38411 20.7862 5.39411 20.4782 5.58711L16.7412 7.92111C16.4952 6.29311 15.0992 5.03711 13.4042 5.03711H5.38019C3.51219 5.03711 1.99219 6.55711 1.99219 8.42511V10.7421C1.99219 11.2941 2.44019 11.7421 2.99219 11.7421C3.54419 11.7421 3.99219 11.2941 3.99219 10.7421V8.42511C3.99219 7.65911 4.61519 7.03711 5.38019 7.03711H13.4052C14.1712 7.03711 14.7932 7.66011 14.7932 8.42511V15.5741C14.7932 16.3401 14.1702 16.9621 13.4052 16.9621H5.38019C4.61419 16.9621 3.99219 16.3391 3.99219 15.5741V14.1571C3.99219 13.6051 3.54419 13.1571 2.99219 13.1571C2.44019 13.1571 1.99219 13.6051 1.99219 14.1571V15.5741C1.99219 17.4421 3.51219 18.9621 5.38019 18.9621H13.4052C15.1012 18.9621 16.4972 17.7051 16.7422 16.0771L20.4782 18.4121C20.6402 18.5131 20.8242 18.5641 21.0082 18.5641C21.1752 18.5641 21.3412 18.5221 21.4932 18.4391C21.8112 18.2631 22.0082 17.9281 22.0082 17.5641V6.43511C22.0082 6.07211 21.8112 5.73711 21.4932 5.56111ZM20.0082 15.7601L16.7932 13.7501V10.2481L20.0082 8.23911V15.7601Z"
                  fill="#fff"
                />
                <path
                  d="M2 3L21 22"
                  stroke="#333"
                  stroke-width="3"
                  stroke-linecap="round"
                />
                <path
                  d="M2 3L21 22"
                  className="line"
                  stroke="#fff"
                  stroke-width="1.5"
                  stroke-linecap="round"
                />
              </svg>
            </span>
            {video == true ? "Stop" : "Start"} camera
          </button>
        </div>
      </div>

      <div className="ew-other-video-settings">
        <VideoInputSelector isSetupPopup={false} />
        {/* <StreamingQualitySelect
                    qualityHandleChange={qualityHandleChang}
                    selectedQuality={stateData?.publishStreamObj?.selectedQuality} /> */}

        <div className="ew-form-field toggle-field">
          <label htmlFor="dropdown">Mirror Camera</label>
          <span className="label-sub-heading">
            Turn off mirror mode if you want to show any readable text in your
            video
            <div className="toggle-container">
              <label className="toggle-switch">
                <input
                  type="checkbox"
                  checked={isMirrored}
                  onChange={handleToggle}
                />
                <span className="toggle-slider"></span>
              </label>
            </div>
          </span>
        </div>
      </div>
    </div>
  );
};
