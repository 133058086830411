import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useImperativeHandle,
  forwardRef,
  useLayoutEffect,
} from "react";
import axios from 'axios';
import { usePubNub } from "pubnub-react";
import { useDispatch, useSelector } from "react-redux";
import debounce from "lodash.debounce";

// Importing Assets
import sendIcon from "../../assets/images/send-msg-icon.svg";
import noChatMsg from "../../assets/images/empty-chat.svg";
import msgReply from "../../assets/images/msg-reply-icon.svg";
import msgReplyHovered from "../../assets/images/msg-reply-hovered-icon.svg";
import msgDelete from "../../assets/images/msg-delete-icon.svg";
import msgDeleteHovered from "../../assets/images/msg-delete-hovered-icon.svg";
import closeIcon from "../../assets/images/close-icon.svg";
import backIcon from '../../assets/images/close-icon.svg';
import { MESSAGE_TYPE } from "../../libs/signal/signalEnum";
import { updateIsSideBar } from "../../Store/Actions/PublishStreamAction";
import downArrow from '../../assets/images/down-arrow-icon.svg';
import ChatsContainer from "./ChatsContainer";
interface props {
  userName: string;
  handleMessageEventData: any;
  indexValue: number;
}

const ChatComponent = forwardRef((props, ref) => {
  // Redux
  const dispatch = useDispatch();
  const stateData = useSelector((state: any) => state.PublishStreamReducer);
  const isPublicChat = stateData.publishStreamObj.isPublicChat;
  // User Roles
  const isAdmin = stateData.publishStreamObj.isAdmin;
  const isModerator = stateData.publishStreamObj.isModerator;
  const isPresenter = stateData.publishStreamObj.isPresenter;
  const isAttendee = stateData.publishStreamObj.isAttendee;
  const currentUser = stateData.publishStreamObj.userName;
  const currentUserUUID = stateData.publishStreamObj.userUUID;

  // State Management
  const [userName, setUserName] = useState(
    stateData?.publishStreamObj?.userName
  );
  const [isVisible, setIsVisible] = useState(true);
  // const [channels] = useState([
  //   `${stateData.publishStreamObj.webinarid}`
  // ]);
  const [channels] = useState([
    `${
      "Chat." +
      stateData.publishStreamObj.webinarid +
      "." +
      stateData.publishStreamObj.sessionId
    }`,
  ]);
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState("");
  const [lastTimetoken, setLastTimetoken] = useState(null);
  const [loadingMore, setLoadingMore] = useState(false);
  const [replyingTo, setReplyingTo] = useState(null);
  const [occupantUUIDs, setOccupantUUIDs] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [mentionedUser, setMentionedUser] = useState("");
  const [uuid, setuuid] = useState([]);
  const [mentionedUUID, setMentionedUUID] = useState(null);
  const [channelMetadata, setChannelMetadata] = useState<any>();
  const [channelMetadataPrivate, setChannelMetadataPrivate] = useState<any>();
  const channelName = `chatModeration.${stateData.publishStreamObj.webinarid}.${stateData.publishStreamObj.sessionId}`;
  const isFirstTimeScrolled = useRef<boolean>(false)

  const [showNewMessageWhenNotOnBottom,setShowNewMessageWhenNotOnBottom]=useState(false)

  //Hnadle message event start
  useImperativeHandle(ref, () => ({
    handleMessageHandler(event) {
      handleMessage(event);
    },
  }));
  // End

  // PubNub Initialization
  const pubnub = usePubNub();

  // Ref for scroll control
  const contentRef = useRef(null);
  // Fetch user list from Redux state
  const userlist = stateData.publishStreamObj.peoples;
  const userUUID = stateData.publishStreamObj.userUUID;

  // Effect for setting occupantUUIDs from user list
  useEffect(() => {
    const uniqueUsernames = new Set(occupantUUIDs);
    const updatedUsernames = [...uniqueUsernames];

    for (const user of userlist) {
      if (user.state && user.state.userName) {
        if (!uniqueUsernames.has(user.state.userName)) {
          updatedUsernames.push(user.state.userName);
          uniqueUsernames.add(user.state.userName);
        }
      }
    }

    setOccupantUUIDs(updatedUsernames);
  }, [userlist]);
  useEffect(() => {
    const uniqueUserUUIDs = new Set(uuid);
    const updatedUUIDs = [...uniqueUserUUIDs];

    for (const user of userlist) {
      if (user && user.uuid) {
        if (!uniqueUserUUIDs.has(user.uuid)) {
          updatedUUIDs.push(user.uuid);
          uniqueUserUUIDs.add(user.uuid);
        }
      }
    }

    setuuid(updatedUUIDs);
  }, [userlist]);
  // Callback for handling incoming messages
  const handleMessage = useCallback((event) => {
    console.log("myevent",event)

    // if(!isAtBottom){
    //   setShowNewMessageWhenNotOnBottom(true)
    // }
      

    if(event.publisher!==stateData.publishStreamObj.userUUID && contentRef.current.scrollTop + contentRef.current.clientHeight < contentRef.current.scrollHeight){
      if(event?.message.meta.msgType==="public"){
        setShowNewMessageWhenNotOnBottom(true)

      }else{
        if(!stateData?.publishStreamObj?.isAttendee){
          setShowNewMessageWhenNotOnBottom(true)
        }else{
          setShowNewMessageWhenNotOnBottom(false)
          // scrollToBottom()

        }
      }
     
    }else{
      setShowNewMessageWhenNotOnBottom(false)
      // scrollToBottom()

    }
    const { message, timetoken } = event;
    const text = typeof message === "string" ? message : message.text;
    setMessages((prevMessages) => [
      ...prevMessages,
      { text, meta: message.meta, replyingTo: message.replyingTo, timetoken },
    ]);
  }, []);

  // Extract mentioned user from the message
  const extractMentionedUser = (message) => {
    const matches = message.match(/(\w+)/); // Match username without @
    return matches ? matches[1] : null;
  };
  const extractMentionedUserUUID = (message) => {
    // const matches = message.match(/@(\w+)/); // Match @username
    const matches = message.match(/(\w+)/); // Match username without @

    // console.log("Matches found:", matches);

    if (matches) {
      const username = matches[1];
      // console.log("Extracted username:", username);

      const user = userlist.find((user) => user?.state?.userName === username);
      if (user) {
        // console.log("User found:", user);
        return user.uuid;
      } else {
        console.warn(`User not found for username: ${username}`);
      }
    }

    return null;
  };




  const [isSending, setIsSending] = useState(false);

const handleSendMessage = async (message) => {
  if (!isSending) {
    setIsSending(true);
    await sendMessage(message);
    setIsSending(false);
  }
};

  const sendMessage = async (message) => {
    if (message) {
      // Prepare data for API request
      const apiData = {
        webinar_id: stateData.publishStreamObj.webinarid, // current webinar Id
        sid: stateData.publishStreamObj.sessionId, // current session Id
        chat_data: {
          senderInfo: {
            user_id: isAdmin
              ? "0"
              : isModerator || isPresenter
              ? stateData.publishStreamObj.userKey
              : stateData.publishStreamObj.attendeeData, // Replace with actual attendee key if needed
            user_name: userName, // Sender name
          },
          msg: message, // Message string
          is_cohost: isPresenter ? 1 : 0, // for cohost if sender is co-host 1 otherwise 0
          person_to: replyingTo
          ? isAdmin
          ? "0"
          : isModerator || isPresenter
          ? stateData.publishStreamObj.userKey
          : stateData.publishStreamObj.attendeeData
          :"-1", // for everyone -1; adjust as needed
          is_reply: replyingTo ? 1 : 0, // if Reply 1 otherwise 0
          is_reply_message : "",
          is_mod: isModerator ? 1 : 0, // for moderator if sender is moderator 1 otherwise 0
          is_green_room: stateData.publishStreamObj.isLiveGo ? 0 : 1, // send 1 if user is in green room, 0 otherwise
          msg_identity:replyingTo ? replyingTo.meta.msgIdentity:""
        },
      };
  
      try {
        // Call the API to store the message
        const response = await axios.post('https://test-v2.easywebinar.com/v1/easywebinar/v1/all/chat', apiData);
      
  
        // Extract the msg_identity from the API response
        const msgIdentity = response.data.data.msg_identity;
        
  
        // Prepare the message to be published
        const newMessage = {
          text: message,
          meta: {
            msgType:
              !isPublicChat && stateData.publishStreamObj.isAttendee
                ? MESSAGE_TYPE.PRIVATE
                : MESSAGE_TYPE.PUBLIC,
            userUUID: currentUserUUID,
            mentionedUser,
            userName,
            role: isAdmin
              ? "Admin"
              : isModerator
              ? "Moderator"
              : isPresenter
              ? "Presenter"
              : "Attendee",
            msgIdentity, // Add msgIdentity here
          },
          replyingTo: replyingTo
            ? {
                text: replyingTo.text,
                userName: replyingTo.meta?.userName,
                role: replyingTo.meta?.role,
                timetoken: replyingTo.timetoken,
                msgIdentity: replyingTo.meta.msgIdentity, // Ensure this is set correctly
              }
            : null,
        };
  
        // Publish the message with PubNub
        await pubnub.publish({
          channel: `${
            "Chat." +
            stateData.publishStreamObj.webinarid +
            "." +
            stateData.publishStreamObj.sessionId
          }`,
          message: newMessage,
        });
        setMessage("");
        setReplyingTo(null);
      } catch (error) {
        console.error('Error storing message or publishing:', error);
      }
    }
  };

  const fetchMessages = (startTimetoken = null) => {
    pubnub.fetchMessages(
      {
        channels: [
          `${
            "Chat." +
            stateData.publishStreamObj.webinarid +
            "." +
            stateData.publishStreamObj.sessionId
          }`,
        ],
        count: 15,
        start: startTimetoken,
      },
      (status, response) => {
        //  console.log(response, "response");
        if (status.statusCode === 200) {
          const fetchedMessages =
            response.channels[
              `${
                "Chat." +
                stateData.publishStreamObj.webinarid +
                "." +
                stateData.publishStreamObj.sessionId
              }`
            ];
          if (fetchedMessages.length > 0) {
            const formattedMessages = fetchedMessages.map((msg) => ({
              text: msg.message.text,
              meta: msg.message.meta
                ? {
                    // isPrivate: msg.message.meta.isPrivate,
                    // mentionedUserUUID: msg.message.meta.mentionedUserUUID,
                    msgType:msg.message.meta.msgType,
                    userUUID: msg.message.meta.userUUID,
                    mentionedUser: msg.message.meta.mentionedUser,
                    userName: msg.message.meta.userName,
                    role: msg.message.meta.role,
                    msgIdentity: msg.message.meta.msgIdentity,
                  }
                : {},
              timetoken: msg.timetoken,
              replyingTo: msg.message.replyingTo
                ? {
                  msgType:msg.message.meta.msgType,
                    text: msg.message.replyingTo.text,
                    userName: msg.message.replyingTo.userName,
                    role: msg.message.replyingTo.role,
                    timetoken: msg.message.replyingTo.timetoken,
                    msgIdentity: msg.message.replyingTo.msgIdentity,
                  }
                : null,
            }));

            if (startTimetoken) {
              setMessages((prevMessages) => [
                ...formattedMessages,
                ...prevMessages,
              ]);
            } else {
              setMessages(formattedMessages);
            }

            if (fetchedMessages.length > 0) {
              setLastTimetoken(fetchedMessages[0].timetoken);
            }
          }
        } else {
          console.error("Failed to fetch messages:", status);
          setLoadingMore(false);
        }
      }
    );
  };

  // Load initial messages on mount
  useEffect(() => {
    fetchMessages();
  }, []);
  const isCurrentUserMentioned1 = (msg) => {
    const isMentioned = msg.meta.mentionedUserUUID === currentUserUUID;
    return isMentioned;
  };
  // Load more messages
  const loadMoreMessages = () => {

    console.log("event load more messages")
    if (lastTimetoken && !loadingMore) {
      const { current } = contentRef;
      const previousScrollHeight = current ? current.scrollHeight : 0;
      setLoadingMore(true);
      fetchMessages(lastTimetoken);
      setTimeout(() => {
        if (current) {
          const newScrollHeight = current.scrollHeight;
          current.scrollTop = newScrollHeight - previousScrollHeight;
        }
      }, 1000);
    }
  };

  // Handle key press for sending messages
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      // sendMessage(message);
      handleSendMessage(message)
    }
  };

  // Delete a message
  const deleteMessage = async (timetoken) => {
    const messageToDelete = messages.find(
      (message) => message.timetoken === timetoken
    );
        // Prepare data for the API request
        const apiData = {
          webinar_id: stateData.publishStreamObj.webinarid, // Replace with your actual webinar ID
          user_id: isAdmin
          ? "0"
          : isModerator || isPresenter
          ? stateData.publishStreamObj.userKey
          : stateData.publishStreamObj.attendeeData, // Replace with your actual user ID
          msg_identity: messageToDelete.meta.msgIdentity, // Message identity to be deleted
        };
    
        // Call the API to delete the message
        await axios.post('https://test-v2.easywebinar.com/v1/easywebinar/v1/all/delete-chat', apiData);
    
    try {
      const result = await pubnub.deleteMessages({
        channel: channels[0],
        end: timetoken,
      });
      // console.log("Deleted message successfully", result);
      setMessages((prevMessages) =>
        prevMessages.filter((message) => message.timetoken !== timetoken)
      );
    } catch (status) {
      // console.log("Error deleting message", status);
    }
  };

  // Handle scroll to load more messages
  const handleScroll = useCallback(
    debounce(() => {

      const { current } = contentRef;  

      if (current) {
        const scrollTop = current.scrollTop;
        const scrollHeight = current.scrollHeight;
        const clientHeight = current.clientHeight;

        // Update scroll position
        // current(scrollTop);

        // Check if the user is at the bottom
        if (scrollTop + clientHeight >= scrollHeight) {
          setShowNewMessageWhenNotOnBottom(false)
        }
      }




      if (current && current.scrollTop === 0) {
        loadMoreMessages();
      }
    }, 1000),
    [loadMoreMessages]
  );





  // Scroll to the bottom of the chat
  const scrollToBottom = () => {
    const { current } = contentRef;
    if (current) {

      current.scrollTop = current.scrollHeight;
       setShowNewMessageWhenNotOnBottom(false)
       
    }
  };

  const scrollToBottomFirstTime = () => {
    if(!isFirstTimeScrolled.current){
      scrollToBottom();
      isFirstTimeScrolled.current=true
    }
  }

  // Handle input change for message input
  const handleInputChange = (e) => {
    let message = e.target.value;
    setMessage(message);

    // let messageArr = message.split(" ");
    // let lastWord = messageArr[messageArr.length - 1];

    // if (lastWord.length > 0 && lastWord[0] === "@") {
    //   let keyword = lastWord.slice(1); // Remove '@' symbol
    //   let suggestedMembers = getMemberSuggestions(keyword);
    //   setSuggestions(suggestedMembers);
    // } else {
    //   setSuggestions([]); // Clear suggestions if no '@' is present
    // }
  };

  // Get member suggestions for mentions
  const getMemberSuggestions = (keyword) => {
    // Filter the occupantUUIDs based on the keyword
    return occupantUUIDs.filter((username) => {
      return username && username.toLowerCase().includes(keyword.toLowerCase());
    });
  };

  // Handle suggestion click for mentions
  const handleSuggestionClick = (name) => {
    setMessage((prevMessage) => {
      let messageArr = prevMessage.split(" ");
      // messageArr[messageArr.length - 1] = "@" + name;
      messageArr[messageArr.length - 1] = name;
      return messageArr.join(" ");
    });

    // Clear suggestions after clicking on a suggestion
    setSuggestions([]);
  };

  // Set up event listeners and cleanup on mount/unmount
  useEffect(() => {
    const { current } = contentRef;
    if (current) {
      current.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (current) {
        current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [contentRef, loadMoreMessages]);

  // Set up PubNub listener and subscription
  

  // Handle loading more messages state
  useEffect(() => {
    if (loadingMore) {
      setLoadingMore(false);
    }

  }, [messages]);


  useLayoutEffect(() => {
    const { current } = contentRef;
    if (current && !showNewMessageWhenNotOnBottom) {
      scrollToBottom()
    }
  }, [messages]);




  const isPrivateChat = stateData.publishStreamObj.isPrivateChat;

  // Helper functions to get role-based class names and text
  const getRoleClassName = (role) => {
    switch (role) {
      case "Admin":
        return "host-msg";
      case "Moderator":
      case "Presenter":
        return "co-host-msg";
      case "Attendee":
        return "";
      default:
        return "";
    }
  };

  const getRoleClassName2 = (role) => {
    switch (role) {
      case "Admin":
        return "host";
      case "Moderator":
      case "Presenter":
        return "co-host";
      case "Attendee":
        return "";
      default:
        return "";
    }
  };

  const getRoleText = (role) => {
    switch (role) {
      case "Admin":
        return "(Admin)";
      case "Moderator":
        return "(Moderator)";
      case "Presenter":
        return "(Presenter)";
      case "Attendee":
        return "";
      default:
        return "";
    }
  };

  // Close the chat component
  // const handleClose = () => {
  //   setIsVisible(false);
  // };

  // const DisplayFormattedMessage = ({ message, mentionedUser }) => {
  //   const parts = message.split(" ");
  //   return (
  //     <>
  //       {parts.map((part, index) =>
  //         // eslint-disable-next-line no-restricted-globals
  //         part === mentionedUser ? (
  //           <span style={{ color: "#4f52b2" }} key={index}>
  //             {part}{" "}
  //           </span>
  //         ) : (
  //           part + " "
  //         )
  //       )}
  //     </>
  //   );
  // };

  const getInitials = (name) => {
    const words = name.split(/\s+/);
    const firstInitial = words[0]?.[0].toUpperCase();
    const lastInitial =
      words.length > 1 ? words[words.length - 1][0].toUpperCase() : "";
    return `${firstInitial}${lastInitial}`;
  };

  // Usage example:
  // const initials = msg.meta?.userName && getInitials(msg.meta?.userName);

  return (
    <>
      <div className="chat-sidebar-area">
        <div
          className="sidebar-header"
          onClick={() => {
            // console.log("hello");
            dispatch(updateIsSideBar(false));
            // stateData.publishStreamObj.sideBarActive=false
          }}
        >
          <a href="#">
            <img src={backIcon} alt="" />
          </a>
          Chat
        </div>
        <div className="chat-thread" ref={contentRef}>
          {messages.length === 0 ? (
            <div className="empty-area-msg flex-center">
              <img src={noChatMsg} alt="No Chat Messages" />
              <h4>Chat</h4>
              <p>Type a message to jump straight into a conversation</p>
            </div>
          ) : (
            messages.map((msg, index) => {
              console.log("msg", msg);
              return (
                <ChatsContainer scrollToBottom={scrollToBottomFirstTime} key={index}>

                <div
                  key={index}
                  className={`flex chat-message ${
                    userName === msg.meta?.userName
                      ? `sent  
                    ${
                      msg.replyingTo &&
                      `reply-msg ${getRoleClassName2(msg.replyingTo?.role)}`
                    }`
                      : `received ${getRoleClassName(msg.meta?.role)}`
                  }  ${
                    msg.replyingTo &&
                    `replied-msg ${getRoleClassName2(msg.replyingTo?.role)}`
                  }`}
                >
                  {userName === msg.meta?.userName ? (
                    <div className="flex message-box">
                      <div className="username">
                        <span className="msg-time">
                          {/* {new Date(parseInt(msg.timetoken) / 10000).toLocaleTimeString()} */}
                          {new Date(
                            parseInt(msg.timetoken) / 10000
                          ).toLocaleTimeString([], {
                            hour: "2-digit",
                            minute: "2-digit",
                          })}
                        </span>
                      </div>
                      <div className="message-text">
                        {msg.replyingTo && (
                          <div className="replying-on-msg">
                            <div className="username">
                              {msg.replyingTo.userName}
                              {/* <span className='position co-host'> {msg.replyingTo.role && `(${msg.replyingTo.role})`}</span>
                               */}
                              <span className="position co-host">
                                {" "}
                                {msg.replyingTo.role !== "Attendee" &&
                                  `(${msg.replyingTo.role})`}
                              </span>
                              <span className="msg-time">
                                {" "}
                                {new Date(
                                  parseInt(msg.replyingTo.timetoken) / 10000
                                ).toLocaleTimeString([], {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                })}
                              </span>
                            </div>
                            <div className="message-text">
                              <p>{msg.replyingTo.text}</p>
                            </div>
                          </div>
                        )}
                        <p>{msg.text}</p>

                        <div className="chat-action">
                          <a
                            href="#"
                            className="delete-chat"
                            onClick={() => deleteMessage(msg.timetoken)}
                          >
                            <img src={msgDelete} alt="" className="default" />
                            <img
                              src={msgDeleteHovered}
                              alt=""
                              className="hovered"
                            />
                          </a>
                          <a
                            href="#"
                            className="reply-chat"
                            onClick={() => setReplyingTo(msg)}
                          >
                            <img src={msgReply} alt="" className="default" />
                            <img
                              src={msgReplyHovered}
                              alt=""
                              className="hovered"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      <>
                        {(msg.meta.msgType == MESSAGE_TYPE.PUBLIC ||
                          (msg.meta.msgType === MESSAGE_TYPE.PRIVATE &&
                            !stateData.publishStreamObj.isAttendee)) && (
                          <>
                            <div className="user">
                              <span className="flex flex-center name-tag">
                                {/* {msg.meta?.userName &&
                                                                  msg.meta?.userName
                                                                    .split(/\s+/)
                                                                    .map(
                                                                      (word) => word && word[0].toUpperCase()
                                                                    )
                                                                    .join("")} */}
                                {msg.meta?.userName &&
                                  getInitials(msg.meta?.userName)}
                              </span>
                            </div>
                            <div className="flex message-box">
                              <div className="username">
                                {msg.meta?.userName}
                                <span className="position">
                                  {getRoleText(msg.meta?.role)}
                                </span>
                                <span className="msg-time">
                                  {" "}
                                  {new Date(
                                    parseInt(msg.timetoken) / 10000
                                  ).toLocaleTimeString([], {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                  })}
                                </span>
                              </div>
                              <div className="message-text">
                                {msg.replyingTo && (
                                  <div className="replying-on-msg">
                                    <div className="username">
                                      {msg.replyingTo.userName}
                                      <span className="position co-host">
                                        {" "}
                                        {msg.replyingTo.role !== "Attendee" &&
                                          `(${msg.replyingTo.role})`}
                                      </span>
                                      <span className="msg-time">
                                        {" "}
                                        {new Date(
                                          parseInt(msg.replyingTo.timetoken) /
                                            10000
                                        ).toLocaleTimeString([], {
                                          hour: "2-digit",
                                          minute: "2-digit",
                                        })}
                                      </span>
                                    </div>
                                    <div className="message-text">
                                      <p>{msg.replyingTo.text}</p>
                                    </div>
                                  </div>
                                )}
                                <p>{msg.text}</p>

                                <div className="chat-action">
                                  <a
                                    href="#"
                                    className="reply-chat"
                                    onClick={() => setReplyingTo(msg)}
                                  >
                                    <img
                                      src={msgReply}
                                      alt=""
                                      className="default"
                                    />
                                    <img
                                      src={msgReplyHovered}
                                      alt=""
                                      className="hovered"
                                    />
                                  </a>
                                  {!isAttendee && (
                                    <a
                                      href="#"
                                      className="delete-chat"
                                      onClick={() =>
                                        deleteMessage(msg.timetoken)
                                      }
                                    >
                                      <img
                                        src={msgDelete}
                                        alt=""
                                        className="default"
                                      />
                                      <img
                                        src={msgDeleteHovered}
                                        alt=""
                                        className="hovered"
                                      />
                                    </a>
                                  )}
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    </>
                  )}
                </div>
                </ChatsContainer>
              );
            })
          )}
        </div>

        <div className="sticky-message-footer">
          {replyingTo && (
            <div className="msg-to-reply">
              <div className="chat-message">
                <div className="flex message-box">
                  <div className="username">
                    {replyingTo.meta?.userName}
                    {/* <span className="position">    {replyingTo.meta && `(${replyingTo.meta?.role})`}</span> */}
                    <span className="position">
                      {replyingTo.meta?.role !== "Attendee" &&
                        `(${replyingTo.meta?.role})`}
                    </span>

                    <span className="msg-time">
                      {" "}
                      {new Date(
                        parseInt(replyingTo.timetoken) / 10000
                      ).toLocaleTimeString([], {
                        hour: "2-digit",
                        minute: "2-digit",
                      })}
                      {/* {msg.replyingTo.timetoken} */}
                    </span>
                  </div>
                  <div className="message-text">
                    <p>{replyingTo.text}</p>
                  </div>
                </div>
              </div>
              <a
                href="#"
                className="cancel-reply"
                onClick={() => setReplyingTo(null)}
              >
                <img src={closeIcon} alt="" />
              </a>
            </div>
          )}
        </div>
      </div>

     { showNewMessageWhenNotOnBottom && <div className="new-message-notify">
        <span className="flex flex-center" onClick={()=>{  scrollToBottom();}}>
          <img src={downArrow} alt="" /> New Messages
        </span>
      </div>}
      <div className="flex chat-message-input">
        <input
          className="input-sm"
          type="text"
          // placeholder="Type your message here..."
          placeholder={
            isAttendee && !stateData.publishStreamObj.isChatPanel
              ? "Chat is disabled by admin"
              : "Type your message here..."
          }
          value={message}
          onChange={handleInputChange}
          onKeyPress={handleKeyPress}
          disabled={
            stateData.publishStreamObj.isAttendee &&
            !stateData.publishStreamObj.isChatPanel
          }
        />

        <button
          className="button primary-button icon"
          // onClick={() => sendMessage(message)}
          onClick={() => handleSendMessage(message)} disabled={isSending}
        >
          <img src={sendIcon} alt="Send Message" />
        </button>
      </div>
    </>
  );
});

export default ChatComponent;
