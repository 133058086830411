import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { setAuthToken } from "../../libs/APIs/auth";
import { postRequest } from "../../libs/APIs/apiCall";
import { Attendee } from "./Attendee";
import Loader from "../Loader/Loader";
import { updateAttendeeDataForAll } from "../../Store/Actions/PublishStreamAction";

export const AttendeeMain = () => {
  //redux
  const stateData = useSelector((state: any) => state.PublishStreamReducer);
  const dispatch = useDispatch();

  const [params, setParams] = useSearchParams();
  const [email, setemail] = useState(params.get("email"));
  const [attendee, setattendee] = useState(params.get("attendee"));
  const [loader, setLoader] = useState(true);

  //Routes parameter read
  const [userName, setUserName] = useState<any>("");
  const [streamName, setstreamName] = useState<any>("");
  const [streamAccountId, setStreamAccountId] = useState<any>(
    stateData?.publishStreamObj?.accountId
  );
  const [subscriberToken, setsubscriberToken] = useState("");
  const [attendeeData, setAttendeeData] = useState({});
  //end

  useEffect(() => {
    (async () => {
      const ew_jwt_token = localStorage.getItem("ew_jwt_token");
      setAuthToken(ew_jwt_token);
      const res = await postRequest(
        "https://test-v2.easywebinar.com/v1/easywebinar/v1/attendee/join",
        {
          attendee: attendee,
          email: email,
        }
      );
      setUserName(res.data.name);
      setstreamName(res.data.stream[0].streamName);
      setsubscriberToken(res.data.subscription_token);
      console.log("res.data atennde:::", res.data);
      setAttendeeData(res?.data);

      dispatch({
        type: "ADD_USERNAME",
        payload: {
          ...stateData?.publishStreamObj,
          userName: res.data.name,
          userEmail: res.data.email,
          subscribeToken: res.data.subscription_token,
          streamName: res.data.stream[0].streamName,
          webinarid: res?.data?.webinar_id,
          sessionId: res?.data?.session_id,
          userUUID: res?.data?.pn_user_id,
          isAttendee: true,
          // attendeeKey: res.data.attendee_data?.key,
          // isModerator: true,
          event_name: res.data?.webinar_details?.event_name,
        },
      });

      dispatch(updateAttendeeDataForAll(res.data.attendee_data?.key));
      setLoader(false);
    })();
  }, []);
  // Backend API call end

  return (
    <>
      {loader && <Loader />}
      {!loader && (
        <Attendee
          attendeeData={attendeeData}
          streamAccountId={streamAccountId}
          streamName={streamName}
          subscriberToken={subscriberToken}
          userName
        />
      )}
    </>
  );
};
