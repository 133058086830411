import React, { useState } from "react";
import "../../assets/styles/main.scss";

export const ScreenShareDeniedPopup = ({
  isScreenShareDeniedOpen,
  onScreenShareDeniedClose,
}) => {
  return (
    <div
      className={`modal sharing-denied-popup ${
        isScreenShareDeniedOpen ? "active" : ""
      }`}
    >
      <div className="modal-background"></div>
      <div className="modal-container with-header">
        <div className="modal-header">
          <h3 className="flex">Screen Sharing Denied</h3>
          <span className="close-btn" onClick={onScreenShareDeniedClose}>
            &times;
          </span>
        </div>
        <div className="modal-content flex">
          <div className="screen-sharing-info">
            <h3>Someone else is sharing</h3>
            <p>
              The host isn’t allowing multiple presenters to share their screen
              for this webinar.
            </p>
          </div>
        </div>
        <div className="modal-footer flex">
          <div className="flex">
            <button
              type="button"
              className="btn primary-button"
              onClick={onScreenShareDeniedClose}
            >
              Ok
            </button>
            {/* <button type="button" className="warning-button">End Session</button> */}
          </div>
        </div>
      </div>
    </div>
  );
};
