import { useEffect, useState } from 'react';

// Extend the Navigator interface globally to include the connection property
declare global {
  interface NetworkInformation {
    downlink: number;
    effectiveType: string;
    rtt: number;
    saveData: boolean;
    addEventListener(type: 'change', listener: () => void): void;
    removeEventListener(type: 'change', listener: () => void): void;
  }

  interface Navigator {
    connection: NetworkInformation;
    mozConnection?: NetworkInformation;
    webkitConnection?: NetworkInformation;
  }
}

type NetworkStatus = 'stable' | 'unstable' | 'offline';

export const useNetworkStatus = (): NetworkStatus => {
  const [networkStatus, setNetworkStatus] = useState<NetworkStatus>('stable');

  const checkNetworkStatus = () => {
    if (!navigator.onLine) {
      setNetworkStatus('offline');
      return;
    }

    const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;

    if (connection) {
      const { downlink, rtt, effectiveType } = connection;

      // Adjust these thresholds as needed
      if (downlink < 1 || rtt > 300 || effectiveType === '2g') {
        setNetworkStatus('unstable');
      } else {
        setNetworkStatus('stable');
      }
    } else {
      setNetworkStatus('stable');
    }
  };

  useEffect(() => {
    checkNetworkStatus();

    const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;

    if (connection) {
      connection.addEventListener('change', checkNetworkStatus);
    }

    window.addEventListener('online', checkNetworkStatus);
    window.addEventListener('offline', checkNetworkStatus);

    return () => {
      if (connection) {
        connection.removeEventListener('change', checkNetworkStatus);
      }
      window.removeEventListener('online', checkNetworkStatus);
      window.removeEventListener('offline', checkNetworkStatus);
    };
  }, []);

  return networkStatus;
};
