import React from "react";
import { useEffect, useRef, useState } from "react";
import PublishStream from "../../libs/DolbyStreaming/PublishStream";
import { useSelector } from "react-redux";
import { Tooltip } from "react-tooltip";
import refreshIcon from "../../assets/images/refresh-icon.svg";
import { Spinner } from "@chakra-ui/react";

interface props {
  name: string;
  screenSharedUsername: string;
  streams: any;
  sourceId: string;
  muteAllStream: boolean;
}

export const ScreenShareForAttendeeScreen = ({
  name,
  streams,
  sourceId,
  screenSharedUsername,
  muteAllStream,
}: props) => {
  //Redux start
  const stateData = useSelector((state: any) => state.PublishStreamReducer);

  const videoRef = useRef<HTMLVideoElement>(null);

  function stopScreenShare() {
    const obj: PublishStream = stateData?.publishStreamObj?.obj;
    obj.stopSreenShare();
  }

  // Initialise video
  const [loadingVideo, setLoadingVideo] = useState(true);
  const messageRef = useRef(false);

  function reloadTimeInterval() {
    if (messageRef.current == true) reLoad();
    setTimeout(() => {
      reloadTimeInterval();
    }, 5000);
  }

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.srcObject = null;
      videoRef.current.srcObject = streams;
    }
  }, []);

  const reLoad = () => {
    if (videoRef.current) {
      videoRef.current.srcObject = null;
      videoRef.current.srcObject = streams;
    }
  };

  function mutedVideo() {
    if (
      stateData?.publishStreamObj?.isAttendee ||
      stateData?.publishStreamObj?.isModerator
    )
      return true;
    else return false;
  }

  function Control() {
    if (
      stateData?.publishStreamObj?.isAttendee ||
      stateData?.publishStreamObj?.isModerator
    )
      return true;
    else return false;
  }
  return (
    <div className="presenter-column screen-sharing-user-fullscreen">
      <div className="ew-video-wrapper">
        {loadingVideo && (
          <div className="flex flex-center loader-bg">
            <div className="blur-bg"></div>
            <div className="flex flex-center section-loader">
              <div className="circle-loader"></div>
              <span>Loading...</span>
            </div>
          </div>
        )}
        {loadingVideo && (
          <div className="refresh-screen" onClick={reLoad}>
            <img src={refreshIcon} alt="refresh" data-tooltip-place="top" />
          </div>
        )}
        {screenSharedUsername != name && (
          <div className="ew-live-video">
            <video
              controls={muteAllStream}
              autoPlay
              loop
              muted={muteAllStream}
              ref={videoRef}
              id={sourceId}
              onError={() => {
                videoRef.current?.error &&
                  console.error(
                    `Video player error: ${videoRef.current?.error}`
                  );
              }}
              onLoadStart={() => setLoadingVideo(true)}
              onPlaying={() => {
                setLoadingVideo(false);
                messageRef.current = false;
              }}
              onStalled={() => {
                console.error("Video is on stalled");
              }}
              onWaiting={() => setLoadingVideo(true)}
              playsInline
            ></video>
          </div>
        )}
        <div className="presenter-name flex flex-center">
          {screenSharedUsername}
        </div>
      </div>
    </div>
  );
};
