
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateSelectedDeviceId } from '../../Store/Actions/PublishStreamAction';
import microphoneIcon from '../../assets/images/microphone-icon.png'
import microphoneIconMuted from '../../assets/images/microphone-icon-off-18.svg'
import { useLocation } from 'react-router-dom';
import Select from 'react-select';
import loggly from '../ErrorsHandler/Logger';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import loggly from 'loggly-jslogger'; // Ensure this is correctly imported and configured


const AudioInputSelector = React.memo((
) => {
  // State variables
  const [uniqueDevices, setUniqueDevices] = useState<InputDeviceInfo[]>([]);
  const [micVolume, setMicVolume] = useState<number>(0);
  const dispatch = useDispatch();
  const { state } = useLocation();
  const stateData = useSelector((state: any) => state.PublishStreamReducer);
  const muted= stateData.publishStreamObj.muted
  // console.log(muted,"muted")
  const micStyle = muted === false ? { width: `${Math.round(micVolume * 100)}%` } : {};



  function isChrome() {
    loggly.push({ tag: 'Chrome', message: 'this is chrome' });

    const userAgent = navigator.userAgent.toLowerCase();
    return userAgent.indexOf('chrome') > -1;
  }

  // Effect to handle changes in media devices and update uniqueDevices state
  useEffect(() => {
    let deviceChangeTimeout: NodeJS.Timeout;

    const gotDevices = (deviceInfos: InputDeviceInfo[]) => {
      try {
      
        const audioInputList: InputDeviceInfo[] = deviceInfos.filter(device => device.kind === 'audioinput');

        const uniqueDevices = Array.from(new Set(audioInputList.map(device => device.groupId)))
          .map(groupId => {
            return audioInputList.reverse().find(device => device.groupId === groupId);
          })
          .filter(device => device !== undefined) as InputDeviceInfo[];

        setUniqueDevices(uniqueDevices);

      // Only apply the default selection if no deviceId is set in stateData
    if (isChrome() && !stateData.publishStreamObj?.deviceId) {
      deviceChangeTimeout = setTimeout(() => {
        if (uniqueDevices.length > 0) {
          const selectedOption = {
            value: uniqueDevices[0]?.deviceId,
            label: uniqueDevices[0]?.label
          };
          handleSelectChange(selectedOption.value, selectedOption.label);
        }
      }, 3000);
    }
  } catch (error) {
    console.error('Error processing devices:', error);
    loggly.push({ tag: 'error', message: 'An error occurred', error: error.message });
  }
};

    const handleDeviceChange = () => {
      try {
        loggly.push({ tag: 'user-action', message: 'Mic changed', data: { buttonId: 'saveButton' } });

        clearTimeout(deviceChangeTimeout); // Clear any existing timeout
        navigator.mediaDevices.enumerateDevices()
          .then((data: any[]) => {
            gotDevices(data);
          })
          .catch(error => {
            console.error('Error enumerating devices:', error);
            loggly.push({ tag: 'error', message: 'An error occurred', error: error.message });
          });
      } catch (error) {
        console.error('Error handling device change:', error);
        loggly.push({ tag: 'error', message: 'An error occurred', error: error.message });
      }
    };

    handleDeviceChange();

    navigator.mediaDevices.addEventListener('devicechange', handleDeviceChange);

    return () => {
      if (isChrome()) {
        clearTimeout(deviceChangeTimeout); // Clear the timeout when the component unmounts
      }
      navigator.mediaDevices.removeEventListener('devicechange', handleDeviceChange);
    };
  }, []);

  // Effect to access microphone and update micVolume state
  useEffect(() => {
    let mediaStream;
    (async function () {
      try {
        mediaStream = await navigator.mediaDevices.getUserMedia({ audio: true });
        const audioContext = new AudioContext();
        const analyser = audioContext.createAnalyser();
        const microphone = audioContext.createMediaStreamSource(mediaStream);
        microphone.connect(analyser);
        const dataArray = new Uint8Array(analyser.frequencyBinCount);

        const updateVolume = () => {
          analyser.getByteFrequencyData(dataArray);
          const total = dataArray.reduce((acc, curr) => acc + curr, 0);
          const average = total / dataArray.length;
          setMicVolume(average / 255);
        };

        const updateVolumeInterval = setInterval(updateVolume, 100);
        return () => clearInterval(updateVolumeInterval);
      } catch (error) {
        // if (error.name === 'NotAllowedError') {
        //   toast.error('Your audio permission is denied. Please allow it from your browser settings.');
        // }
        // console.error('Error accessing microphone:', error);
        loggly.push({ tag: 'error', message: 'An error occurred', error: error.message });
      }
    })();

    return ()=>{
      mediaStream?.getTracks().forEach(track => track.stop())
    }

  }, []);

  // Function to handle Select component onChange event
  const handleSelectChange = (deviceId: string, label: string) => {
    try {
      loggly.push({ tag: 'info', message: 'Device changed successfully' });
      dispatch({
        type: 'UPDATE_SELECTED_DEVICE_ID',
        payload: {
          ...stateData?.publishStreamObj,
          deviceId: deviceId,
          label: label
        },
      });
    } catch (error) {
      console.error('Error dispatching device change:', error);
      loggly.push({ tag: 'error', message: 'An error occurred', error: error.message });
    }
  };

  return (
    <div>
      <div className="ew-form-field">
        <label htmlFor="dropdown">Microphone <span></span></label>
        <span className="label-sub-heading">Select your preferred device for microphone</span>
       
        {/* <span className='microphone-volume'>
        <span className='icon'><img src={microphoneIcon} alt="" /></span>
        <span className='microphone-volume'>
          <span style={{ width: `${Math.round(micVolume * 100)}%` }}></span>
          </span>
        </span> */}
        
        <div className='mic-volume'>
          <span className='icon'><img src={muted ? microphoneIconMuted : microphoneIcon}  alt="" /></span>
          <span className='microphone-volume'>
            <span style={micStyle}></span>
          </span>
        </div>
        <Select
          id="dropdown"
          options={uniqueDevices.map(device => ({
            value: device.deviceId,
            label: device.label || `Microphone ${uniqueDevices.length + 1}`
          }))}
          value={
            uniqueDevices.find(device => device.deviceId === stateData?.publishStreamObj?.deviceId)
              ? { value: stateData?.publishStreamObj?.deviceId, label: stateData?.publishStreamObj?.label }
              : { value: uniqueDevices[0]?.deviceId, label: uniqueDevices[0]?.label } // Using first device from uniqueDevices
          }
        
          onChange={(selectedOption) => {
            if (selectedOption) {
              loggly.push({ tag: 'info', message: 'Device selected' });
              handleSelectChange(selectedOption.value, selectedOption.label);
            }
          }}
        />
      </div>
      {/* <ToastContainer /> */}
    </div>
  );
});

export default AudioInputSelector;
