import { useEffect, useState } from 'react';

const usePermissionListener = () => {
  const [showPopup, setShowPopup] = useState(false);
  console.log({showPopup})

  useEffect(() => {
    const checkPermissions = async () => {
      try {
        // Check camera and microphone permissions
        const cameraPermission = await navigator.permissions.query({ name: 'camera' as PermissionName });
        const microphonePermission = await navigator.permissions.query({ name: 'microphone' as PermissionName });

        // Handle initial permission state
        if (cameraPermission.state === 'denied' || microphonePermission.state === 'denied') {
         
          setShowPopup(true);
        }

        // Set up listeners for permission changes
        cameraPermission.onchange = () => {
          if (cameraPermission.state === 'denied') {
           
            setShowPopup(true);
          } else {
            // window.location.reload();
          }
        };

        microphonePermission.onchange = () => {
          if (microphonePermission.state === 'denied') {
          
            setShowPopup(true);
          } else {
            // window.location.reload();
          }
        };
      } catch (error) {
        console.error("Error checking permissions:", error);
      }
    };

    checkPermissions();
  }, []);

  return { showPopup ,setShowPopup};
};

export default usePermissionListener;
