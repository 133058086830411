import defaultChatIcon from "../../assets/images/default-chat-icon.svg";
import activeChatIcon from "../../assets/images/active-chat-icon.svg";
import defaultPeopleIcon from "../../assets/images/default-people-icon.svg";
import activePeopleIcon from "../../assets/images/active-people-icon.svg";
import defaultOffersIcon from "../../assets/images/default-offers-icon.svg";
import activeOffersIcon from "../../assets/images/active-offers-icon.svg";
import defaultPollsIcon from "../../assets/images/default-polls-icon.svg";
import activePollsIcon from "../../assets/images/active-polls-icon.svg";
import leaveSession from "../../assets/images/leave-session.svg";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addnewPeople,
  addNumberOfPeople,
  addPeoples,
  cameraId,
  cameraToggle,
  muteToggle,
  participantMicStatus,
  participantVideoStatus,
  removePeople,
  updateIsAttendeeList,
  updateIsChatPanel,
  updateIsLive,
  updateIsLiveGo,
  updateIsPrivatChat,
  updateIsPublicChat,
  updateIsScreen,
  updateIsScreenOther,
  updateIsSideBar,
  updateWhoIsSharingScreen,
} from "../../Store/Actions/PublishStreamAction";
import { usePubNub } from "pubnub-react";
import {
  getChatChannelName,
  getPeopleChannelName,
  MSG_ENM,
  signalEnum,
} from "../../libs/signal/signalEnum";
import { useNavigate } from "react-router-dom";
import { ConfirmationPopupAttendee } from "../LiveRoomPopUp/ConfimationPopupAttendee";
import PublishStream from "../../libs/DolbyStreaming/PublishStream";
import { updateActivePollStatus } from "../../Store/Actions/PublishStreamAction";
import { ConfimationPopToAttendeeToJoinAsCohost } from "../LiveRoomPopUp/ConfimationPopJoinAsCohost";
import { toast } from "react-toastify";
import { postRequest } from "../../libs/APIs/apiCall";
import { leaveAttendeeUrl } from "../../config";

interface props {
  sideClick: (index: number, value?: string) => void;
  onLeaveClick: any;
  messageHandle: (event) => void;
  onOpenConfirmationModelFunc: () => void;
}

const icons = [
  // { defaultIcon: defaultChatIcon, activeIcon: activeChatIcon, label: "Chat" },
  {
    defaultIcon: defaultPeopleIcon,
    activeIcon: activePeopleIcon,
    label: "People",
  },
  {
    defaultIcon: defaultOffersIcon,
    activeIcon: activeOffersIcon,
    label: "Offers",
  },
  {
    defaultIcon: defaultPollsIcon,
    activeIcon: activePollsIcon,
    label: "Polls",
  },
  // Add other icons here if needed
];

export const SidebarLayout = ({
  sideClick,
  onLeaveClick,
  messageHandle,
  onOpenConfirmationModelFunc,
}: props) => {
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  console.log("Active", activeIndex);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState<boolean>(false);
  const [newUrl, setNewUrl] = useState("");
  const [isNewMessage, setIsNewMessage] = useState(false);
  const sideClickHandler = (index: number) => {
    setIsNewMessage(false);

    if (activeIndex === index) {
      setActiveIndex(null);
      // dispatch(updateIsSideBar(true));
      sideClick(index, "hide");
    } else {
      setActiveIndex(() => index);
      sideClick(index);
    }
  };

  //redux
  const stateData = useSelector((state: any) => state.PublishStreamReducer);
  console.log("stateData", stateData);
  const obj: PublishStream = stateData?.publishStreamObj?.obj;
  const [muted, setMuted] = useState(stateData?.publishStreamObj?.muted);
  const dispatch = useDispatch();
  const isAttendee = stateData.publishStreamObj.isAttendee;
  const isModerator = stateData.publishStreamObj.isModerator;
  const [time, setTime] = useState("");
  // console.log(time,"time")
  // dispatch(updateTimeStamp(time));
  stateData.publishStreamObj.timeStamp = time;
  useEffect(() => {
    if (stateData.publishStreamObj.sideBarActive == false) {
      setActiveIndex(null);
    }
  }, [stateData.publishStreamObj.sideBarActive]);

  //pubnub start

  //Channel names start
  const ChatchannelName = getChatChannelName(
    stateData.publishStreamObj.webinarid,
    stateData.publishStreamObj.sessionId
  );

  const PeoplechannelName = getPeopleChannelName(
    stateData.publishStreamObj.webinarid,
    stateData.publishStreamObj.sessionId
  );

  const channelNameChatModeration = `chatModeration.${stateData.publishStreamObj.webinarid}.${stateData.publishStreamObj.sessionId}`;
  const WebinachannelName =
    "Webinar." +
    stateData.publishStreamObj.webinarid +
    "." +
    stateData.publishStreamObj.sessionId;

  const WebinarchannelName =
    "Webinar." +
    stateData.publishStreamObj.webinarid +
    "." +
    stateData.publishStreamObj.sessionId;

  const globalPollChannel =
    "Polls" +
    "." +
    stateData.publishStreamObj.webinarid +
    "." +
    stateData.publishStreamObj.sessionId;
  //Channel names end
  const pubnubobj = usePubNub();

  useEffect(() => {
    if (stateData.publishStreamObj.isNewPollPublished) {
      if (activeIndex !== 3) {
        setActiveIndex(3);
        sideClickHandler(3);
      }
      dispatch(updateActivePollStatus(false));
    }
  }, [stateData.publishStreamObj.isNewPollPublished, sideClickHandler]);

  useEffect(() => {
    (async () => {
      // console.log("Join");
      // try {
      //   const setSate = await pubnubobj.setState({
      //     channels: [ChatchannelName],
      //     state: {
      //       uuid: stateData?.publishStreamObj?.userUUID,
      //       userName: stateData?.publishStreamObj?.userName,
      //       // email: stateData?.publishStreamObj?.userEmail,
      //       mic: !stateData?.publishStreamObj?.muted,
      //       camera: stateData?.publishStreamObj?.camera,
      //       isAttendee: stateData?.publishStreamObj?.isAttendee,
      //       isAdmin: stateData?.publishStreamObj?.isAdmin,
      //       isModerator: stateData?.publishStreamObj?.isModerator,
      //       isPresentor: stateData?.publishStreamObj?.isPresenter,
      //     },
      //   });
      //   console.log(
      //     "SideLayout Set State successfully :: ",
      //     setSate,
      //     new Date()
      //   );
      // } catch (e) {
      //   console.log("SideLayout Error in set state", e, new Date());
      // }
      // setTimeout(() => {
      //   userLists();
      // }, 1000);
    })();
  }, []);

  const muteToggle1 = async (mic) => {
    await obj.streamMicUpdate(mic);
    dispatch(muteToggle(!mic));
    pubnubobj
      .signal({
        message: {
          // uuid: stateData?.publishStreamObj?.userUUID,
          mic: mic,
        },
        channel: ChatchannelName,
      })
      .catch((error) => {
        console.error("Error sending mute signal:", error);
        console.error("Error status:", error.status); // Log the status code or status object
        console.error("Error message:", error.message);
      });
  };

  const videoToggle = async (camera) => {
    await obj.streamCameraUpdate(camera);
    dispatch(cameraToggle(camera));
    pubnubobj
      .signal({
        message: {
          camera: camera,
        },
        channel: ChatchannelName,
      })
      .catch((error) => {
        console.error("Error sending mute signal:", error);
        console.error("Error status:", error.status); // Log the status code or status object
        console.error("Error message:", error.message);
      });
  };

  const handleEventsWebinarChannel = (event) => {
    if (event.message.type === MSG_ENM.BLOCK) {
      if (event?.message?.uuid == stateData.publishStreamObj?.userUUID) {
        const baseUrl =
          "https://business-owner.easywebinar.live/blocked-attendee"; // Target URL
        // const newUrl = `${baseUrl}?id=${stateData.publishStreamObj?.webinarid}`;
        localStorage.setItem("Blocked", stateData.publishStreamObj?.userUUID);
        window.location.href = baseUrl;
      }
      dispatch(removePeople(event?.message?.uuid));
    } else if (event.message.type == MSG_ENM.MAKE_COHOST) {
      if (event?.message?.uuid == stateData.publishStreamObj?.userUUID) {
        console.log(" make him as cohost");
        console.log(
          "presenter link",
          event?.message,
          stateData.publishStreamObj.userName,
          event?.message,
          stateData.publishStreamObj.userEmail
        );
        const newUrl = new URL(event?.message?.presentorLink);

        // // Append dynamic query parameters
        newUrl.searchParams.append(
          "pname",
          stateData.publishStreamObj.userName
        );
        newUrl.searchParams.append(
          "pemail",
          stateData.publishStreamObj.userEmail
        );

        console.log("new url", newUrl);
        setNewUrl(newUrl.toString());
        onOpenConfirmationModel();
        // window.location.href = newUrl.toString();

        // userName: string;
        // userAccountId: string;
        // userEmail: string;
        // userUUID: string;
      }
    } else if (event?.message?.type === MSG_ENM.MAKE_ATTENDEE) {
      if (event?.message?.uuid == stateData.publishStreamObj?.userUUID) {
        console.log("make him attendee");
        console.log(
          "presenter link",
          event?.message,
          stateData.publishStreamObj.userName,
          event?.message,
          stateData.publishStreamObj.userEmail
        );
        const newUrl = new URL(event?.message?.attendeeLink);
        console.log("Attendee Link", event?.message?.attendeeLink);
        // // Append dynamic query parameters
        newUrl.searchParams.append(
          "pname",
          stateData.publishStreamObj.userName
        );
        newUrl.searchParams.append(
          "pemail",
          stateData.publishStreamObj.userEmail
        );
        console.log("new url", newUrl);
        // window.location.href = newUrl.toString();
      }
    } else if (event?.message?.type === MSG_ENM.TOGGLE_MIC) {
      if (event?.message?.mutedBy === "self") {
        dispatch(
          participantMicStatus({
            mic: event.message.mic,
            uuid: event?.message?.uuid,
          })
        );
      } else {
        if (event?.message?.uuid == stateData.publishStreamObj?.userUUID) {
          muteToggle1(event?.message?.mic);
        }
      }
    } else if (event?.message?.type === MSG_ENM.TOGGLE_CAMERA) {
      if (event?.message?.uuid == stateData.publishStreamObj?.userUUID) {
        videoToggle(event?.message?.camera);
      }
    } else if (
      event?.message.type === MSG_ENM.STOP_SCREEN_SHARE_FOR_PRESENTER
    ) {
      if (event?.message?.uuid == stateData.publishStreamObj?.userUUID) {
        const obj: PublishStream = stateData?.publishStreamObj?.obj;
        obj.stopSreenShare();
      }
      dispatch(updateIsScreen(false));
      dispatch(updateWhoIsSharingScreen(""));
    }
  };

  const listener = {
    status: async (statusEvent) => {
      // console.log(statusEvent, "status");
      if (statusEvent.category === "PNConnectedCategory") {
        console.log(
          "SideLayout PubNub Update: PNConnectedCategory",
          new Date()
        );
      }
      if (statusEvent.category === "PNNetworkUpCategory") {
        console.log(
          "SideLayout PubNub Update: PNNetworkUpCategory",
          new Date()
        );
      }
      if (statusEvent.category === "PNNetworkDownCategory") {
        console.log(
          "SideLayout PubNub Update: PNNetworkDownCategory",
          new Date()
        );
      }
      if (statusEvent.category === "PNReconnectedCategory") {
        console.log(
          "SideLayout PubNub Update: PNConnectedCategory",
          new Date()
        );
      }
      if (statusEvent.category === "PNAccessDeniedCategory") {
        // setupRefreshToken();
      }
    },
    // Signals
    signal: function (s) {
      console.log("SideLayout  signal", s, new Date());
      if (s.channel == channelNameChatModeration) {
        if (s?.message?.people == true || s?.message?.people == false) {
          pubnubobj.objects.setChannelMetadata({
            channel: s.channel,
            data: {
              custom: {
                people: s.message.people,
                PubChat: s.message.PubChat,
                PvtChat: s.message.PvtChat,
                ChatPanel: s.message.ChatPanel,
              },
            },
          });

          dispatch(updateIsAttendeeList(s?.message?.people));
        }
        if (s?.message?.PubChat == true || s?.message?.PubChat == false) {
          setTime(s.timetoken);

          pubnubobj.objects.setChannelMetadata({
            channel: s.channel,
            data: {
              custom: {
                people: s.message.people,
                PubChat: s.message.PubChat,
                PvtChat: s.message.PvtChat,
                ChatPanel: s.message.ChatPanel,
              },
            },
          });

          // dispatch(updateTimeStamp(s.timetoken));
          dispatch(updateIsPublicChat(s?.message?.PubChat));
        }
        if (s?.message?.PvtChat == true || s?.message?.PvtChat == false) {
          pubnubobj.objects.setChannelMetadata({
            channel: s.channel,
            data: {
              custom: {
                people: s?.message?.people,
                PubChat: s?.message?.PubChat,
                PvtChat: s?.message?.PvtChat,
                ChatPanel: s?.message?.ChatPanel,
              },
            },
          });
          dispatch(updateIsPrivatChat(s?.message?.PvtChat));
        }
        if (s?.message?.ChatPanel == true || s?.message?.ChatPanel == false) {
          pubnubobj.objects.setChannelMetadata({
            channel: s.channel,
            data: {
              custom: {
                people: s.message.people,
                PubChat: s.message.PubChat,
                PvtChat: s.message.PvtChat,
                ChatPanel: s.message.ChatPanel,
              },
            },
          });

          dispatch(updateIsChatPanel(s?.message?.ChatPanel));
        }
      } else if (s.channel == ChatchannelName) {
        if (s?.message?.mic == false || s?.message?.mic == true) {
          dispatch(
            participantMicStatus({
              mic: s?.message?.mic,
              uuid: s?.publisher,
            })
          );
        } else if (s?.message?.camera == false || s?.message?.camera == true) {
          dispatch(
            participantVideoStatus({
              camera: s?.message?.camera,
              uuid: s?.publisher,
            })
          );
        }
      } else if (s.channel == WebinarchannelName) {
        if (s?.message?.type == signalEnum.ENDSESSION) {
          localStorage.removeItem("Blocked");
          console.log("SideLayout  signal2", s, new Date());
          if (stateData.publishStreamObj.isAdmin) {
            const baseUrl = "https://test-v2.easywebinar.com/webinar-live-end"; // Target URL
            const newUrl = `${baseUrl}?id=${stateData.publishStreamObj?.webinarid}`;

            window.location.href = newUrl;
          } else {
            if (stateData?.publishStreamObj.isAttendee) {
              const leaveApiforAttendee = async () => {
                try {
                  const res = await postRequest(leaveAttendeeUrl, {
                    webinar_id: stateData.publishStreamObj.webinarid,
                    attendee_key: stateData?.publishStreamObj?.attendeeData,
                    sid: stateData.publishStreamObj.sessionId,
                  });
                } catch (error) {
                  console.log("error", error);
                }
              };
              leaveApiforAttendee();
            }
          }
          navigate("/Thanks");
        } else if (s?.message.type == MSG_ENM.MAKE_COHOST) {
          console.log("signal reciverdddddddd", s);
        } else if (s?.message.isLive == true) {
          dispatch(updateIsLiveGo(true));
        } else if (s?.message.screen == true) {
          if (s?.message.name != stateData?.publishStreamObj?.userName) {
            console.log("dispatched");

            dispatch(updateIsScreenOther(true));
            dispatch(updateWhoIsSharingScreen(s?.publisher));
          }
        } else if (s?.message.screen == false) {
          if (s?.message.name != stateData?.publishStreamObj?.userName) {
            dispatch(updateIsScreenOther(false));
          }
        }
      }
    },

    //Message
    message: (messageEvent) => {
      console.log("message event", messageEvent);
      if (
        messageEvent?.channel ==
        "Polls-" + stateData.publishStreamObj.webinarid
      ) {
        setPolls((polls) => [
          ...polls,
          {
            message: messageEvent.message,
            timetoken: messageEvent.timetoken,
          },
        ]);
      } else if (
        messageEvent?.channel ==
        "Chat." +
          stateData.publishStreamObj.webinarid +
          "." +
          stateData.publishStreamObj.sessionId
      ) {
        console.log("came here");
        console.log(
          "publisher",
          messageEvent.publisher,
          stateData?.publishStreamObj?.userUUID
        );
        if (messageEvent.publisher !== stateData?.publishStreamObj?.userUUID) {
          console.log("Active", activeIndex);
          if (activeIndex !== 0 || activeIndex == null) {
            if (messageEvent?.message.meta.msgType === "public") {
              setIsNewMessage((preMsg) => true);
            } else {
              if (!stateData?.publishStreamObj?.isAttendee) {
                setIsNewMessage((preMsg) => true);
              } else {
                setIsNewMessage((preMsg) => false);
              }
            }
          } else {
            setIsNewMessage((preMsg) => false);
          }
        }
        messageHandle(messageEvent);
      } else if (
        messageEvent?.channel ==
        "Webinar." +
          stateData.publishStreamObj.webinarid +
          "." +
          stateData.publishStreamObj.sessionId
      ) {
        handleEventsWebinarChannel(messageEvent);
      } else if (messageEvent?.channel == globalPollChannel) {
        if (
          stateData.publishStreamObj.isAttendee ||
          stateData.publishStreamObj.isModerator ||
          stateData.publishStreamObj.isPresenter
        ) {
          if (messageEvent?.message?.type == "activate_poll") {
            toast.success(`New poll has been published`, {
              autoClose: 3000,
            });
            dispatch(updateActivePollStatus(true));
          }
          if (messageEvent?.message?.type == "deactivate_poll") {
            toast.success(`Poll has been deactivated`, {
              autoClose: 3000,
            });
            dispatch(updateActivePollStatus(true));
          }
        }
      }
    },
  };

  useEffect(() => {
    pubnubobj.addListener(listener);
    pubnubobj.subscribe({
      channels: [
        ChatchannelName,
        channelNameChatModeration,
        WebinarchannelName,
        globalPollChannel,
      ],
    });

    return () => {
      pubnubobj.unsubscribe({
        channels: [
          ChatchannelName,
          channelNameChatModeration,
          WebinarchannelName,
        ],
      });
      pubnubobj.removeListener(listener);
    };
  }, []);

  useEffect(() => {
    try {
      pubnubobj.objects
        .getChannelMetadata({
          channel: globalPollChannel,
        })
        .then((response) => {
          if (response.data && response.data.custom.activePoll) {
            console.log("active poll id activating tab");
            dispatch(updateActivePollStatus(true));
          }
        })
        .catch((error) => {
          console.log("active poll id", error);
        });
    } catch (error) {
      console.log("active poll id catch", error);
    }
  }, []);

  function numberOfAttendees(peoples) {
    let count = 0;
    peoples.forEach((ele) => {
      if (ele?.state?.isAttendee) count++;
    });
    return count;
  }

  function numberOfHosts(peoples) {
    let count = 0;
    peoples.forEach((ele) => {
      if (
        ele?.state?.isPresentor ||
        ele?.state?.isAdmin ||
        ele?.state?.isModerator
      )
        count++;
    });
    return count;
  }

  function numberOfTotalUser() {
    if (
      stateData?.publishStreamObj.isAttendee &&
      !stateData.publishStreamObj.isAttendeeList
    ) {
      return numberOfHosts(stateData.publishStreamObj.peoples);
    } else {
      return (
        numberOfAttendees(stateData.publishStreamObj.peoples) +
        numberOfHosts(stateData.publishStreamObj.peoples)
      );
    }
  }

  // Polls start
  const [polls, setPolls] = useState([]);
  const pubnub = usePubNub();
  //Polls end

  //Webinar status start
  useEffect(() => {
    pubnub.objects
      .getChannelMetadata({
        channel: WebinachannelName,
      })
      .then((response) => {
        // console.log("Webinar status :", response);
        if (response.data.custom?.satus == "liveroom")
          dispatch(updateIsLiveGo(true));
        // console.log("Live updated ... ");
      })
      .catch((error) => {
        console.error("Error getting metadata:", error);
      });
  }, []);

  const onOpenConfirmationModel = () => {
    setIsConfirmationOpen(true);
  };
  const onCloseConfirmationPopUp = () => {
    setIsConfirmationOpen(false);
  };
  // const ischatpanel = stateData.publishStreamObj.isChatPanel;
  // const getChannelMetadata = () => {
  //   console.log("first");
  //   pubnub.objects
  //     .getChannelMetadata({
  //       channel: channelNameChatModeration,
  //     })
  //     .then((response) => {
  //       if (response.data) {
  //         dispatch(
  //           updateIsChatPanel(response.data.custom.isChatPanel || false)
  //         );
  //       }
  //       // console.log('Channel metadata:', response);
  //     })
  //     .catch((error) => {
  //       console.error("Error getting metadata:", error);
  //     });
  // };
  // useEffect(() => {
  //   getChannelMetadata();
  // }, [ischatpanel]);

  useEffect(() => {
    pubnub.objects
      .getChannelMetadata({
        channel: channelNameChatModeration,
      })
      .then((response) => {
        console.log("Channelllllllllllllllll:", response?.data);
        dispatch(updateIsChatPanel(Boolean(response?.data?.custom?.ChatPanel)));
        dispatch(updateIsPrivatChat(Boolean(response?.data?.custom?.PvtChat)));
        dispatch(updateIsPublicChat(Boolean(response?.data?.custom?.PubChat)));
        dispatch(updateIsAttendeeList(Boolean(response?.data?.custom?.people)));
      })
      .catch((error) => {
        /**
         * if channel does not exists
         */
        if (
          error.status.error &&
          error.status.errorData &&
          error.status.errorData.status === 404
        ) {
          if (stateData?.publishStreamObj?.isAdmin) {
            pubnub.objects
              .setChannelMetadata({
                channel: channelNameChatModeration,
                data: {
                  name: "chat",
                  description: "chat channel",
                  custom: {
                    people: stateData?.publishStreamObj?.isAttendeeList,
                    PubChat: stateData?.publishStreamObj?.isPublicChat,
                    PvtChat: stateData?.publishStreamObj?.isPrivateChat,
                    ChatPanel: stateData?.publishStreamObj?.isChatPanel,
                  },
                },
              })
              .then((response) => {
                // console.log("channel data set successfully:", response);
              })
              .catch((error) => {
                console.log("channel data set Error:", error);
              });
          }
        } else {
          console.error("Error getting metadata:", error);
        }
      });
  }, []);

  //People lists/count start

  //Browser close/refresh start
  // useEffect(() => {
  //   const handleTabClose = (event: BeforeUnloadEvent) => {
  //     pubnubobj.unsubscribe({
  //       channels: [PeoplechannelName],
  //     });
  //     pubnubobj.removeListener(listener);
  //   };

  //   window.addEventListener("beforeunload", handleTabClose);

  //   return () => {
  //     window.removeEventListener("beforeunload", handleTabClose);
  //   };
  // }, []);

  async function userLists() {
    // Fetching presence data
    try {
      const presenceResult = await pubnubobj.hereNow({
        channels: [ChatchannelName],
        includeUUIDs: true,
        includeState: true,
      });
      console.log("SideLayout UserLists :: ", presenceResult, new Date());
      dispatch(addPeoples(presenceResult.channels[ChatchannelName].occupants));
      dispatch(addNumberOfPeople(presenceResult.totalOccupancy));
    } catch (e) {
      console.log("SideLayout Error in userLists", e, new Date());
    }
  }

  const Peopleslistener = {
    status: async (statusEvent) => {
      // console.log(statusEvent, "status");
      if (statusEvent.category === "PNConnectedCategory") {
        console.log(
          "SideLayout PubNub Update: PNConnectedCategory",
          new Date()
        );

        try {
          const setSate = await pubnubobj.setState({
            channels: [ChatchannelName],
            state: {
              uuid: stateData?.publishStreamObj?.userUUID,
              userName: stateData?.publishStreamObj?.userName,
              email: stateData?.publishStreamObj?.userEmail,
              mic: !stateData?.publishStreamObj?.muted,
              camera: stateData?.publishStreamObj?.camera,
              isAttendee: stateData?.publishStreamObj?.isAttendee,
              isAdmin: stateData?.publishStreamObj?.isAdmin,
              isModerator: stateData?.publishStreamObj?.isModerator,
              isPresentor: stateData?.publishStreamObj?.isPresenter,
            },
          });
          console.log(
            "SideLayout Set State successfully :: ",
            setSate,
            new Date()
          );
        } catch (e) {
          console.log("SideLayout Error in set state", e, new Date());
        }

        setTimeout(() => {
          userLists();
        }, 2000);
      }
      if (statusEvent.category === "PNNetworkUpCategory") {
        console.log(
          "SideLayout PubNub Update: PNNetworkUpCategory",
          new Date()
        );
      }
      if (statusEvent.category === "PNNetworkDownCategory") {
        console.log(
          "SideLayout PubNub Update: PNNetworkDownCategory",
          new Date()
        );
      }
      if (statusEvent.category === "PNReconnectedCategory") {
        console.log(
          "SideLayout PubNub Update: PNConnectedCategory",
          new Date()
        );
      }
      if (statusEvent.category === "PNAccessDeniedCategory") {
        // setupRefreshToken();
      }
    },
    presence: async function (p) {
      if (p?.channel == PeoplechannelName) {
        if (
          p.action == "join" &&
          p.uuid != stateData.publishStreamObj.userUUID
        ) {
          console.log("SideLayout  presence  join", p, Date());
          const uuid = p.uuid;
          const newmember = await pubnubobj.getState({
            channels: [ChatchannelName],
            uuid: uuid,
          });
          // console.log("SideLayout getState", p, new Date());
          if (
            newmember.channels[ChatchannelName].userName != null ||
            newmember.channels[ChatchannelName].userName != "" ||
            newmember.channels[ChatchannelName].userName != undefined
          ) {
            dispatch(
              addNumberOfPeople(
                stateData.publishStreamObj.numberOfAttendees + 1
              )
            );
            dispatch(
              addnewPeople({
                uuid: newmember.channels[ChatchannelName].uuid,
                state: newmember.channels[ChatchannelName],
              })
            );
          }
        } else if (p.action == "leave" || p.action == "timeout") {
          console.log("SideLayout  presence leave", p, Date());
          dispatch(removePeople(p.uuid));
        }
      }
    },
  };

  useEffect(() => {
    pubnubobj.addListener(Peopleslistener);
    pubnubobj.subscribe({
      channels: [PeoplechannelName],
      withPresence: true,
    });

    return () => {
      pubnubobj.unsubscribe({
        channels: [PeoplechannelName],
        // withPresence: true,
      });
      pubnubobj.removeListener(Peopleslistener);
    };
  }, []);
  //People lists/count end
  return (
    <>
      <div className="sidebar thin-scrollbar">
        <ul className="flex flex-center">
          {/* {stateData.publishStreamObj.isChatPanel &&
            !stateData.publishStreamObj.isAdmin && (
              <li
                key="chat"
                className={`flex flex-center ${
                  activeIndex === 0 ? "active" : ""
                }`}
                onClick={() => sideClickHandler(0)}
              >
                <a href="#" className="flex flex-center">
                  <div className="ew-action-icon">
                    <img src={defaultChatIcon} alt="Chat" className="default" />
                    <img src={activeChatIcon} alt="Chat" className="active" />
                  </div>
                  <span>Chat</span>
                </a>
              </li>
            )} */}

          <li
            key="chat"
            className={`flex flex-center ${activeIndex === 0 ? "active" : ""} ${
              isNewMessage && activeIndex !== 0 ? "new-notification" : ""
            }`}
            onClick={() => sideClickHandler(0)}
          >
            <a href="#" className="flex flex-center">
              <div className="ew-action-icon">
                <img src={defaultChatIcon} alt="Chat" className="default" />
                <img src={activeChatIcon} alt="Chat" className="active" />
              </div>
              <span>Chat</span>
            </a>
          </li>

          {icons.map((icon, index) => (
            <li
              key={index + 1} // Adjust the key if needed
              className={`flex flex-center ${
                activeIndex === index + 1 ? "active" : ""
              }`}
              onClick={() => sideClickHandler(index + 1)}
            >
              <a href="#" className="flex flex-center">
                <div className="ew-action-icon">
                  <img
                    src={icon.defaultIcon}
                    alt={icon.label}
                    className="default"
                  />
                  <img
                    src={icon.activeIcon}
                    alt={icon.label}
                    className="active"
                  />
                </div>
                <span>{icon.label}</span>
                {icon.label === "People" && (
                  <span className="count">{numberOfTotalUser()}</span>
                )}
                {/* {icon.label === "Polls" && stateData?.publishStreamObj.isAttendee && stateData.publishStreamObj.isNewPollPublished && (
                  <span className="count"></span>
                )} */}
              </a>
            </li>
          ))}
        </ul>

        {(isAttendee || isModerator) && (
          <>
            <ul className="flex flex-center leave-event">
              <li className="flex flex-center">
                <a
                  href="#"
                  className="flex flex-center"
                  onClick={onOpenConfirmationModelFunc}
                >
                  <div className="ew-action-icon">
                    <img src={leaveSession} alt="leave event" />
                  </div>
                  <span>Leave</span>
                </a>
              </li>
            </ul>
          </>
        )}
      </div>
      {/* {isConfirmationOpen && (
        <ConfirmationPopupAttendee
          isConfirmationOpen={isConfirmationOpen}
          onCloseConfirmationPopUp={onCloseConfirmationPopUp}
          SessionHandle={onLeaveClick}
        />
      )} */}

      {
        <ConfimationPopToAttendeeToJoinAsCohost
          newUrl={newUrl}
          isConfirmationOpen={isConfirmationOpen}
          onCloseConfirmationPopUp={onCloseConfirmationPopUp}
        />
      }
    </>
  );
};
