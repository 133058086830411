import {
  configureStore,
  createSerializableStateInvariantMiddleware,
  Tuple,
  combineReducers,
} from "@reduxjs/toolkit";
import { PublishStreamReducer } from "./Reducers/PublishStreamReducer";

const serializableMiddleware = createSerializableStateInvariantMiddleware({});

const store = configureStore({
  reducer: combineReducers({
    PublishStreamReducer: PublishStreamReducer,
  }),
  middleware: () => new Tuple(serializableMiddleware),
});

export default store;
