import { useEffect, useState } from "react";
import { pollStatus } from "./poll-enum";
import { usePubNub } from "pubnub-react";
import { useSelector } from "react-redux";
import { Polls } from "./Polls";
import { postRequestWithFormdata } from "../../libs/APIs/apiCall";
import { setAuthToken } from "../../libs/APIs/auth";

interface props {
  createPollHandler: () => void;
  updatePollHandler: (poll) => void;
}

export const PollsMain = ({ createPollHandler, updatePollHandler }: props) => {
  // Settings popup tabs
  const stateData = useSelector((state: any) => state.PublishStreamReducer);

  //Backend API call start
  const pubnubobj = usePubNub();
  const [pollsData, setPollsData] = useState([]);
  const [isDataReady, setIsDataReady] = useState(false);

  useEffect(() => {


    const setAssignedPolls = (pollIdsArr) => {
      let globalPollChannel =
      "Polls" +
      "." +
      stateData.publishStreamObj.webinarid +
      "." +
      stateData.publishStreamObj.sessionId;

    pubnubobj.objects
      .getChannelMetadata({
        channel: globalPollChannel,
      })
      .then((response) => {
        let existingActivePoll = response.data.custom.activePoll;
        console.log("got pollIds", existingActivePoll);

        pubnubobj.objects
          .setChannelMetadata({
            channel: globalPollChannel,
            data: {
              custom: {
                assignedPoll: JSON.stringify(pollIdsArr),
                activePoll: existingActivePoll,
              },
            },
          })
          .then((setpollidRes) => {
            console.log("setpollidRes ", setpollidRes);
            setIsDataReady(true);
          })
          .catch((err) => {
            console.log("Error setting channel metadata 03 ::: ", err);
          });
      })
      .catch((error) => {
        /**
         * channel does not exists
         */
        if (
          error.status.error &&
          error.status.errorData &&
          error.status.errorData.status === 404
        ) {
          pubnubobj.objects
            .setChannelMetadata({
              channel: globalPollChannel,
              data: {
                custom: {
                  assignedPoll: JSON.stringify(pollIdsArr),
                  activePoll: "",
                },
              },
            })
            .then((r) => {
              setIsDataReady(true);
            })
            .catch((err) => {
              console.log("Error setting channel metadata 04 ::: ", err);
            });
        }
      });
    };
    
    let pollsArrData = [],
    pollIds = [];

    const ew_jwt_token = localStorage.getItem("ew_jwt_token");
    (async () => {
      setAuthToken(ew_jwt_token);
      let bodyFormData = new FormData();
      bodyFormData.append("webinar_id", stateData.publishStreamObj.webinarid);

      const resAPI = await postRequestWithFormdata(
        "https://test-v2.easywebinar.com/hoa_webinar_sessions.php",
        bodyFormData
      );
      // console.log("Polls data response from API  ::: ", res);


    //   let pollsresp = [
    //     {
    //         "id": "1234",
    //         "poll_question": "Poll Title",
    //         "enablePoll": "1",
    //         "show_result": "1",
    //         "poll_type": "1",
    //         "start_time": null,
    //         "end_time": null,
    //         "options": [
    //             {
    //                 "id": "3827",
    //                 "poll_questions_id_fk": "1234",
    //                 "option": "Option 1",
    //                 "status": "1"
    //             },
    //             {
    //                 "id": "3828",
    //                 "poll_questions_id_fk": "1234",
    //                 "option": "Option 2",
    //                 "status": "1"
    //             }
    //         ]
    //     },
    //     {
    //         "id": "1235",
    //         "poll_question": "Poll Title 02",
    //         "enablePoll": "1",
    //         "show_result": "1",
    //         "poll_type": "1",
    //         "start_time": null,
    //         "end_time": null,
    //         "options": [
    //             {
    //                 "id": "3829",
    //                 "poll_questions_id_fk": "1235",
    //                 "option": "Option 11",
    //                 "status": "1"
    //             },
    //             {
    //                 "id": "3830",
    //                 "poll_questions_id_fk": "1235",
    //                 "option": "Option 22",
    //                 "status": "1"
    //             }
    //         ]
    //     }
    // ]

    // let res = {
    //   data : {
    //     polls: pollsresp
    //   }
    // }
      if (resAPI && resAPI.data && Array.isArray(resAPI.data.polls) && resAPI.data.polls.length > 0) {
        for (let i = 0; i < resAPI.data.polls.length; i++) {
          setPollsData([]);
          let poll = resAPI.data.polls[i];
          let pollOptions = poll.options.map((option) => {
            return {
              id: option.id,
              // poll_questions_id_fk: option.poll_questions_id_fk,
              val: option.option,
            };
          });
          let pollData = {
            id: poll.id,
            poll_question: poll.poll_question,
            status: pollStatus.saved,
            start_time: poll.start_time,
            end_time: poll.end_time,
            options: pollOptions,
            enablePoll: poll.enablePoll,
            show_result: poll.show_result,
            poll_type: poll.poll_type
          };
          setPollsData((pollsData) => [...pollsData, pollData]);
          pollsArrData.push(pollData);
          pollIds.push(poll.id);

          // Check if it's the last iteration
          // if (i === res.data.polls.length - 1) {
          //   // Call your function here
          //   // setAssignedPolls(pollIds);
          // }
          
        }


        /**
         * before setting channel metadata, get status first if its active or not
         */
        for (let i = 0; i < pollsArrData.length; i++) {
          let pollSpecificChannelName =
            "Polls." +
            stateData.publishStreamObj.sessionId +
            "." +
            pollsArrData[i].id;
          console.log("pollSpecificChannelNames > ", pollSpecificChannelName);


          let resultSpecificChannelName = "results." + stateData.publishStreamObj.sessionId + "." + pollsArrData[i].id;
          pubnubobj.subscribe({ channels: [resultSpecificChannelName] });

          let defaultPollStatus = pollStatus.saved;
          pubnubobj.objects
            .getChannelMetadata({
              channel: pollSpecificChannelName,
            })
            .then((res) => {
              if (
                res.data.status !== undefined ||
                res.data.status !== null ||
                res.data.status !== ""
              ) {
                console.log("got existng poll status., ", res.data);
                defaultPollStatus = res.data.status;

                setPollsData((pollsData) =>
                  pollsData.map((item) =>
                    item.poll_id === pollsArrData[i].id
                      ? { ...item, status: defaultPollStatus }
                      : item
                  )
                );
              }

              pubnubobj.objects
                .setChannelMetadata({
                  channel: pollSpecificChannelName,
                  data: {
                    name: `Poll ${pollsArrData[i].id}`,
                    type: "poll",
                    description: pollsArrData[i].poll_question,
                    status: defaultPollStatus,
                    custom: {
                      poll_id: pollsArrData[i].id,
                      session_id: stateData.publishStreamObj.sessionId,
                      Status: pollsArrData[i].status,
                      Question: pollsArrData[i].poll_question,
                      options: JSON.stringify(pollsArrData[i].options),
                      pollData: JSON.stringify(resAPI.data.polls[i])
                    },
                  },
                })
                .then((res) => {
                  console.log("updated poll status and other elements", res.data);
                })
                .catch((err) => {
                  console.log("Error ::: ", err);
                });
            })
            .catch((err) => {
              /**
               * if channel does not exists
               */
              if (
                err.status.error &&
                err.status.errorData &&
                err.status.errorData.status === 404
              ) {
                pubnubobj.objects
                  .setChannelMetadata({
                    channel: pollSpecificChannelName,
                    data: {
                      name: `Poll ${pollsArrData[i].id}`,
                      type: "poll",
                      description: pollsArrData[i].poll_question,
                      status: defaultPollStatus,
                      custom: {
                        poll_id: pollsArrData[i].id,
                        session_id: stateData.publishStreamObj.sessionId,
                        Status: pollsArrData[i].status,
                        Question: pollsArrData[i].poll_question,
                        options: JSON.stringify(pollsArrData[i].options),
                        pollData: JSON.stringify(pollsArrData[i])
                      },
                    },
                  })
                  .then((res) => {
                    console.log("catched error and set data again", res.data);
                  })
                  .catch((err) => {
                    console.log("still not able to set data, Error ::: ", err);
                  });
              } else {
                console.log("Error ::: >>> ", err);
              }
            });

            if (i === pollsArrData.length - 1) {
              // Call your function here
              setAssignedPolls(pollIds);
            }

        }

      } else {
        setAssignedPolls([]);
      }
      
    })();

    
    // const res = {
    //   polls: [
    //     {
    //       id: "1287",
    //       poll_question: "What is your name ?",
    //       enablePoll: "1",
    //       show_result: "1",
    //       poll_type: "1",
    //       start_time: null,
    //       end_time: null,
    //       options: [
    //         {
    //           id: "5085",
    //           poll_questions_id_fk: "1287",
    //           option: "1",
    //           status: "1",
    //         },
    //         {
    //           id: "5086",
    //           poll_questions_id_fk: "1287",
    //           option: "2",
    //           status: "1",
    //         },
    //         {
    //           id: "5087",
    //           poll_questions_id_fk: "1287",
    //           option: "3",
    //           status: "1",
    //         },
    //       ],
    //     }
    //   ],
    // };

    

    //pubnub start

   
    

  }, []);
  //Backend api call end

  function createPollMain() {
    createPollHandler();
  }

  function updatePollHandlerMain(poll) {
    updatePollHandler(poll);
  }

  return (
    <div>
      {/* Conditionally render Polls component only if data is ready */}
      {isDataReady ? (
        <Polls
          createPollHandler={createPollMain}
          updatePollHandler={updatePollHandlerMain}
        />
      ) : (
        <div className="loader">
          {/* Your loader component or HTML */}
          <div className="flex flex-center loader-bg">
              <div className='blur-bg'></div>
              <div className='flex flex-center section-loader'>
                  <div className="circle-loader"></div>
                  <span>Loading...</span>
              </div>
          </div>
        </div>
      )}
    </div>
    
  );
};
// export default Polls;
