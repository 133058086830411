import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { setAuthToken } from "../../libs/APIs/auth";
import { postRequest } from "../../libs/APIs/apiCall";
import { PresentorStream } from "./PresentorStream";
import Loader from "../Loader/Loader";

export const Presenter = () => {
  //redux
  const stateData = useSelector((state: any) => state.PublishStreamReducer);
  const dispatch = useDispatch();

  //Loader
  const [loader, setLoader] = useState(true);

  //Routes parameter read
  const [params, setParams] = useSearchParams();
  const [pname, setpname] = useState(params.get("pname"));
  const [pemail, setpemail] = useState(params.get("pname"));
  const [key, setkey] = useState(params.get("key"));
  const [session_id, setSessionId] = useState(params.get("sid"));
  const [userName, setuserName] = useState("");
  const [token, settoken] = useState("");
  const [streamName, setstreamName] = useState("");
  const [subscription_token, setsubscription_token] = useState("");
  //end

  // Backend API call start
  function updateReduxState(
    name,
    email,
    token,
    subscription_token,
    streamName,
    sessionId,
    webinarid,
    userUUID,
    event_name,
    attendeeLink,
    moderatorLink,
    presentorLink,
    userKey,
  ) {
    dispatch({
      type: "ADD_USERNAME",
      payload: {
        ...stateData?.publishStreamObj,
        userName: name,
        userEmail: email,
        token: token,
        subscribeToken: subscription_token,
        streamName: streamName,
        sessionId: sessionId,
        webinarid: webinarid,
        userUUID: userUUID,
        isPresenter: true,
        event_name: event_name,
        attendeeLink: attendeeLink,
        moderatorLink: moderatorLink,
        presentorLink: presentorLink,
        userKey:userKey
      },
    });
  }

  useEffect(() => {
    (async () => {
      // localStorage.setItem(
      //   "ew_jwt_token",
      //   "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE3MjU1OTk5NzcsImV4cCI6MTcyNTYxNDM3NywiZGF0YSI6eyJ1c2VybmFtZSI6InRlc3QgdXNlciIsInVzZXJfaWQiOjc5MzksImV4cG9ydF9saW1pdCI6IjIwMCJ9fQ.s6QbWtqE9eHwSmE4AsHD1jbIoNMPAhEvflQlpXP2Fdo"
      // );

      if(localStorage.getItem("Blocked")){
        const baseUrl =
          "https://business-owner.easywebinar.live/blocked-attendee"; // Target URL
        // const newUrl = `${baseUrl}?id=${stateData.publishStreamObj?.webinarid}`;
        window.location.href = baseUrl;
      }

      const ew_jwt_token = localStorage.getItem("ew_jwt_token");
      setAuthToken(ew_jwt_token);
      const res = await postRequest(
        "https://test-v2.easywebinar.com/v1/easywebinar/v1/presenter/join/?key=" +
          key +
          "&pname=" +
          pname +
          "&pemail=" +
          pemail
      );

      //end

      setuserName(res.data.name);
      settoken(res.data.token);
      setstreamName(res.data.stream[0].streamName);
      setsubscription_token(res.data.subscription_token);
      updateReduxState(
        res.data.name,
        res.data.email,
        res.data.token,
        res.data.subscription_token,
        res.data.stream[0].streamName,
        res.data.session_id,
        res.data.webinar_id,
        res.data.pn_user_id,
        res.data?.webinar_details?.event_name,
        res.data.attendee_link,
        res.data.moderator_link,
        res.data.presenter_link,
        res.data.user_key,


      );
      setLoader(false);
    })();
  }, []);
  // Backend API call end

  return (
    <>
      {loader && <Loader />}
      {!loader && (
        <PresentorStream
          userName={userName}
          streamName={streamName}
          token={token}
          subscription_token={subscription_token}
        />
      )}
    </>
  );
};
