import React, { useEffect, useRef, useState } from "react";
import "../../assets/styles/main.scss";
import playIcon from "../../assets/images/play-icon.svg";
import { SidebarLayout } from "../SidebarLayout/SidebarLayout";
import { useToast } from "@chakra-ui/react";
import ViewerPre from "../../libs/view/ViewerPre";
import { useNavigate, useSearchParams } from "react-router-dom";
import { setAuthToken } from "../../libs/APIs/auth";
import { postRequest } from "../../libs/APIs/apiCall";
import { useDispatch, useSelector } from "react-redux";
import { Offers } from "../Offers/Offers";
import { Polls } from "../Polls/Polls";
import { PollsAttendee } from "../Polls/PollsAttendee";
import { PeopleList } from "../PeopleList/PeopleLists";
import { PubNubProvider } from "pubnub-react";
import PubNub from "pubnub";
import ChatComponent from "../PubNubChat/ChatComponent";
// import leaveSession from "../../assets/images/leave-session.svg";
import { ScreenShare } from "../GreenRoom/share-screen-details";
import { Participent } from "../GreenRoom/people-participant";
import loader from "../../assets/images/Liveroom-Loader.gif";
import offClickSoundIcon from "../../assets/images/off-sound-icon.svg";
import onClickSoundIcon from "../../assets/images/on-sound-icon.svg";

import {
  screenShareSourceId,
  updateIsLiveGo,
  updateIsSideBar,
} from "../../Store/Actions/PublishStreamAction";
import axios from "axios";
import { ConfirmationPopupAttendee } from "../LiveRoomPopUp/ConfimationPopupAttendee";
import { Tooltip } from "react-tooltip";
import refreshIcon from "../../assets/images/refresh-icon.svg";
import { formatTime } from "../../libs/UtilityFunctions/UtilFunctions";
import { MSG_ENM } from "../../libs/signal/signalEnum";
import NetwotkUnstable from "../CommonComponents/NetwotkUnstable";
import { useNetworkStatus } from "../../libs/hooks/NetworkStabilityListner";
import { ParticipentForAttendeeScreen } from "./people-participant-Attendee-screen";
import { ScreenShareForAttendeeScreen } from "./share-screen-details-Attendee-screen";
import { ScreenShareDeniedPopup } from "../PopUp/ScreenShareDeniedPopup/ScreenShareDeniedPopup";
interface props {
  attendeeData?: any;
  streamAccountId: string;
  streamName: string;
  subscriberToken: string;
  userName;
}

export const Attendee = ({
  attendeeData,
  streamAccountId,
  streamName,
  subscriberToken,
  userName,
}: props) => {
  const stateData = useSelector((state: any) => state.PublishStreamReducer);
  const dispatch = useDispatch();
  const minutesCounterRef = useRef(0);

  // console.log("stateData : ", stateData);
  const [time, setTime] = useState(0);
  const { remoteTrackSources, startViewer, startViewerAttendee, stopViewer } =
    ViewerPre({ streamAccountId, streamName, subscriberToken });

  //screen share by other presentor
  const [isScreenSharedByPresentor, setIsScreenSharedByPresentor] =
    React.useState(false);
  const [screenSharedStreamValue, setscreenSharedStreamValue] =
    React.useState<any>();
  const [screenSharedUserNameValue, setscreenSharedUserNameValue] =
    React.useState<any>();
  const [screenSharedSourceIDValue, setscreenSharedSourceIDValue] =
    React.useState<any>();
  const [isUserListActive, setIsUserListActive] = useState(true);
  console.log(
    "remoteTrackSources  :: ",
    remoteTrackSources,
    remoteTrackSources.size
  );
  const networkStatus = useNetworkStatus();
  useEffect(() => {
    let isScreenSharePresentorAvailable = false;
    if (remoteTrackSources.size > 0) {
      setIsUserListActive(false);

      if (muteAllStream == true) setUnMutePopup(true);
    }

    Array.from(remoteTrackSources).map(([sourceId, { mediaStream }]) => {
      let splittedSourceId = sourceId.split(":");
      if (splittedSourceId[1] == "screenShare") {
        isScreenSharePresentorAvailable = true;
        setscreenSharedUserNameValue(splittedSourceId[0]);
        setscreenSharedStreamValue(mediaStream);
        setIsScreenSharedByPresentor(true);
        setscreenSharedSourceIDValue(sourceId);

        //save screenshare source id
        dispatch(screenShareSourceId(sourceId));
      }
    });

    if (!isScreenSharePresentorAvailable) {
      setscreenSharedUserNameValue(undefined);
      setscreenSharedStreamValue(undefined);
      setIsScreenSharedByPresentor(false);
      setscreenSharedSourceIDValue(undefined);
    }
  }, [remoteTrackSources]);
  // console.log("remoteTrackSources  :: ", remoteTrackSources);
  //end

  // Backend API call start
  useEffect(() => {
    // startViewer();
    // startViewerAttendee(streamName, streamAccountId, subscriberToken);
    // setTimeout(() => {
    startViewer();
    // }, 0);
    return () => {
      stopViewer();
    };
  }, []);

  const [isConfirmationOpenAction, setIsConfirmationOpenAction] =
    useState<boolean>(false);
  const [title, setTitle] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [state, setState] = useState({
    uuid: "",
    action: "",
    email: "",
  });
  const [dropdownOpenAttendee, setDropdownOpenAttendee] = useState(null);
  const [submenuOpenAttendee, setSubmenuOpenAttendee] = useState(false);

  const [dropdownOpen, setDropdownOpen] = useState(null);
  const [submenuOpen, setSubmenuOpen] = useState(false);

  const onConfirmationOpenAction = () => {
    setIsConfirmationOpenAction(true);
  };
  const onConfirmationCloseAction = () => {
    setIsConfirmationOpenAction(false);
  };

  useEffect(() => {
    let timer;

    if (stateData?.publishStreamObj?.isLiveGo) {
      timer = setInterval(() => {
        setTime((prevTime) => prevTime + 1);
      }, 1000);
    } else {
      setTime(0);
    }

    return () => {
      clearInterval(timer);
    };
  }, [stateData?.publishStreamObj?.isLiveGo]);

  useEffect(() => {
    if (!stateData?.publishStreamObj?.isAttendee) {
      // If not an attendee, do not set up the interval
      return;
    }
    const apiCallAfterOneMinute = async () => {
      try {
        // Increment the minutes counter
        const presentAtMinutes = minutesCounterRef.current + 1;
        minutesCounterRef.current = presentAtMinutes;

        // Calculate `present_at_date_time` as 4 minutes from now
        const presentAtDateTime = Date.now() + presentAtMinutes * 60000; // `presentAtMinutes` minutes in milliseconds

        console.log("presentAtMinutes", presentAtMinutes);
        console.log("presentAtDateTime", presentAtDateTime);

        let headers = {
          "Content-Type": "application/json",
          Authorization: `${attendeeData?.Authorization}`,
          "X-EW-WEBINAR-ID": `${stateData.publishStreamObj.webinarid}`,
          "X-EW-KEY": `${attendeeData?.attendee_data?.key}`,
          // "X-EW-TIMESTAMP": `${Math.floor(Date.now() / 1000)}`, // Current Unix timestamp in seconds
          "X-EW-TIMESTAMP": `${attendeeData?.anstimestamp}`,
        };

        let payload = {
          webinar_id: stateData.publishStreamObj.webinarid,
          live_session_id: attendeeData?.session_id,
          is_replay: "0",
          attendee_key: attendeeData?.attendee_data?.key,
          event_version_id: "",
          replay_version_id: "",
          global_session_id: attendeeData?.attendee_data?.global_session_id,
          owner_id: attendeeData?.meta?.owner_id,
          present_at_minutes: presentAtMinutes, // Updated value
          present_at_date_time: presentAtDateTime, // Updated value
        };

        axios({
          method: "post",
          url: "https://w42st912za.execute-api.us-east-1.amazonaws.com/test/events/attendee-presence-test",
          data: payload,
          headers: headers,
        });
      } catch (error) {}
    };

    // Initial delay
    const initialDelay = 60000; // 1 minute in milliseconds
    let intervalId;
    // Initial API call after one minute
    const timeoutId = setTimeout(() => {
      apiCallAfterOneMinute(); // Call API initially

      // Set up interval for subsequent API calls every minute
      intervalId = setInterval(apiCallAfterOneMinute, 60000);

      // Cleanup interval on component unmount
      return () => clearInterval(intervalId);
    }, initialDelay);

    // Cleanup timeout on component unmount
    return () => {
      clearTimeout(timeoutId);
      clearInterval(intervalId);
    };
  }, []);

  // Backend API call end

  // Sidebar Layout start
  const [sideBarActive, setSideBarActive] = useState(false);
  const [index, setIndex] = useState(0);
  // const sideClickHandler = (index: number, value?: string) => {
  //   if (value === "hide") {

  //     setSideBarActive((pre) => false);
  //     dispatch(updateIsSideBar(false))
  //   } else {
  //     if (index == 0) {
  //       setIndex(0);
  //       setSideBarActive(true);
  //       dispatch(updateIsSideBar(true))
  //     } else {
  //       setIndex(index);
  //       setSideBarActive(true);
  //       dispatch(updateIsSideBar(true))
  //     }
  //   }
  // };
  useEffect(() => {
    setSideBarActive(stateData.publishStreamObj.sideBarActive);
  }, [stateData.publishStreamObj.sideBarActive]);

  const sideClickHandler = (clickedIndex: number, value?: string) => {
    if (value === "hide") {
      setSideBarActive(false);
      dispatch(updateIsSideBar(false));
    } else {
      // Check if the same tab is clicked
      if (index === clickedIndex) {
        // Toggle the sidebar when clicking the same tab
        setSideBarActive((prevActive) => {
          const newActive = !prevActive; // Toggle active state
          dispatch(updateIsSideBar(newActive));
          return newActive;
        });
      } else {
        // Handle different tab clicks
        setIndex(clickedIndex);
        setSideBarActive(true);
        dispatch(updateIsSideBar(true));
      }
    }
  };

  // Sidebar Layout end

  //pubnub start
  const pubnub = new PubNub({
    publishKey: "pub-c-21a30db6-d198-4b69-942d-3d4ec07e481d",
    subscribeKey: "sub-c-0892f98e-0dda-49b9-88c5-fa1124a478cb",
    uuid: stateData?.publishStreamObj?.userUUID,
    autoNetworkDetection: true,
    restore: true,
    heartbeatInterval: 300,
  });
  //pubnub end

  // Leave green room start
  const navigate = useNavigate();

  const leaveroom = async () => {
    const leaveApiforAttendee = async () => {
      try {
        let headers = {
          "Content-Type": "application/json",
        };
        let payload = {
          webinar_id: stateData.publishStreamObj.webinarid,
          attendee_key: attendeeData?.attendee_data?.key,
          sid: stateData.publishStreamObj.sessionId,
        };
        await axios({
          method: "post",
          url: "https://test-v2.easywebinar.com/v1/easywebinar/v1/attendee/leave",
          data: payload,
          headers: headers,
        });
      } catch (error) {
        console.log("error", error);
      }
    };

    if (stateData?.publishStreamObj?.isAttendee) {
      leaveApiforAttendee();
    }

    navigate("/Thanks");
  };
  //end

  // Polls popup
  const [isCreatePollsOpen, setCreatePollsOpen] = useState(false);
  const toggleCreatePollsPopup = (event: { preventDefault: () => void }) => {
    setCreatePollsOpen(!isCreatePollsOpen);
  };

  function createPoll() {
    setCreatePollsOpen(true);
  }

  function updatePollHandlerGreen(poll) {
    setCreatePollsOpen(true);
  }

  //Chat message handler start
  const childRef = useRef(null);
  const messageEventHandler = (event) => {
    childRef.current.handleMessageHandler(event);
  };
  //chat message handler end

  //Live status update start
  const [IsLiveGo, setIsLiveGo] = React.useState(
    stateData?.publishStreamObj?.isLiveGo
  );

  useEffect(() => {
    setIsLiveGo(stateData?.publishStreamObj?.isLiveGo);
  }, [stateData?.publishStreamObj?.isLiveGo]);

  const WebinachannelName =
    "Webinar." +
    stateData.publishStreamObj.webinarid +
    "." +
    stateData.publishStreamObj.sessionId;
  useEffect(() => {
    pubnub.objects
      .getChannelMetadata({
        channel: WebinachannelName,
      })
      .then((response) => {
        // console.log("Webinar status:", response);
        if (response.data.custom?.satus == "liveroom") {
          dispatch(updateIsLiveGo(true));
          setIsLiveGo(true);
        }
      })
      .catch((error) => {
        console.error("Error getting metadata:", error);
      });

    //to get live time in Attendee and miderator also
    const liveRoomChannel = `${
      "live-room-" + stateData.publishStreamObj.webinarid
    }`;

    pubnub.objects
      .getChannelMetadata({
        channel: liveRoomChannel,
      })
      .then((response) => {
        if (response.data.custom?.status == "live") {
          let prevTimeStamp = response.data.custom?.currentTimestamp;
          if (typeof prevTimeStamp !== "number") {
            prevTimeStamp = Number(prevTimeStamp); // Convert to number if possible
            if (isNaN(prevTimeStamp)) {
              // Handle the case where conversion fails
              console.error(
                "Invalid timestamp received:",
                response.data.custom?.currentTimestamp
              );
              return;
            }
          }

          const currentTimestamp = Date.now();
          const diffInMilliseconds = currentTimestamp - prevTimeStamp;
          const diffInSeconds = Math.floor(diffInMilliseconds / 1000);

          setTime(diffInSeconds);
        }
      })
      .catch((error) => {
        console.error("Error getting metadata:", error);
      });
  }, []);

  const calculateIsMutedOrNot = (sourceId: string) => {
    if (stateData?.publishStreamObj?.isAdmin) {
      if (sourceId == "main") {
        return true;
      } else {
        return false;
      }
    } else {
      if (sourceId == stateData?.publishStreamObj.userName) {
        return true;
      } else {
        return false;
      }
    }
  };

  const handleClickForConfirmationPopup = (
    uuid,
    action,
    isPresenterOrAttendee,
    index,
    email
  ) => {
    setState((prevState) => ({
      ...prevState, // Keep existing state
      uuid,
      action,
      email, // Update with new values
    }));
    if (action === MSG_ENM.MAKE_ATTENDEE) {
      setTitle("Make Attendee");
      setMessage("Are you sure, You want to convert this cohost to attendee");
    } else if (action === MSG_ENM.MAKE_COHOST) {
      setTitle("Make Cohost");
      setMessage("Are you sure? You want to convert  this attendee to cohost");
    } else if (action === MSG_ENM.BLOCK) {
      setTitle("Block");
      if (isPresenterOrAttendee == "presenter") {
        setMessage("Are  you sure? You  want to Block this presenter");
      } else {
        setMessage("Are  you sure? You  want to Block this attendee");
      }
    }

    onConfirmationOpenAction();
  };
  //end

  //Video status check
  function checkVideoStatus(userName) {
    let splittedSourceId = userName.split(":");
    if (splittedSourceId[1] == "screenShare") return true;
    if (userName == "main") {
      const peopleDetails = stateData.publishStreamObj.peoples.filter(
        (ele) => ele?.state?.isAdmin == true
      );
      return peopleDetails[0]?.state?.camera;
    }
    const peopleDetails = stateData.publishStreamObj.peoples.filter(
      (ele) => ele?.state?.userName == userName
    );
    if (peopleDetails[0]?.state?.isAttendee) return false;
    else if (peopleDetails[0]?.state?.isModerator) return false;
    return peopleDetails[0]?.state?.camera;
    return true;
  }
  //end

  //Confirmation popup start
  const [isConfirmationOpen, setIsConfirmationOpen] = useState<boolean>(false);
  const onOpenConfirmationModel = () => {
    setIsConfirmationOpen(true);
  };
  const onCloseConfirmationPopUp = () => {
    setIsConfirmationOpen(false);
  };
  //end

  // For mobile scss
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    // Function to detect if the device is mobile
    const checkIfMobile = () => {
      const userAgent = navigator.userAgent || navigator.vendor;
      if (/android/i.test(userAgent)) {
        setIsMobile(true);
      } else if (
        /iPad|iPhone|iPod/.test(userAgent) &&
        !(window as any).MSStream
      ) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    // Initial check
    checkIfMobile();
  }, []);

  // Refresh the page
  const refresh = () => {
    // window.location.reload();
    startViewer();
    setIsUserListActive(true);
  };

  const [muteAllStream, setmuteAllStream] = useState(true);

  const [unMutePopup, setUnMutePopup] = useState(false);
  const onScreenShareDeniedClose = () => {
    setUnMutePopup(false);
    setmuteAllStream(false);
  };

  return (
    <>
      <div
        //   className={`ew-container-layout attendee-layout ${
        //     sideBarActive ? "open-sidebar" : ""
        //   // eslint-disable-next-line no-sequences
        //   }`,
        //   { '': true, 'mobile': isMobile }
        // }
        className={`ew-container-layout attendee-layout ${
          sideBarActive ? "open-sidebar" : ""
        } ${isMobile ? "mobile" : ""} ${
          stateData?.publishStreamObj?.isModerator ? "moderator-view" : ""
        }`}
      >
        <div className="left-layout overflow-hidden">
          {/* EW Header */}
          <div className="layout-header flex flex-align-center flex-justify-between">
            <div className="flex flex-align-center overflow-hidden">
              <span className="ew-logo hide-on-mobile">
                <svg
                  width="57"
                  height="55"
                  viewBox="0 0 57 55"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="Logo-Symbol" clip-path="url(#clip0_1147_18554)">
                    <path
                      id="Rounded Rectangle 1"
                      d="M49.875 0H7.125C3.18997 0 0 3.2119 0 7.17397V47.826C0 51.7881 3.18997 55 7.125 55H49.875C53.81 55 57 51.7881 57 47.826V7.17397C57 3.2119 53.81 0 49.875 0Z"
                      fill="#22B060"
                    />
                    <g id="Group 694">
                      <path
                        id="Shape 1"
                        d="M39.5517 15L46.0574 15.0957C46.0574 15.0957 36.6654 27.9366 46.184 41.2393H39.4567C39.4567 41.2393 34.6716 37.053 34.5308 28.5672C34.5297 28.5684 34.3877 20.1829 39.5517 15Z"
                        fill="white"
                      />
                      <path
                        id="Shape 1 copy 3"
                        d="M27.7883 15L34.294 15.0957C34.294 15.0957 24.902 27.9366 34.4206 41.2393H27.6933C27.6933 41.2393 22.9082 37.053 22.7675 28.5672C22.7675 28.5684 22.6256 20.1829 27.7883 15Z"
                        fill="white"
                      />
                      <path
                        id="Shape 1 copy 4"
                        d="M16.0202 15L22.5259 15.0957C22.5259 15.0957 13.1339 27.9366 22.6526 41.2393H15.9264C15.9264 41.2393 11.1413 37.053 11.0006 28.5672C10.9994 28.5684 10.8575 20.1829 16.0202 15Z"
                        fill="white"
                      />
                    </g>
                  </g>
                  <defs>
                    <clipPath id="clip0_1147_18554">
                      <rect width="57" height="55" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </span>
              <div className="ew-event-details">
                <h3 className="ew-event-title">
                  {stateData.publishStreamObj.event_name}
                </h3>
                <span
                  className={`not-live-label flex-justify-center flex-align-center not-live-label ${
                    IsLiveGo ? "event-live" : "event-not-live"
                  }`}
                >
                  <span className="status-sign"></span>{" "}
                  {IsLiveGo ? "LIVE " : "OFF AIR"}
                  <span className="event-time">{formatTime(time)}</span>
                </span>
                <span></span>
              </div>
            </div>
            <div className="flex flex-align-center">
              <div className="ew-event-actions flex flex-align-center flex-justify-center">
                {/* <button type="button" className="flex flex-center button go-live">
                Go Live
              </button> */}
                {/* <button onClick={leaveroom}>
                <img src={endSession} alt="" />
              </button> */}
              </div>
            </div>
          </div>

          {/* {(networkStatus === "unstable" || networkStatus === "offline") && (
            <NetwotkUnstable />
          )} */}

          {/* EW Video Conatiner */}
          {isUserListActive == true && (
            <div className="ew-loader-con">
              <div className="dummmy-loader-bg"></div>
              <div className="ew-blur"></div>
              <div className="loader-inner">
                <div className="square-container">
                  <div className="loading_box">
                    <div className="ewp_bar bar1"></div>
                    <div className="ewp_bar bar2"></div>
                    <div className="ewp_bar bar3"></div>
                  </div>
                </div>
                <span>Configuring the webinar room</span>
              </div>
            </div>
          )}

          {isUserListActive == false && isScreenSharedByPresentor == true ? (
            <div className="ew-video-container attendee-layout overflow-hidden w-full h-full">
              <div
                className={
                  "presenters active-presenter-" +
                  (remoteTrackSources.size - 1) +
                  " screen_shared_fullscreen"
                }
              >
                <div className="ew-n-presenters">
                  {unMutePopup && (
                    <div
                      className="click-for-sound"
                      onClick={onScreenShareDeniedClose}
                    >
                      <span>
                        <img src={offClickSoundIcon} className="off" alt="" />
                        <img src={onClickSoundIcon} className="on" alt="" />
                        Click for sound
                      </span>
                    </div>
                  )}

                  {isScreenSharedByPresentor && (
                    <ScreenShareForAttendeeScreen
                      name={userName}
                      screenSharedUsername={screenSharedUserNameValue}
                      streams={screenSharedStreamValue}
                      sourceId={screenSharedSourceIDValue}
                      muteAllStream={muteAllStream}
                    />
                  )}
                  {Array.from(remoteTrackSources)
                    .filter(([sourceId, { mediaStream }]) => {
                      return !(sourceId == screenSharedSourceIDValue);
                    })
                    .map(([sourceId, { mediaStream }]) => {
                      // let muted = calculateIsMutedOrNot(sourceId);
                      return (
                        <ParticipentForAttendeeScreen
                          name={sourceId}
                          streams={mediaStream}
                          sourceId={sourceId}
                          screenSharesourceId={screenSharedSourceIDValue}
                          mirror={false}
                          videoTrack={true}
                          remoteTrackSources={remoteTrackSources}
                          muteAllStream={muteAllStream}
                          // muted={muted}
                        />
                      );
                    })}
                </div>
              </div>
            </div>
          ) : (
            <div className="ew-video-container attendee-layout overflow-hidden w-full h-full">
              <div
                className={
                  "presenters active-presenter-" + remoteTrackSources.size
                }
              >
                {" "}
                {/* add class according to presenters added in the screen */}
                <div className="ew-n-presenters">
                  {unMutePopup && (
                    <div
                      className="click-for-sound"
                      onClick={onScreenShareDeniedClose}
                    >
                      <span>
                        <img src={offClickSoundIcon} className="off" alt="" />
                        <img src={onClickSoundIcon} className="on" alt="" />
                        Click for sound
                      </span>
                    </div>
                  )}

                  {Array.from(remoteTrackSources).map(
                    ([sourceId, { mediaStream }]) => {
                      // let muted = calculateIsMutedOrNot(sourceId);
                      return (
                        <ParticipentForAttendeeScreen
                          name={sourceId}
                          streams={mediaStream}
                          sourceId={sourceId}
                          screenSharesourceId={screenSharedSourceIDValue}
                          mirror={false}
                          // camera={true}
                          videoTrack={true}
                          remoteTrackSources={remoteTrackSources}
                          // muted={muted}
                          muteAllStream={muteAllStream}
                        />
                      );
                    }
                  )}
                </div>
              </div>
            </div>
          )}

          {/* <div
            className="refresh-screen"
            onClick={() => window.location.reload()}
          >
            <img src={refreshIcon} alt="refresh" data-tooltip-place="top" />
            <Tooltip anchorSelect=".refresh-screen">Refresh</Tooltip>
          </div> */}
        </div>

        <PubNubProvider client={pubnub}>
          <div className="right-layout">
            <SidebarLayout
              sideClick={sideClickHandler}
              onLeaveClick={leaveroom}
              messageHandle={messageEventHandler}
              onOpenConfirmationModelFunc={onOpenConfirmationModel}
            />
            <div className="sidebar-info-box">
              {index == 0 && (
                // <ChatModule />
                <ChatComponent ref={childRef} />
              )}
              {index == 1 && (
                <PeopleList
                  onConfirmationCloseAction={onConfirmationCloseAction}
                  setTitle={setTitle}
                  setMessage={setMessage}
                  onConfirmationOpenAction={onConfirmationOpenAction}
                  setDropdownOpenAttendee={setDropdownOpenAttendee}
                  setSubmenuOpenAttendee={setSubmenuOpenAttendee}
                  setDropdownOpen={setDropdownOpen}
                  setSubmenuOpen={setSubmenuOpen}
                  dropdownOpen={dropdownOpen}
                  dropdownOpenAttendee={dropdownOpenAttendee}
                  handleClickForConfirmationPopup={
                    handleClickForConfirmationPopup
                  }
                />
              )}
              {index == 2 && <Offers />}
              {index == 3 && stateData.publishStreamObj.isAttendee !== true && (
                <Polls
                  createPollHandler={createPoll}
                  updatePollHandler={updatePollHandlerGreen}
                />
              )}
              {index == 3 && stateData.publishStreamObj.isAttendee === true && (
                <PollsAttendee
                  attendeeData={attendeeData}
                  createPollHandler={createPoll}
                  updatePollHandler={updatePollHandlerGreen}
                />
              )}
            </div>
          </div>
        </PubNubProvider>

        {isConfirmationOpen && (
          <ConfirmationPopupAttendee
            isConfirmationOpen={isConfirmationOpen}
            onCloseConfirmationPopUp={onCloseConfirmationPopUp}
            SessionHandle={leaveroom}
          />
        )}
      </div>
      {/* {unMutePopup && (
        <ScreenShareDeniedPopup
          onScreenShareDeniedClose={onScreenShareDeniedClose}
          isScreenShareDeniedOpen={unMutePopup}
          topic={"Unmute all streams"}
          header={"Unmute all streams"}
          content={"Unmute all streams."}
        />
      )} */}
    </>
  );
};
