import { b, i } from "vite/dist/node/types.d-jgA8ss1A";
import {
  UPDATE_IS_TIMER,
  UPDATE_IS_LIVE,
  UPDATE_IS_LIVEGO,
  UPDATE_IS_SCREEN,
  SPEAKER_SELECTED_LABEL,
  SPEAKER_SELECTED_DEVICE_ID,
  ADD_CONNECTION,
  ADD_USERNAME,
  AUDIO_TOGGLE,
  MIRROR_CAMERA_TOGGLE,
  UPDATEVIDEOQUALITY,
  UPDATE_SELECTED_DEVICE_ID,
  UPDATE_SELECTED_LABEL,
  VIDEO_TOGGLE,
  CAMERA_ID,
  CAMERA_LABEL,
  ADD_NEW_PEOPLE,
  ADD_PEOPLES,
  ADD_NUMBER_OF_PEOPLES,
  REMOVE_PEOPLE,
  PARTIPANT_MIC_STATUS,
  UPDATE_IS_INVITEPOPUP,
  UPDATE_NOISE_REDUCTION,
  ATTENDEE_LIST,
  PUBLIC_CHAT,
  CHAT_PANEL,
  PRIVATE_CHAT,
  UPDATE_CAMERA,
  PARTIPANT_VIDEO_STATUS,
  UPDATE_SCREEN_OTHER,
  UPDATE_ACTIVE_POLL_STATUS,
  SIDE_BAR,
  UPDATE_ATTENDEE_DATA_FOR_ALL,
  MUTE_TOGGLE,
  CAMERA_TOGGLE,
  WHO_IS_SHARING_SCREEN,
  SCREENSHARESOURCEID,
} from "../ActionTypes/PublishStreamActionType";

export interface PublishStreamObj {
  obj: any | null;

  // User details
  userName: string;
  userAccountId: string;
  userEmail: string;
  userUUID: string;

  // Recording
  recording: boolean;

  // Device details
  deviceId?: string;
  label?: string;
  speakerdeviceId?: string;
  speakerlabel?: string;
  cameraId?: string;
  cameraLabel?: string;
  speakerVolume: number;
  mirroCamera: boolean;
  muted: boolean;
  camera: boolean;
  width: number;
  hieght: number;
  bitrate: number;
  selectedQuality: number;

  // Background
  backgroundNone: boolean;
  backgroundBlur: boolean;
  backgroundImage: boolean;
  backgroundImageName: string;

  // Stream details
  token: string;
  subscribeToken: string;
  streamName: string;
  accountId: string;

  // Webinar details
  sid: number;
  did: number;
  webinarid: number;
  sessionId: string;

  // Webinar links
  attendeeLink: string;
  moderatorLink: string;
  presentorLink: string;
  isLive: boolean;
  isTimer: boolean;
  isLiveGo: boolean;
  isScreen: boolean;
  isScreenByOtherMember: boolean;
  screenShareSourceID: string;
  isInvitePopUp: boolean;

  // User type
  isAdmin: boolean;
  isModerator: boolean;
  isAttendee: boolean;
  isPresenter: boolean;

  isAttendeeList: boolean;
  isPublicChat: boolean;
  isPrivateChat: boolean;
  isChatPanel: boolean;
  event_name: string;
  timeStamp: any;
  sideBarActive: boolean;
  // Stream details
  numberOfAttendees: number;
  peoples: Array<{ uuid: string; state: { mic: boolean } }>;

  // PubNub
  channels: string[];

  // Noise reduction
  isNoiseReductionEnabled: boolean;

  isNewPollPublished: boolean;
  attendeeData: string;
  userKey: string;
  screenSharingUUId: string;
}
export interface PublishStreamState {
  publishStreamObj: PublishStreamObj;
}

// Root State Interface
export interface RootState {
  PublishStreamReducer: PublishStreamState;
}

const initialState: PublishStreamState = {
  publishStreamObj: {
    obj: null,

    // user details
    userName: "",
    userAccountId: "",
    userEmail: "",
    userUUID: "",

    // recording
    recording: false,

    // Device details
    deviceId: undefined,
    label: undefined,
    speakerdeviceId: undefined,
    speakerlabel: undefined,
    cameraId: undefined,
    cameraLabel: undefined,
    speakerVolume: 1,
    mirroCamera: false,
    muted: false,
    camera: true,
    width: 1280,
    hieght: 720,
    bitrate: 1500,
    selectedQuality: 1,

    // background
    backgroundNone: true,
    backgroundBlur: false,
    backgroundImage: false,
    backgroundImageName: "",

    // stream details
    token: "",
    subscribeToken: "",
    streamName: "",
    accountId: "uuMGY4",
    event_name: "",

    //webinar details
    sid: 0,
    did: 0,
    webinarid: 0,
    sessionId: "",

    //webinar links
    attendeeLink: "",
    moderatorLink: "",
    presentorLink: "",
    isLive: false, // Add this line
    isTimer: false, // Add this line
    isLiveGo: false,
    isScreen: false,
    isScreenByOtherMember: false,
    screenShareSourceID: "",
    isInvitePopUp: false,
    sideBarActive: false,

    //userType
    isAdmin: false,
    isModerator: false,
    isAttendee: false,
    isPresenter: false,

    isAttendeeList: true,
    isPublicChat: true,
    isPrivateChat: true,
    isChatPanel: true,
    timeStamp: "",

    //stream details
    numberOfAttendees: 0,
    peoples: [],

    //pubnub
    channels: [],
    isNoiseReductionEnabled: false,

    isNewPollPublished: false,
    attendeeData: "",
    userKey: "",
    screenSharingUUId: "",
  },
};

export const PublishStreamReducer = (state = initialState, action: any) => {
  console.log("Redux call ", action);
  switch (action.type) {
    case ADD_CONNECTION:
      return {
        ...state,
        publishStreamObj: action.payload,
      };
    case VIDEO_TOGGLE:
      return {
        ...state,
        publishStreamObj: action.payload,
      };
    case AUDIO_TOGGLE:
      return {
        ...state,
        publishStreamObj: action.payload,
      };
    case ADD_USERNAME:
      return {
        ...state,
        publishStreamObj: action.payload,
      };
    case MIRROR_CAMERA_TOGGLE:
      return {
        ...state,
        publishStreamObj: action.payload,
      };
    case UPDATEVIDEOQUALITY:
      return {
        ...state,
        publishStreamObj: action.payload,
      };

    case UPDATE_SELECTED_DEVICE_ID:
      return {
        ...state,
        publishStreamObj: action.payload,
      };

    case UPDATE_SELECTED_LABEL:
      return {
        ...state,
        publishStreamObj: action.payload,
      };

    case SPEAKER_SELECTED_DEVICE_ID:
      return {
        ...state,
        publishStreamObj: action.payload,
      };

    case SPEAKER_SELECTED_LABEL:
      return {
        ...state,
        publishStreamObj: action.payload,
      };

    case CAMERA_ID:
      return {
        ...state,
        publishStreamObj: action.payload,
      };

    case CAMERA_LABEL:
      return {
        ...state,
        publishStreamObj: action.payload,
      };

    case UPDATE_IS_LIVE: // Add this case
      return {
        ...state,
        publishStreamObj: {
          ...state.publishStreamObj,
          isLive: action.payload,
        },
      };
    case UPDATE_IS_SCREEN: // Add this case
      return {
        ...state,
        publishStreamObj: {
          ...state.publishStreamObj,
          isScreen: action.payload,
        },
      };
    case UPDATE_SCREEN_OTHER: // Add this case
      console.log("called");
      return {
        ...state,
        publishStreamObj: {
          ...state.publishStreamObj,
          isScreenByOtherMember: action.payload,
        },
      };
    case UPDATE_IS_TIMER: // Add this case
      return {
        ...state,
        publishStreamObj: {
          ...state.publishStreamObj,
          isTimer: action.payload,
        },
      };
    case UPDATE_IS_LIVEGO: // Add this case
      return {
        ...state,
        publishStreamObj: {
          ...state.publishStreamObj,
          isLiveGo: action.payload,
        },
      };
    case UPDATE_IS_INVITEPOPUP: // Add this case
      return {
        ...state,
        publishStreamObj: {
          ...state.publishStreamObj,
          isInvitePopUp: action.payload,
        },
      };
    case ADD_PEOPLES: // Add this case
      return {
        ...state,
        publishStreamObj: {
          ...state.publishStreamObj,
          peoples: [...state.publishStreamObj.peoples, ...action.payload],
        },
      };
    case ADD_NEW_PEOPLE: // Add this case
      return {
        ...state,
        publishStreamObj: {
          ...state.publishStreamObj,
          peoples: [...state.publishStreamObj.peoples, action.payload],
        },
      };
    case ADD_NUMBER_OF_PEOPLES: {
      // Add this case
      const people = state.publishStreamObj.peoples.filter(
        (o) => o.uuid == action.payload.uuid
      );
      if (people.length > 0) {
        return state;
      } else
        return {
          ...state,
          publishStreamObj: {
            ...state.publishStreamObj,
            numberOfAttendees: action.payload,
          },
        };
    }
    case REMOVE_PEOPLE: // Add this case
      return {
        ...state,
        publishStreamObj: {
          ...state.publishStreamObj,
          peoples: state.publishStreamObj.peoples.filter(
            (o) => o.uuid !== action.payload
          ),
        },
      };
    case PARTIPANT_MIC_STATUS: // Add this case
      return {
        ...state,
        publishStreamObj: {
          ...state.publishStreamObj,
          peoples: state.publishStreamObj.peoples.map((o) => {
            if (o.uuid === action.payload.uuid) {
              return {
                ...o,
                state: {
                  ...o.state,
                  mic: action.payload.mic,
                },
              };
            }
            return o;
          }),
        },
      };

    case PARTIPANT_VIDEO_STATUS: // Add this case
      return {
        ...state,
        publishStreamObj: {
          ...state.publishStreamObj,
          peoples: state.publishStreamObj.peoples.map((o) => {
            if (o.uuid === action.payload.uuid) {
              return {
                ...o,
                state: {
                  ...o.state,
                  camera: action.payload.camera,
                },
              };
            }
            return o;
          }),
        },
      };
    // publishStreamObj:{
    //   ...state.publishStreamObj,
    //   isNoiseReductionEnabled:action.payload
    //   }
    // }

    //  chat moderation
    case ATTENDEE_LIST:
      return {
        ...state,
        publishStreamObj: {
          ...state.publishStreamObj,
          isAttendeeList: action.payload,
        },
      };

    case PUBLIC_CHAT:
      return {
        ...state,
        publishStreamObj: {
          ...state.publishStreamObj,
          isPublicChat: action.payload,
        },
      };
    case PRIVATE_CHAT:
      return {
        ...state,
        publishStreamObj: {
          ...state.publishStreamObj,
          isPrivateChat: action.payload,
        },
      };
    case CHAT_PANEL:
      return {
        ...state,
        publishStreamObj: {
          ...state.publishStreamObj,
          isChatPanel: action.payload,
        },
      };
    case SIDE_BAR:
      return {
        ...state,
        publishStreamObj: {
          ...state.publishStreamObj,
          sideBarActive: action.payload,
        },
      };
    case UPDATE_NOISE_REDUCTION:
      return {
        ...state,
        publishStreamObj: {
          ...state.publishStreamObj,
          isNoiseReductionEnabled: action.payload,
        },
      };
    case UPDATE_CAMERA:
      return {
        ...state,
        publishStreamObj: {
          ...state.publishStreamObj,
          cameraId: action.payload.cameraId,
          cameraLabel: action.payload.cameraName,
        },
      };
    case UPDATE_ACTIVE_POLL_STATUS:
      return {
        ...state,
        publishStreamObj: {
          ...state.publishStreamObj,
          isNewPollPublished: action.payload,
        },
      };

    case UPDATE_ATTENDEE_DATA_FOR_ALL:
      console.log("called", action.payload);
      return {
        ...state,
        publishStreamObj: {
          ...state.publishStreamObj,
          attendeeData: action.payload.toString(),
        },
      };

    case MUTE_TOGGLE:
      console.log("called", action.payload);
      return {
        ...state,
        publishStreamObj: {
          ...state.publishStreamObj,
          muted: action.payload,
        },
      };

    case CAMERA_TOGGLE:
      console.log("called", action.payload);
      return {
        ...state,
        publishStreamObj: {
          ...state.publishStreamObj,
          camera: action.payload,
        },
      };

    case WHO_IS_SHARING_SCREEN: // Add this case
      return {
        ...state,
        publishStreamObj: {
          ...state.publishStreamObj,
          screenSharingUUId: action.payload,
        },
      };

    case SCREENSHARESOURCEID:
      return {
        ...state,
        publishStreamObj: {
          ...state.publishStreamObj,
          screenShareSourceID: action.payload,
        },
      };

    default:
      return state;
  }
};
