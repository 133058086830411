import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { setAuthToken } from "../../libs/APIs/auth";
import { postRequest } from "../../libs/APIs/apiCall";
import { Attendee } from "../Attendee/Attendee";
import Loader from "../Loader/Loader";

export const Moderator = () => {
  //redux
  const stateData = useSelector((state: any) => state.PublishStreamReducer);
  const dispatch = useDispatch();

  const [params, setParams] = useSearchParams();
  const [mname, setmname] = useState(params.get("mname"));
  const [session_id, setSessionId] = useState(params.get("sid"));
  const [key, setkey] = useState(params.get("key"));
  const [loader, setLoader] = useState(true);

  //Routes parameter read
  const [userName, setUserName] = useState<any>("");
  const [streamName, setstreamName] = useState<any>("");
  const [streamAccountId, setStreamAccountId] = useState<any>(
    stateData?.publishStreamObj?.accountId
  );
  const [subscriberToken, setsubscriberToken] = useState("");
  //end

  // Backend API call start
  useEffect(() => {
    (async () => {
      const ew_jwt_token = localStorage.getItem("ew_jwt_token");
      setAuthToken(ew_jwt_token);
      const res = await postRequest(
        "https://test-v2.easywebinar.com/v1/easywebinar/v1/moderator/join/?key=" +
          key +
          "&mname=" +
          mname +
          "&sid=" +
          session_id
      );
      setUserName(res.data.name);
      setstreamName(res.data.stream[0].streamName);
      setsubscriberToken(res.data.subscription_token);
      // console.log("res.data moderator :::", res.data);
      setLoader(false);

      dispatch({
        type: "ADD_USERNAME",
        payload: {
          ...stateData?.publishStreamObj,
          userName: res.data.name,
          userEmail: res?.data?.email,
          subscribeToken: res.data.subscription_token,
          streamName: res.data.stream[0].streamName,
          webinarid: res?.data?.webinar_id,
          sessionId: res?.data?.session_id,
          userUUID: res?.data?.pn_user_id,
          isModerator: true,
          event_name: res.data?.webinar_details?.event_name,
          userKey:res.data.user_key,
        },
      });
    })();
  }, []);
  // Backend API call end

  return (
    <>
      {loader && <Loader />}
      {!loader && (
        <Attendee
          streamAccountId={streamAccountId}
          streamName={streamName}
          subscriberToken={subscriberToken}
          userName
        />
      )}
    </>
  );
};
