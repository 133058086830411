import { useEffect, useRef, useState } from "react";
import React from "react";
import "../../assets/styles/main.scss";
import AudioOutputSelector from "../SelectDeviceComponents/AudioOutputSelector";
import AudioInputSelector from "../SelectDeviceComponents/AudioInputSelector";
import VideoInputSelector from "../SelectDeviceComponents/VideoInputSelector";
// import BackgroundSelect from "../BackgroundImage/BackgroundSelect";
import StreamingQualitySelect from "../SelectDeviceComponents/StreamingQualitySelect";
import speakerIcon from "../../assets/images/speaker-icon.png";
import PublishStream from "../../libs/DolbyStreaming/PublishStream";
import classNames from "classnames";

//redux
import { useSelector, useDispatch } from "react-redux";
import { VideoView } from "./VideoView";
import { usePubNub } from "pubnub-react";
import { participantMicStatus, participantVideoStatus, updateIsLiveGo } from "../../Store/Actions/PublishStreamAction";
import { getChatChannelName } from "../../libs/signal/signalEnum";
import { ScreenShareDeniedPopup } from "../PopUp/ScreenShareDeniedPopup/ScreenShareDeniedPopup";
import { postRequest } from "../../libs/APIs/apiCall";
import playAudioIcon from "../../assets/images/play-audio-icon.svg";
import stopAudioIcon from "../../assets/images/stop-audio-icon.svg";
import { awsTestAudioUrl } from "../../config";

interface props {
  videoRef?: any;
  canvasRef?: any;
  mediaStreamRef?: any;
  volume: number;
  userName: string;
  streamName: string;
  token: string;

  videoBackGroundNone: () => void;
  videoBackGroundBlur: () => void;
  videoBackGroundImage: (img: any, imgName: string) => void;
  handleVolumeChange: (value: number) => void;
  handleGotoGreenRoom: () => void;
  CanvasDisplayComponenet: any;
  startStream(): Promise<void>;
  pauseStream: () => void;
}

export const PresentorSetup = ({
  videoRef,
  mediaStreamRef,
  canvasRef,
  videoBackGroundNone,
  videoBackGroundBlur,
  videoBackGroundImage,
  handleVolumeChange,
  volume,
  userName,
  streamName,
  token,
  handleGotoGreenRoom,
  CanvasDisplayComponenet,
  startStream,
  pauseStream,
}: props) => {
  //redux start
  let stateData = useSelector((state: any) => state.PublishStreamReducer);
  const dispatch = useDispatch();
  //redux end

  //Device handle change start
  const handleVolumeChangeValue = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newVolume = parseFloat(event.target.value) / 100;
    handleVolumeChange(newVolume);
  };
  //Device handle change end

  //video on/off
  const [isCameraOff, setCameraOff] = React.useState(false);
  const videoToggle = async () => {
    setCameraOff(!isCameraOff);
    if (stateData?.publishStreamObj?.camera) {
      pauseStream();
    } else {
      await startStream();
    }
    dispatch({
      type: "VIDEO_TOGGLE",
      payload: {
        ...stateData?.publishStreamObj,
        camera: !stateData?.publishStreamObj?.camera,
      },
    });

    dispatch(
      participantVideoStatus({
        Camera: !stateData?.publishStreamObj?.camera,
        uuid: stateData.publishStreamObj.userUUID,
      })
    )
  };

  //Mic on/off
  const muteToggleRef = () => {
    dispatch({
      type: "AUDIO_TOGGLE",
      payload: {
        ...stateData?.publishStreamObj,
        muted: !stateData?.publishStreamObj?.muted,
      },
    });


    dispatch(
      participantMicStatus({
        mic: !stateData?.publishStreamObj?.mic,
        uuid: stateData.publishStreamObj.userUUID,
      })
    )
  };
  //end
  async function cohostRestriction() {
    if (stateData?.publishStreamObj?.isAdmin) {
      return true;
    } else {
      const ChatchannelName = getChatChannelName(
        stateData.publishStreamObj.webinarid,
        stateData.publishStreamObj.sessionId
      );
      const presenceResult = await pubnubobj.hereNow({
        channels: [ChatchannelName],
        includeUUIDs: true,
        includeState: true,
      });

      let numberOfPresentor = 0;
      if (presenceResult.channels[ChatchannelName].occupants.length > 0) {
        presenceResult.channels[ChatchannelName].occupants.forEach((ele) => {
          if (ele?.state?.isPresentor) {
            numberOfPresentor++;
          }
        });
        // console.log(
        //   "SideLayout UserLists :: ",
        //   presenceResult.channels[ChatchannelName].occupants.length,
        //   new Date(),
        //   numberOfPresentor
        // );
        if (numberOfPresentor >= 3) return false;
        else return true;
      } else {
        return false;
      }
    }
  }
  //green room  start

  //check cohost retriction

  const pubnubobj = usePubNub();
  const cancelGreenRoom = async () => {
    try {
      const res = await postRequest(
        "https://test-v2.easywebinar.com/v1/easywebinar/v1/admin/exit-green-room",
        {
          sid: stateData?.publishStreamObj?.sid,
          webinar_id: stateData?.publishStreamObj.webinarid,
        }
      );

      window.history.back();
      // console.log(res);
    } catch (error) {
      console.log("error");
    }
  };
  const goToGreenRoom = async () => {
    const flag = await cohostRestriction();

    if (flag == false) {
      setisScreenShareDeniedOpen(true);
    } else {
      try {
        const publishStreamObj = new PublishStream();
        await publishStreamObj.initialize(
          userName,
          streamName,
          token,
          stateData?.publishStreamObj?.cameraId,
          stateData?.publishStreamObj?.deviceId,
          stateData?.publishStreamObj?.speakerdeviceId,
          stateData?.publishStreamObj?.backgroundNone,
          mediaStreamRef,
          stateData?.publishStreamObj?.recording,
          stateData?.publishStreamObj?.isAdmin
        );
        await publishStreamObj.streamStart(
          stateData?.publishStreamObj?.bitrate,
          stateData?.publishStreamObj?.camera,
          !stateData?.publishStreamObj?.muted,
          stateData?.publishStreamObj?.isAdmin
        );
        dispatch({
          type: "ADD_CONNECTION",
          payload: {
            ...stateData.publishStreamObj,
            obj: publishStreamObj,
          },
        });

        //update pubnub channel state start
        if (isLive == false) {
          const ChatchannelName =
            "Webinar." +
            stateData.publishStreamObj.webinarid +
            "." +
            stateData.publishStreamObj.sessionId;
          pubnubobj.objects.setChannelMetadata({
            channel: ChatchannelName,
            data: {
              name: "Webinar",
              description: "Webinar Status",
              custom: {
                webinarId: stateData.publishStreamObj.webinarid,
                sessionId: stateData.publishStreamObj.sessionId,
                satus: "greenroom",
              },
            },
          });
        }
        //end
        handleGotoGreenRoom();
      } catch (err) {
        // console.log("Error ::: ", err);
      }
    }
  };
  //green room end

  //Streaming Qualaity
  const qualityHandleChang = async (
    width: number,
    height: number,
    bitrate: number,
    qualityType: number
  ) => {
    // setbitrate(bitrate);
    // setwidth(width);
    // setheight(height);
    dispatch({
      type: "UPDATEVIDEOQUALITY",
      payload: {
        ...stateData.publishStreamObj,
        width: width,
        hieght: height,
        bitrate: bitrate,
        selectedQuality: qualityType,
      },
    });
  };
  //end
  // for camera mic first time allowed
  useEffect(() => {
    let stream;
    const requestMediaAccess = async () => {
      try {
        // Request access to the camera and microphone
        stream = await navigator.mediaDevices.getUserMedia({
          video: {width:{ideal: 4096}, height:{ideal: 2160}},
          audio: true,
        });
        // console.log("Camera and microphone access granted");
        // Check if media access has been granted before
        if (!localStorage.getItem("mediaAccessGranted")) {
          // Store a flag in localStorage to indicate that media access has been granted
          localStorage.setItem("mediaAccessGranted", "true");

          // Refresh the page to handle any initial state or settings
          window.location.reload();
        }
      } catch (error) {
        // console.error("Error accessing camera or microphone:", error);
      }
    };
    requestMediaAccess();
    return () => {
      stream?.getTracks().forEach((track) => (track.enabled = false));
    };
  }, []);

  // For mobile scss
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    // Function to detect if the device is mobile
    const checkIfMobile = () => {
      const userAgent = navigator.userAgent || navigator.vendor;
      if (/android/i.test(userAgent)) {
        setIsMobile(true);
      } else if (
        /iPad|iPhone|iPod/.test(userAgent) &&
        !(window as any).MSStream
      ) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    // Initial check
    checkIfMobile();
  }, []);

  //Webinar status start
  const [isLive, setLive] = useState(false);
  const WebinachannelName =
    "Webinar." +
    stateData.publishStreamObj.webinarid +
    "." +
    stateData.publishStreamObj.sessionId;
  useEffect(() => {
    pubnubobj.objects
      .getChannelMetadata({
        channel: WebinachannelName,
      })
      .then((response) => {
        // console.log("Webinar status:", response);
        if (response.data.custom?.satus == "liveroom") {
          dispatch(updateIsLiveGo(true));
          setLive(true);
        }
      })
      .catch((error) => {
        // console.error("Error getting metadata:", error);
      });
  }, []);
  //end

  //Restriction popup start
  const [isScreenShareDeniedOpen, setisScreenShareDeniedOpen] = useState(false);
  function onScreenShareDeniedClose() {
    setisScreenShareDeniedOpen(false);
  }
  //end

  //Speaker Test Start
  const [isPlaying, setIsPlaying] = useState(false);
  const [speakerTestAudio, setAudio] = useState(new Audio(awsTestAudioUrl));
  useEffect(() => {
    if (isPlaying) {
      speakerTestAudio.play();
    } else {
      speakerTestAudio.pause();
    }
    speakerTestAudio.volume = volume;

    speakerTestAudio.addEventListener("ended", () => {
      setIsPlaying(false);
    });

    return () => {
      speakerTestAudio.removeEventListener("ended", () => {
        setIsPlaying(false);
      });
    };
  }, [isPlaying,volume, speakerTestAudio]);

  const handlePlayClick = () => {
    setIsPlaying(true);
  };

  const handleStopClick = () => {
    setIsPlaying(false);
  };

  //Speaker Test End

  return (
    <>
      <div className={classNames("", { "": true, mobile: isMobile })}>
        <div className="modal setting-popup">
          <div className="dummmy-live-bg"></div>
          <div className="modal-background"></div>
          <div className="outer-setting-con">
            <h3 className="settings-popup-heading">
              Test your Audio, Video and Device Compatibility
            </h3>
            <div className="modal-container">
              <div className="live-room-settings-box">
                <div className="camera-settings">
                  <h4>Camera</h4>
                  <VideoView
                    muted={stateData?.publishStreamObj?.muted}
                    videoRefer={videoRef}
                    canvasRef={canvasRef}
                    videoToggleRef={videoToggle}
                    muteToggleRef={muteToggleRef}
                    userName={stateData?.publishStreamObj?.userName}
                    CanvasDisplayComponenet={CanvasDisplayComponenet}
                  />
                  {/* <BackgroundSelect
                  videoBackGroundBlur={videoBackGroundBlur}
                  videoBackGroundNone={videoBackGroundNone}
                  videoBackGroundImage={videoBackGroundImage}
                /> */}
                </div>
                <div className="ew-other-video-settings">
                  <VideoInputSelector isSetupPopup={true} />
                  <AudioInputSelector />
                  <div className="ew-form-field">
                    <label htmlFor="dropdown">Speakers</label>
                    <span className="label-sub-heading test-output">
                      Select your preferred device for Speakers
                      <span
                        className="flex flex-center test-audio"
                        onClick={isPlaying ? handleStopClick : handlePlayClick}
                      >
                        {isPlaying ? (
                          <>
                            <img src={stopAudioIcon} alt="pause icon" />
                            <span>Stop</span>
                          </>
                        ) : (
                          <>
                            <img src={playAudioIcon} alt="play icon" />
                            <span>Test Output</span>
                          </>
                        )}
                      </span>
                    </span>
                    <div className="mic-volume">
                      <span className="icon">
                        <img src={speakerIcon} alt="" />
                      </span>
                      <input
                        type="range"
                        min="0"
                        max="100"
                        value={volume * 100}
                        onChange={handleVolumeChangeValue}
                        className="custom-range"
                        style={{ width: "100%", cursor: "ew-resize" }}
                      />
                    </div>
                    <AudioOutputSelector />
                  </div>
                  {/* <StreamingQualitySelect
                    qualityHandleChange={qualityHandleChang}
                    selectedQuality={
                      stateData?.publishStreamObj?.selectedQuality
                    }
                  /> */}
                  <div className="ew-form-field-action">
                    <button
                      type="button"
                      className="default-btn"
                      // onClick={() => window.history.back()}
                      onClick={cancelGreenRoom}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      onClick={goToGreenRoom}
                      className="primary-button"
                    >
                      {isLive ? "Enter Live room" : "Enter Greenroom"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isScreenShareDeniedOpen && (
        <ScreenShareDeniedPopup
          onScreenShareDeniedClose={onScreenShareDeniedClose}
          isScreenShareDeniedOpen={isScreenShareDeniedOpen}
          topic={"Presentor join Denied"}
          header={"Three presenter at a time"}
          content={
            "The host isn't allowing more then three presenter at a time."
          }
        />
      )}
    </>
  );
};
