import { Director, Publish } from "@millicast/sdk";
import { useRef } from "react";
import store from "../../Store/Store";
import { updateIsScreen } from "../../Store/Actions/PublishStreamAction";
import WebRTCStats, { OnStats } from "@dolbyio/webrtc-stats";

// This class will handle all stream publish related opeartion like -
// publish strea, stop stream, update stream etc
export default class PublishStream {
  mediaStreamm: any;
  broadCastOptions: any;
  userName: any;
  streamName: any;
  token: any;
  publisher: any;
  publisherScreenShare: any;
  mediaStreamScreenShare?: MediaStream;
  constructor() {}

  // initilize millicast publish object
  async initialize(
    name: string,
    streamName: string,
    token: string,
    cameraId: string,
    deviceId: string,
    speakerdeviceId: string,
    backgroundNone: boolean,
    mediaStreamRef: any,
    recording: boolean,
    isAdmin: boolean
  ) {
    this.userName = name;
    this.streamName = streamName;
    this.token = token;

    if (backgroundNone) {
      const audioConstraints = {
        deviceId: {
          exact: deviceId,
          sinkId: speakerdeviceId,
        },
        echoCancellation: true,
      };
      const mediaStream = await navigator.mediaDevices.getUserMedia({
        audio: audioConstraints,
        video: { deviceId: cameraId, width:{ideal: 4096}, height:{ideal: 2160} },
      });
      this.mediaStreamm = mediaStream;
    } else {
      this.mediaStreamm = mediaStreamRef;
    }

    if (isAdmin) {
      this.broadCastOptions = {
        mediaStream: this.mediaStreamm,
        record: recording,
        simulcast: true,
        codec: "vp8",
        bandwidth: 0,
        sourceId: this.userName,
      };
    } else {
      this.broadCastOptions = {
        mediaStream: this.mediaStreamm,
        sourceId: this.userName,
        record: recording,
        simulcast: true,
        codec: "vp8",
        bandwidth: 0,
      };
    }

    const tokenGenerator = () =>
      Director.getPublisher({
        token: this.token,
        streamName: this.streamName,
      });
    this.publisher = new Publish(this.streamName, tokenGenerator, true);
  }

  // Start dolby millicast stream
  async streamStart(
    bitrate: number,
    video: boolean,
    audio: boolean,
    isAdmin: boolean
  ) {
    // console.log(this.streamName, this.token, "video", video);
    try {
      await this.publisher.connect(this.broadCastOptions);

      const collection = new WebRTCStats({
        getStatsInterval: 5000,
        getStats: () => this.publisher.webRTCPeer?.getRTCPeer().getStats(),
      });  
      collection.on('stats', (event: OnStats) => {
        console.log('WebRTCStats-pub',event, this.mediaStreamm, this.mediaStreamm.getTracks())
        // handleStats(streamId, event);
      });
      collection.on('error', (reason: string) => {
        console.error('WebRTCStats-pub-error',reason);
      });
      collection.start();

      // if (video) {
      //   // if (isAdmin == false) {
      //   await this.publisher.webRTCPeer?.updateBitrate(bitrate);
      //   // }
      //   // else {
      //   //   this.publisher.webRTCPeer?.updateBitrate(bitrate);
      //   // }
      // }
      if (video == false) {
        await this.streamCameraUpdate(false);
      }
      if (audio == false) {
        await this.streamMicUpdate(false);
      }
    } catch (err) {
      console.log("Error in stream start", err);
      throw err;
    }
  }

  // Stream camera on/off
  async streamCameraUpdate(startus: boolean) {
    const [videoTrack2] = this.mediaStreamm?.getVideoTracks() ?? [];
    videoTrack2.enabled = startus;
    await this.publisher.webRTCPeer?.replaceTrack(videoTrack2);
  }

  // Stream mic on/off
  async streamMicUpdate(startus: boolean) {
    const [audeoTrack2] = this.mediaStreamm?.getAudioTracks() ?? [];
    audeoTrack2.enabled = startus;
    await this.publisher.webRTCPeer?.replaceTrack(audeoTrack2);
  }

  // Stream bitrate update
  async streamBitrateUpdate(bitrate: number, width: number, height: number) {
    try {
      // await this.publisher.webRTCPeer?.updateBitrate(bitrate);
      // console.log("bit rate applied successfully");
    } catch (error) {
      console.log("error in  bitrate ");
    }

    // const [videoTrack] = this.mediaStreamm.getVideoTracks();
    // const applyVideoConstraints = videoTrack.applyConstraints(
    //     {
    //         width: width, height: height
    //     }
    // );
    // await applyVideoConstraints;
  }

  //Screen share start
  async startScreenShare(userName: string, bitrate: number) {
    try {
      // this.mediaStreamScreenShare =
      //   await navigator.mediaDevices.getDisplayMedia({
      //     video: true,
      //     audio: true,
      //   });

      // const broadCastOptions = {
      //   mediaStream: this.mediaStreamScreenShare,
      //   sourceId: userName + ":screenShare",
      //   simulcast: true,
      //   codec: "h264",
      //   // bandwidth: 6000,
      // };

      const constraints = {
        audio: true,
        video: { cursor: 'always' },
      } as MediaStreamConstraints;

      this.mediaStreamScreenShare = await navigator.mediaDevices.getDisplayMedia(constraints);

      const broadCastOptions = {
        mediaStream: this.mediaStreamScreenShare,
        sourceId: userName + ":screenShare",
        simulcast: true,
        codec: "vp8",
        bandwidth: 0,
      };

      const tokenGenerator = () =>
        Director.getPublisher({
          token: this.token,
          streamName: this.streamName,
        });
      this.publisherScreenShare = new Publish(
        this.streamName,
        tokenGenerator,
        true
      );
      await this.publisherScreenShare.connect(broadCastOptions);
      // await this.publisher.webRTCPeer?.updateBitrate(bitrate);

      this.mediaStreamScreenShare.getTracks().forEach((track) => {
        track.onended = async () => {
          // console.log("Screen sharing stopped by user");
          await this.stopSreenShare(); // Handle the stop event
          if (this.mediaStreamScreenShare) {
            this.mediaStreamScreenShare
              .getTracks()
              .forEach((track) => track.stop());
            // mediaStreamm = null; // Clear the reference
          }
        };
      });
    } catch (error) {
      if (error.name === "NotAllowedError") {
        console.error("Permission denied for screen sharing:", error);
        this.onScreenShareStopped();
        // Optionally show a user-friendly message or prompt
      } else {
        console.error("Error starting screen share:", error);
      }
    }
  }

  onScreenShareStopped() {
    // Dispatch an action to update the global state
    store.dispatch(updateIsScreen(false));
  }

  //stop screen share
  async stopSreenShare() {
    if (this.publisherScreenShare) {
      await this.publisherScreenShare.stop();
      this.publisherScreenShare = undefined;
      this.onScreenShareStopped();
      if (this.mediaStreamScreenShare) {
        this.mediaStreamScreenShare
          .getTracks()
          .forEach((track) => track.stop());
        // mediaStreamm = null; // Clear the reference
      }
    } else {
      if (this.mediaStreamScreenShare) {
        this.mediaStreamScreenShare
          .getTracks()
          .forEach((track) => track.stop());
        // mediaStreamm = null; // Clear the reference
      }
      this.onScreenShareStopped();
    }
  }

  //Leave green room
  async leaveGreenRoom() {
    await this.publisher.stop();
    const tracks = await this.mediaStreamm.getTracks();
    tracks.forEach((track) => {
      track.enabled = false;
      track.stop();
    });
  }

  //Get media stream
  getMediaStream() {
    return this.mediaStreamm;
  }

  //Update camera device id
  async updateStreamCameraId(
    cameraId: string,
    micId: string,
    speakerId: string,
    video: boolean
  ) {
    const audioConstraints = {
      deviceId: {
        exact: micId,
        sinkId: speakerId,
      },
      echoCancellation: true,
    };
    const mediaStream = await navigator.mediaDevices.getUserMedia({
      audio: audioConstraints,
      video: { deviceId: cameraId, width:{ideal: 4096}, height:{ideal: 2160} },
    });

    this.mediaStreamm = mediaStream;
    const [videoTrack] = this.mediaStreamm.getVideoTracks();
    if (video == false) {
      videoTrack.enabled = false;
    }
    await this.publisher.webRTCPeer?.replaceTrack(videoTrack);
  }

  //Update mic device id
  async updateStreamAudioId(
    cameraId: string,
    micId: string,
    speakerId: string,
    muted: boolean
  ) {
    const audioConstraints = {
      deviceId: {
        exact: micId,
        sinkId: speakerId,
      },
      echoCancellation: true,
    };
    const mediaStream = await navigator.mediaDevices.getUserMedia({
      audio: audioConstraints,
      video: { deviceId: cameraId, width:{ideal: 4096}, height:{ideal: 2160} },
    });

    this.mediaStreamm = mediaStream;
    const [audioTrack] = this.mediaStreamm.getAudioTracks();
    if (muted == true) {
      audioTrack.enabled = false;
    }
    await this.publisher.webRTCPeer?.replaceTrack(audioTrack);
  }

  //Update mic device id
  async noiseReductionUpdate(
    NoiseReduction: boolean,
    micId: string,
    speakerId: string,
    cameraId: string,
    muted: boolean
  ) {
    return;
    // if (this.mediaStreamm) {
    //   this.mediaStreamm.getTracks().forEach((track) => {
    //     track.stop(); // Stop each track (audio and video)
    //   });
    //   this.mediaStreamm = null; // Optionally, set the reference to null
    // }
    // const audioConstraints = {
    //   deviceId: {
    //     exact: micId,
    //     sinkId: speakerId,
    //   },
    //   echoCancellation: true,
    //   noiseSuppression: NoiseReduction,
    // };
    // const mediaStream = await navigator.mediaDevices.getUserMedia({
    //   audio: audioConstraints,
    //   video: { deviceId: cameraId },
    // });

    // this.mediaStreamm = mediaStream;
    // const [audioTrack] = this.mediaStreamm.getAudioTracks();
    // if (muted == true) {
    //   audioTrack.enabled = false;
    // }
    // await this.publisher.webRTCPeer?.replaceTrack(audioTrack);
  }

  // Start recording
  startRecording = async (): Promise<void> => {
    await this.publisher.record();
    return;
  };

  //stop recording
  stopRecording = async (): Promise<void> => {
    await this.publisher.unrecord();
    return;
  };
}
