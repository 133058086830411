import { useEffect, useRef, useState } from "react";
import React from "react";
import "../../assets/styles/main.scss";
import { useLocation, useSearchParams } from "react-router-dom";

//redux
import { useSelector, useDispatch } from "react-redux";
import { SelfieSegmentation } from "@mediapipe/selfie_segmentation";
import { PresentorSetup } from "../PresentorSetup/PresentorSetup";
import PublishStream from "../../libs/DolbyStreaming/PublishStream";
import { CanvasDisplay } from "./canvas";
import { setAuthToken } from "../../libs/APIs/auth";
import { postRequest } from "../../libs/APIs/apiCall";
import { subscribe } from "diagnostics_channel";
import { GreenRoom } from "../GreenRoom/green-room-details";
import { PubNubProvider } from "pubnub-react";
import PubNub from "pubnub";
import Loader from "../Loader/Loader";

interface Props {
  userName: string;
  streamName: string;
  token: string;
  subscription_token: string;
}

export const PresentorStream = ({
  userName,
  streamName,
  token,
  subscription_token,
}: Props) => {
  //Param start
  const [streamStatus, setStreamStatus] = useState("setup");
  const [loading, setLoading] = useState<boolean>(true);
  // const [loader,setLoader]=useState<boolean>(false)
  //Param end

  //redux
  const stateData = useSelector((state: any) => state.PublishStreamReducer);
  const dispatch = useDispatch();

  // const [params, setParams] = useSearchParams()
  // const [sid, setsid] = useState(params.get('sid'));
  // const [did, setdid] = useState(params.get('did'));
  // const [webinarid, setwebinarid] = useState(params.get('webinar_id'));
  // const [userName, setuserName] = useState("");
  // const [token, settoken] = useState("");
  // const [streamName, setstreamName] = useState("");
  // const [subscription_token, setsubscription_token] = useState("");
  //Media stream variable start

  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const contextRef = useRef<CanvasRenderingContext2D | null>(null);
  let mediaStreamRef = useRef<MediaStream | null>(null);
  let videomediaStreamRef = useRef<MediaStream | null>(null);
  let canvasId;

  const [paused, setpaused] = useState(false);
  const [volume, setVolume] = React.useState(1);
  //end

  //volume up and down
  const handleVolumeChange = (value: number) => {
    const newVolume = value;
    setVolume(newVolume);
    if (videoRef.current) {
      videoRef.current.volume = newVolume;
    }
  };

  //Device handle change start
  function attachSinkId(element: HTMLAudioElement, sinkId: string) {
    if ("sinkId" in element) {
      (element as any)
        .setSinkId(sinkId)
        .then(() => {
          // console.log(`Success, audio output device attached: ${sinkId}`);
        })
        .catch((error: Error) => {
          let errorMessage: string = error.message;
          if (error.name === "SecurityError") {
            errorMessage = `You need to use HTTPS for selecting audio output device: ${error.message}`;
          }
        });
    } else {
      console.warn("Browser does not support output device selection.");
      // Handle unsupported browser
    }
  }
  //Device handle change end

  //Background image start - Mediapipe
  const [backImage, setbackImage] = React.useState(null);
  let img,
    selfieSegmentation = new SelfieSegmentation({
      locateFile: (file: string) =>
        `https://cdn.jsdelivr.net/npm/@mediapipe/selfie_segmentation@0.1/${file}`,
    });
  const [backgroundState, setBackgroundState] = React.useState({
    none: true,
    blur: false,
    img: false,
    initialState: true,
  });
  const videoBackGroundBlur = async () => {
    setBackgroundState({
      none: false,
      blur: true,
      img: false,
      initialState: false,
    });
    dispatch({
      type: "VIDEO_TOGGLE",
      payload: {
        ...stateData?.publishStreamObj,
        backgroundNone: false,
        backgroundBlur: true,
        backgroundImage: false,
        backgroundImageName: "",
      },
    });
  };

  const videoBackGroundNone = () => {
    setBackgroundState({
      none: true,
      blur: false,
      img: false,
      initialState: false,
    });
    dispatch({
      type: "VIDEO_TOGGLE",
      payload: {
        ...stateData?.publishStreamObj,
        backgroundNone: true,
        backgroundBlur: false,
        backgroundImage: false,
        backgroundImageName: "",
      },
    });
    //canvasRef.current = null;
  };

  const videoBackGroundImage = (img: any, imgName: string) => {
    setbackImage(img);
    setBackgroundState({
      none: false,
      blur: false,
      img: true,
      initialState: false,
    });
    dispatch({
      type: "VIDEO_TOGGLE",
      payload: {
        ...stateData?.publishStreamObj,
        backgroundNone: false,
        backgroundBlur: false,
        backgroundImage: true,
        backgroundImageName: imgName,
      },
    });
  };

  async function applyBackgrounds() {
    try {
      //If no background selected
      if (backgroundState.none && backgroundState.initialState == true) return;

      //If no background selected

      //If no camera selected
      if (stateData?.publishStreamObj?.camera == false) {
        cancelAnimationFrame(canvasId);
        return;
      }

      if (!canvasRef.current || !videoRef.current) return;
      img = document.getElementById("background");
      contextRef.current = canvasRef.current.getContext("2d");
      const sendToMediaPipe = async () => {
        if (
          !videoRef.current ||
          !contextRef.current ||
          !videoRef.current.videoWidth
        ) {
          canvasId = requestAnimationFrame(sendToMediaPipe);
        } else {
          await selfieSegmentation.send({ image: videoRef.current });
          canvasId = requestAnimationFrame(sendToMediaPipe);
          //cancelAnimationFrame(canvasId);
        }
      };

      sendToMediaPipe();

      if (videoRef.current) {
        videoRef.current = videoRef.current;
        attachSinkId(
          videoRef.current,
          stateData?.publishStreamObj?.speakerdeviceId
        );
      }

      selfieSegmentation.setOptions({
        modelSelection: 1,
        selfieMode: false,
      });
      selfieSegmentation.onResults(onResults);
    } catch (error) {
      console.error("Error accessing media devices:", error);
    }
  }

  //Trigger once change backgrounds
  // useEffect(() => {
  //     applyBackgrounds();
  // }, [
  //     backgroundState.blur, backgroundState.none, backgroundState.img,
  //     stateData?.publishStreamObj?.camera
  // ]);

  const onResults = (results: any) => {
    // console.log("On Result");
    if (!contextRef.current || !canvasRef.current) return;
    const context = contextRef.current;

    context.save();
    context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    context.drawImage(
      results.segmentationMask,
      0,
      0,
      canvasRef.current.width,
      canvasRef.current.height
    );

    //Will show exact video without background effect
    contextRef.current.globalCompositeOperation = "source-in";
    contextRef.current.drawImage(
      results.image,
      0,
      0,
      canvasRef.current.width,
      canvasRef.current.height
    );

    //Person (no change)
    if (backgroundState.blur) {
      contextRef.current.globalCompositeOperation = "destination-atop";
      contextRef.current.filter = "blur(10px)";
      contextRef.current.drawImage(
        results.image,
        0,
        0,
        canvasRef.current.width,
        canvasRef.current.height
      );
    } else if (backgroundState.none) {
      contextRef.current.globalCompositeOperation = "destination-atop";
      contextRef.current.drawImage(
        results.image,
        0,
        0,
        canvasRef.current.width,
        canvasRef.current.height
      );
    } else {
      contextRef.current.globalCompositeOperation = "destination-atop";
      contextRef.current.drawImage(
        img,
        0,
        0,
        canvasRef.current.width,
        canvasRef.current.height
      );
    }
    contextRef.current.restore();

    context.restore();
    mediaStreamRef.current = canvasRef.current.captureStream(30);
    mediaStreamRef.current.addTrack(
      videomediaStreamRef.current.getAudioTracks()[0]
    );
  };
  //Background image end - Mediapipe

  //Media api stream start
  async function mediaAPIInitiate() {
    try {
      stopStreamTotally();
      let mediaStream: MediaStream;
      const audioConstraints = !stateData?.publishStreamObj?.muted
        ? {
            deviceId:
              stateData?.publishStreamObj?.speakerdeviceId === undefined
                ? { exact: stateData?.publishStreamObj?.deviceId }
                : {
                    exact: stateData?.publishStreamObj?.deviceId,
                    sinkId: stateData?.publishStreamObj?.speakerdeviceId,
                  },
            echoCancellation: true,
          }
        : { muted: true };
      mediaStream = await navigator.mediaDevices.getUserMedia({
        audio: audioConstraints,
        video: { deviceId: stateData?.publishStreamObj?.cameraId, width:{ideal: 4096}, height:{ideal: 2160} },
      });

      //here i use this speaker change
      if (
        videoRef.current &&
        "setSinkId" in videoRef.current &&
        stateData?.publishStreamObj?.speakerdeviceId
      ) {
        try {
          await (videoRef.current as any).setSinkId(
            stateData?.publishStreamObj?.speakerdeviceId
          );
          // console.log(
          //   `Success, audio output device attached: ${stateData?.publishStreamObj?.speakerdeviceId}`
          // );
        } catch (error) {
          let errorMessage: string = error.message;
          if (error.name === "SecurityError") {
            errorMessage = `You need to use HTTPS for selecting audio output device: ${error.message}`;
          }
        }
      }
      //Background start

      // videoRef.current.srcObject = mediaStream;
      videomediaStreamRef.current = mediaStream;

      //Backgrounds end
      if (videoRef.current) {
        attachSinkId(
          videoRef.current,
          stateData?.publishStreamObj?.speakerdeviceId
        );
        videoRef.current.srcObject = mediaStream;
        videoRef.current.muted = true
      }
    } catch (error) {
      console.error("Error accessing media devices:", error);

      if (error.name === "NotAllowedError") {
        console.warn("User denied permission to access microphone/camera.");
        // Example: Show a message to the user
        // toast.error('Your audio/video permission is denied. Please allow it from your browser settings.');
      } else if (error.name === "OverconstrainedError") {
        console.warn("Media constraints are too restrictive:", error);
        // Example: Inform the user about the overconstrained error
        // toast.error('Media constraints are too restrictive. Please adjust your settings.');
      } else {
        console.error("Other error occurred:", error);
        // Handle other errors as needed
      }
    } finally {
      setLoading(false);
    }
  }
  const pauseStream = () => {
    if (videomediaStreamRef.current) {
      const mediaStream = videoRef.current.srcObject as MediaStream;
      mediaStream?.getVideoTracks().forEach((track) => (track.enabled = false));
      // videomediaStreamRef.current = null;
      if (videoRef.current) {
        videoRef.current.srcObject = mediaStream;
      }
    }
  };

  const stopStreamTotally = () => {
    if (videomediaStreamRef.current) {
      const mediaStream = videoRef?.current?.srcObject as MediaStream;
      mediaStream?.getVideoTracks().forEach((track) => track.stop());
      videomediaStreamRef.current = null;
      if (videoRef.current) {
        videoRef.current.srcObject = mediaStream;
      }
    }
  };

  useEffect(() => {
    if (stateData?.publishStreamObj?.cameraId) {
      mediaAPIInitiate();
    }
  }, [
    stateData?.publishStreamObj?.cameraId,
    stateData?.publishStreamObj?.speakerdeviceId,
    stateData?.publishStreamObj?.deviceId,
  ]);

  // useEffect(()=>{
  //   if(!stateData?.publishStreamObj?.camera){
  //     stopStreamTotally()
  //   }

  // },[stateData?.publishStreamObj?.camera])

  // useEffect(() => {
  //   if (videoRef.current) {
  //     videoRef.current.muted = stateData?.publishStreamObj?.muted;
  //     if (!stateData?.publishStreamObj?.muted) {
  //       videoRef.current.volume = 1;
  //     }
  //   }
  // }, [stateData?.publishStreamObj?.muted]);

  useEffect(() => {
    if (videoRef.current) {
      // videoRef.current.muted = stateData?.publishStreamObj?.muted;
      videoRef.current.muted=true
      if (!stateData?.publishStreamObj?.muted) {
        videoRef.current.volume = 1;
      }
    }
  }, [stateData?.publishStreamObj?.muted]);

  useEffect(() => {
    if (videoRef.current) {
      if (!paused) {
        videoRef.current.play().catch(() => null);
      } else {
        videoRef.current.pause();
      }
    }
  }, [paused]);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.volume = volume;
    }
  }, [volume]);
  //Media api stream end

  //Handle green room start
  const handleGotoGreenRoom = async () => {
    const ew_jwt_token = localStorage.getItem("ew_jwt_token");
    setStreamStatus("greenRoom");
    setAuthToken(ew_jwt_token);
    stopStreamTotally();
    if (stateData.publishStreamObj?.isAdmin) {
      const res = await postRequest(
        "https://test-v2.easywebinar.com/v1/easywebinar/v1/owner/webinar-status",
        {
          webinar_id: stateData?.publishStreamObj.webinarid,
          status: "active",
        }
      );
    }
  };

  async function backgrounds() {
    //await mediaAPIInitiate();
    //await applyBackgrounds();
    // setTimeout(async () => {
    //     const publishStreamObj = new PublishStream();
    //     await publishStreamObj.initialize(
    //         stateData?.publishStreamObj?.userName,
    //         stateData?.publishStreamObj?.streamName,
    //         stateData?.publishStreamObj?.token,
    //         stateData?.publishStreamObj?.cameraId,
    //         stateData?.publishStreamObj?.deviceId,
    //         stateData?.publishStreamObj?.speakerdeviceId,
    //         stateData?.publishStreamObj?.backgroundNone,
    //         mediaStreamRef.current,
    //     );
    //     await publishStreamObj.streamStart(
    //         stateData?.publishStreamObj?.bitrate,
    //         stateData?.publishStreamObj?.camera,
    //         !stateData?.publishStreamObj?.muted,
    //     );
    //     dispatch({
    //         type: 'ADD_CONNECTION',
    //         payload: {
    //             ...stateData.publishStreamObj,
    //             obj: publishStreamObj,
    //         },
    //     })
    // }, 1000);
  }
  //Handle green room end

  //Canvas componenet start
  const CanvasDisplayComponenet = (videoReff: any, canvasReff: any) => (
    <CanvasDisplay
      videoRef={videoReff}
      canvasRef={canvasReff}
      muted={stateData?.publishStreamObj?.muted}
      camera={stateData?.publishStreamObj?.camera}
    />
  );
  //end

  // Backend API call start
  // function updateReduxState(name, email, token, subscription_token, streamName) {
  //     dispatch({
  //         type: '',
  //         payload: {
  //             ...stateData?.publishStreamObj,
  //             userName: name,
  //             userEmail: email,
  //             sid: sid,
  //             did: did,
  //             webinarid: webinarid,
  //             token: token,
  //             subscribeToken: subscription_token,
  //             streamName: streamName,
  //         },
  //     });
  // };

  // useEffect(() => {
  //     (async () => {
  //         setAuthToken('eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE3MTkzMDU3NDQsImV4cCI6MTcxOTMyMDE0NCwiZGF0YSI6eyJ1c2VybmFtZSI6InRlc3QgdXNlciIsInVzZXJfaWQiOjc5MzksImV4cG9ydF9saW1pdCI6IjIwMCJ9fQ.n1TcODVUlwiVi07GVxYbQCYd9XjYRCIAdt8g7XKw6Nw')
  //         const res = await postRequest(
  //             "https://test-v2.easywebinar.com/v1/easywebinar/v1/admin/join",
  //             {
  //                 "webinar_id": webinarid,
  //                 "did": did,
  //                 "sid": sid,
  //             }
  //         );
  //         setuserName(res.data.name);
  //         settoken(res.data.token);
  //         setstreamName(res.data.streams[0].streamName);
  //         setsubscription_token(res.data.subscription_token);
  //         updateReduxState(res.data.name,
  //             res.data.email,
  //             res.data.token,
  //             res.data.subscription_token,
  //             res.data.streams[0].streamName
  //         );
  //         console.log("res.data  ::: ", res.data);
  //     })();
  // }, []);
  // Backend API call end

  //pubnub start
  const pubnub = new PubNub({
    publishKey: "pub-c-21a30db6-d198-4b69-942d-3d4ec07e481d",
    subscribeKey: "sub-c-0892f98e-0dda-49b9-88c5-fa1124a478cb",
    uuid: stateData?.publishStreamObj?.userUUID,
    autoNetworkDetection: true,
    restore: true,
    heartbeatInterval: 300,
  });
  //pubnub end

  return (
    <>
      <PubNubProvider client={pubnub}>
        {loading && <Loader />}
        {streamStatus == "setup" && (
          <PresentorSetup
            videoRef={videoRef}
            canvasRef={canvasRef}
            mediaStreamRef={videoRef.current}
            volume={volume}
            userName={userName}
            streamName={streamName}
            token={token}
            videoBackGroundNone={videoBackGroundNone}
            videoBackGroundBlur={videoBackGroundBlur}
            videoBackGroundImage={videoBackGroundImage}
            handleVolumeChange={handleVolumeChange}
            handleGotoGreenRoom={handleGotoGreenRoom}
            CanvasDisplayComponenet={CanvasDisplayComponenet(
              videoRef,
              canvasRef
            )}
            startStream={mediaAPIInitiate}
            pauseStream={pauseStream}
          />
        )}

        {streamStatus == "greenRoom" && (
          <GreenRoom
            CanvasDisplayComponenet={CanvasDisplayComponenet(
              videoRef,
              canvasRef
            )}
            backgrounds={backgrounds}
            subscription_token={subscription_token}
            stream_name={streamName}
            stopStreamTotally={stopStreamTotally}
          />
        )}

        <div style={{ display: "none" }}>
          <img id="background" src={backImage} alt="background" />
        </div>
      </PubNubProvider>
    </>
  );
};
