import React, { useEffect, useState } from "react";
import Select from "react-select";
import speakerIcon from "../../assets/images/speaker-icon.png";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import loggly from "../ErrorsHandler/Logger"; // Ensure loggly is correctly imported and configured

const AudioOutputSelector = React.memo(() => {
  const [audioOutputDevices, setAudioOutputDevices] = useState<
    MediaDeviceInfo[]
  >([]);
  const dispatch = useDispatch();
  const { state } = useLocation();
  const stateData = useSelector((state: any) => state.PublishStreamReducer);

  useEffect(() => {
    const gotDevices = (deviceInfos: MediaDeviceInfo[]) => {
      try {
        const audioOutputList: MediaDeviceInfo[] = deviceInfos.filter(
          (device) => device.kind === "audiooutput"
        );
        const uniqueDevices = Array.from(
          new Set(audioOutputList.map((device) => device.groupId))
        )
          .map((groupId) => {
            return audioOutputList.find((device) => device.groupId === groupId);
          })
          .filter((device) => device !== undefined) as MediaDeviceInfo[];
        setAudioOutputDevices(uniqueDevices);
        // console.log("unique devices",uniqueDevices)
      } catch (error) {
        console.error("Error processing audio output devices:", error);
        loggly.push({
          tag: "error",
          message: "An error occurred while processing audio output devices",
          error: error.message,
        });
      }
    };

    const handleDeviceChange = () => {
      try {
        loggly.push({ tag: "Speaker", message: "Speaker Changed" });

        navigator.mediaDevices
          .enumerateDevices()
          .then(gotDevices)
          .catch((error) => {
            console.error("Error enumerating audio output devices:", error);
            loggly.push({
              tag: "error",
              message:
                "An error occurred while enumerating audio output devices",
              error: error.message,
            });
          });
      } catch (error) {
        console.error("Error handling audio output device change:", error);
        loggly.push({
          tag: "error",
          message:
            "An error occurred while handling audio output device change",
          error: error.message,
        });
      }
    };

    handleDeviceChange();

    navigator.mediaDevices.addEventListener("devicechange", handleDeviceChange);

    return () => {
      navigator.mediaDevices.removeEventListener(
        "devicechange",
        handleDeviceChange
      );
    };
  }, []); // No dependency needed here

  const handleSelectChange = (
    speakerdeviceId: string,
    speakerlabel: string
  ) => {
    try {
      dispatch({
        type: "SPEAKER_SELECTED_DEVICE_ID",
        payload: {
          ...stateData.publishStreamObj,
          speakerdeviceId: speakerdeviceId,
          speakerlabel: speakerlabel,
        },
      });
    } catch (error) {
      console.error("Error handling speaker device change:", error);
      loggly.push({
        tag: "error",
        message: "An error occurred while handling speaker device change",
        error: error.message,
      });
    }
  };

  // useEffect(() => {
  //   let mediaStream;
  //   const requestCameraPermission = async () => {
  //     try {
  //       mediaStream = await navigator.mediaDevices.getUserMedia({
  //         video: true,
  //       });
  //       // Permission granted
  //     } catch (error) {
  //       // Permission denied
  //       if (error.name === "NotAllowedError") {
  //         toast.error(
  //           "Your Camera permission is denied. Please allow it from your browser settings."
  //         );
  //       }
  //     }
  //   };

  //   requestCameraPermission();
  //   return () => {
  //     mediaStream?.getVideoTracks().forEach((track) => track.stop());
  //   };
  // }, []);

  return (
    <Select
      id="dropdown"
      options={audioOutputDevices.map((device) => ({
        value: device.deviceId,
        label: device.label || `Speaker ${audioOutputDevices.length + 1}`,
      }))}
      value={
        stateData?.publishStreamObj?.speakerdeviceId === undefined
          ? {
              value: audioOutputDevices[0]?.deviceId,
              label: audioOutputDevices[0]?.label || "None",
            }
          : {
              value: stateData?.publishStreamObj?.speakerdeviceId,
              label: stateData?.publishStreamObj?.speakerlabel,
            }
      }
      onChange={(selectedOption) => {
        if (selectedOption) {
          handleSelectChange(selectedOption.value, selectedOption.label);
        }
      }}
    />
  );
});

export default AudioOutputSelector;
