import React, { useState } from 'react';
import '../../assets/styles/main.scss';

export const ConfirmationPopupAttendee = ({ isConfirmationOpen, onCloseConfirmationPopUp,SessionHandle }) => {

    if(!isConfirmationOpen){
        return
    }
    
    return (

        <div className={`modal confirmation-popup ${isConfirmationOpen ? 'active' : ''}`}>
            <div className="modal-background"></div>
            <div className="modal-container with-header">
                <div className="modal-header">
                    <h3 className='flex'>Leave Session</h3>
                    <span className="close-btn" onClick={onCloseConfirmationPopUp}>&times;</span>
                </div>
                <div className="modal-content flex">
                    <div className="confirmation-info">
                    <p>Are you sure you want to leave  the session? Session will continue, You can join back anytime you want.</p>
                        
                    </div>
                </div>
                <div className="modal-footer flex">
                    <div className="flex">
                        <button type="button" className="default-btn" onClick={onCloseConfirmationPopUp}>Cancel</button>
                        <button type="button" className="warning-button" onClick={SessionHandle}>Leave Session</button>
                    </div>
                </div>
            </div>
        </div>
    );
};
