import React, { useState } from 'react';
import Select from 'react-select';
import '../../assets/styles/main.scss';

import cameraSettingsIcon from '../../assets/images/camera-settings.svg';
import audioSettingsIcon from '../../assets/images/audio-settings.svg';
import recordingSettingsIcon from '../../assets/images/recording-settings.svg';
import bgSettingsIcon from '../../assets/images/bg-settings.svg';
import chatSettingsIcon from '../../assets/images/chat-settings.svg';
import { ChatModeration } from './ChatModeration';
import { AccessibiltyPermissions } from './AccessibiltyPermissions';
import { BackgrounSettings } from './BackgroundSettings';
import { AudioSettings } from './AudioSettings';
import { CameraSettings } from './CameraSettings';
import { useDispatch, useSelector } from 'react-redux';
import PublishStream from '../../libs/DolbyStreaming/PublishStream';


interface props {
    togglePopup: () => void,
    activeTabProp : number
}

export const Settings = (
    { togglePopup, activeTabProp }: props
) => {
    //redux 
    const stateData = useSelector((state: any) => state.PublishStreamReducer);
    let obj: PublishStream = stateData?.publishStreamObj?.obj;
    const dispatch = useDispatch();
    const [isOpen, setIsOpen] = useState(false);

    // Invite popup
    const inviteTogglePopup = (event: { preventDefault: () => void; }) => {
        setIsOpen(!isOpen);
    };


    // Settings popup tabs
    const [activeTab, setActiveTab] = useState(activeTabProp);

    const handleTabClick = (tabIndex: React.SetStateAction<number>) => {
        setActiveTab(tabIndex);
    };

    const options = [
        { value: 'option1', label: 'Integrated Webcam' },
        { value: 'option2', label: 'Integrated Webcam 2' },
        { value: 'option3', label: 'Integrated Webcam 3' }
    ];

    const [value, setValue] = useState(50); // Initial value for the slider

    const handleChange = (event: any) => {
        setValue(event.target.value);
    };

    // Toggle button
    const [isToggled, setIsToggled] = useState(stateData?.publishStreamObj?.recording);

    const handleToggle = async () => {
        setIsToggled(!isToggled);
        dispatch({
            type: 'MIRROR_CAMERA_TOGGLE',
            payload: {
                ...stateData.publishStreamObj,
                recording: !isToggled
            },
        });

        if (!isToggled)
            await obj.startRecording();
        else
            await obj.stopRecording();
    };

    return (
        <div className="modal session-settings active">
            <div className="modal-background"></div>
            <div className="modal-container">
                <div className="modal-content flex">
                    <span className="close-btn" onClick={togglePopup}>&times;</span>
                    <div className="settings-left-sidebar">
                        <div className="tabs flex">
                            <h3>Settings</h3>
                            <h4>Device Settings</h4>
                            <button className={`flex ${activeTab === 1 ? 'active' : ''}`} onClick={() => handleTabClick(1)}>
                                <span><img src={cameraSettingsIcon} alt="" /></span>
                                Camera Settings
                            </button>
                            <button className={`flex ${activeTab === 2 ? 'active' : ''}`} onClick={() => handleTabClick(2)}>
                                <span><img src={audioSettingsIcon} alt="" /></span>
                                Audio Settings
                            </button>
                            <button className={`flex ${activeTab === 3 ? 'active' : ''}`} onClick={() => handleTabClick(3)}>
                                <span><img src={recordingSettingsIcon} alt="" /></span>
                                Recording Settings
                            </button>
                            {/* <button className={`flex ${activeTab === 4 ? 'active' : ''}`} onClick={() => handleTabClick(4)}>
                                <span><img src={bgSettingsIcon} alt="" /></span>
                                Background Settings
                            </button> */}

                            <h4>Preferences</h4>
                            <button className={`flex ${activeTab === 5 ? 'active' : ''}`} onClick={() => handleTabClick(5)}>
                                <span><img src={chatSettingsIcon} alt="" /></span>
                                Chat Moderation
                            </button>
                            {/* <button className={`flex ${activeTab === 6 ? 'active' : ''}`} onClick={() => handleTabClick(6)}>
                                <span><img src={bgSettingsIcon} alt="" /></span>
                                Accessibility Permissions
                            </button> */}
                        </div>
                    </div>

                    <div className="settings-right-area">
                        <div className="tab-content">
                            {activeTab === 1 &&
                                <CameraSettings />
                            }
                            {activeTab === 2 &&
                                <AudioSettings />
                            }
                            {activeTab === 3 &&
                                <div className='ew-setting-area-section recording-settings'>
                                    <div className="ew-form-field toggle-field">
                                        <label htmlFor="dropdown">Auto Recording</label>
                                        <span className="label-sub-heading">Turn on auto recording if you wish to record automatically at beginning of webinar
                                            <div className="toggle-container">
                                                <label className="toggle-switch">
                                                    <input type="checkbox" checked={isToggled} onChange={handleToggle} />
                                                    <span className="toggle-slider"></span>
                                                </label>
                                            </div>
                                        </span>
                                    </div>

                                </div>
                            }
                            {activeTab === 4 &&
                                <BackgrounSettings />
                            }
                            {activeTab === 5 &&
                                <ChatModeration />
                            }
                            {activeTab === 6 &&
                                <AccessibiltyPermissions />}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}