import '../../assets/styles/main.scss';
import Select from 'react-select';

export const EasyCastPopup = ({ isEasyCastOpen, onEasyCastClose }) => {

    const options = [
        { value: 'option1', label: 'Integrated Webcam' },
        { value: 'option2', label: 'Integrated Webcam 2' },
        { value: 'option3', label: 'Integrated Webcam 3' }
    ];
    
    return (

        <div className={`modal easycast-popup ${isEasyCastOpen ? 'active' : ''}`}>
            <div className="modal-background"></div>
            <div className="modal-container with-header">
                <div className="modal-header">
                    <h3 className='flex'>Youtube LiveStream</h3>
                    <span className="close-btn" onClick={onEasyCastClose}>&times;</span>
                </div>
                <div className="flex modal-content easycast-info">
                    <div className="ew-form-field only-label">
                        <label>Webinar title on youtube</label>
                        <input type="text" className='input-md disabled' />
                    </div>
                    <div className="ew-form-field only-label">
                        <label htmlFor="dropdown">Privacy</label>
                        <Select
                            id="dropdown"
                            options={options}
                        />
                    </div>
                </div>
                <div className="modal-footer flex">
                    <div className="flex w-full">
                        <button type="button" className="btn primary-button w-full">LiveStream on Youtube</button>
                    </div>
                </div>
            </div>
        </div>
    );
};
