// export function userName(name: string) {
//   let arr = name.split(" ");
//   let result = "";
//   for (let i = 0; i < arr.length; i++) {
//     result += arr[i].charAt(0).toUpperCase();
//   }
//   return result;
// }

export function userName(name: string) {
  let arr = name.split(" ");
  let result = "";

  if (arr.length > 0) {
    result += arr[0].charAt(0).toUpperCase(); // First initial
  }

  if (arr.length > 1) {
    result += arr[arr.length - 1].charAt(0).toUpperCase(); // Last initial
  }

  return result;
}

