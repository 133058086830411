import React, { PropsWithChildren, useEffect } from 'react'

const ChatsContainer:React.FC<PropsWithChildren<{ scrollToBottom: () => void}>> = (
    {children,scrollToBottom}
) => {
    useEffect(() => {
        scrollToBottom();
    },[])
  return (
    <>{children}</>
  )
}

export default ChatsContainer