import React from "react";
import { useEffect, useRef, useState } from "react";
import PublishStream from "../../libs/DolbyStreaming/PublishStream";
import { useSelector } from "react-redux";
import { Tooltip } from "react-tooltip";
import refreshIcon from "../../assets/images/refresh-icon.svg";
import { Spinner } from "@chakra-ui/react";

interface props {
  name: string;
  screenSharedUsername: string;
  streams: any;
  sourceId: string;
}

export const ScreenShare = ({
  name,
  streams,
  sourceId,
  screenSharedUsername,
}: props) => {
  //Redux start
  const stateData = useSelector((state: any) => state.PublishStreamReducer);

  const videoRef = useRef<HTMLVideoElement>(null);

  function stopScreenShare() {
    const obj: PublishStream = stateData?.publishStreamObj?.obj;
    obj.stopSreenShare();
  }

  // Initialise video
  const [loadingVideo, setLoadingVideo] = useState(true);
  const messageRef = useRef(false);

  function reloadTimeInterval() {
    if (messageRef.current == true) reLoad();
    setTimeout(() => {
      reloadTimeInterval();
    }, 5000);
  }

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.srcObject = null;
      videoRef.current.srcObject = streams;
    }
  }, []);

  const reLoad = () => {
    if (videoRef.current) {
      videoRef.current.srcObject = null;
      videoRef.current.srcObject = streams;
    }
  };

  return (
    <div className="presenter-column screen-sharing-user-fullscreen">
      <div className="ew-video-wrapper">
        {loadingVideo && (
          <div className="flex flex-center loader-bg">
            <div className="blur-bg"></div>
            <div className="flex flex-center section-loader">
              <div className="circle-loader"></div>
              <span>Loading...</span>
            </div>
          </div>
        )}
        {loadingVideo && (
          <div className="refresh-screen" onClick={reLoad}>
            <img src={refreshIcon} alt="refresh" data-tooltip-place="top" />
          </div>
        )}
        {screenSharedUsername != name && (
          <div className="ew-live-video">
            <video
              controls={false}
              autoPlay
              loop
              ref={videoRef}
              id={sourceId}
              onError={() => {
                videoRef.current?.error &&
                  console.error(
                    `Video player error: ${videoRef.current?.error}`
                  );
              }}
              onLoadStart={() => setLoadingVideo(true)}
              onPlaying={() => {
                setLoadingVideo(false);
                messageRef.current = false;
              }}
              onStalled={() => {
                console.error("Video is on stalled");
              }}
              onWaiting={() => setLoadingVideo(true)}
              playsInline
            ></video>
          </div>
        )}
        <div className="presenter-name flex flex-center">
          {screenSharedUsername}
        </div>

        {/* {screenSharedUsername == name &&
                    <div className="stop-screen-sharing flex flex-center">
                        <div className="img-wrapper flex flex-center">
                            <svg width="121" height="121" viewBox="0 0 121 121" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M25.1427 17.6426C20.8803 17.6426 16.7925 19.3358 13.7785 22.3498C10.7645 25.3638 9.07129 29.4516 9.07129 33.714V87.2854C9.07129 91.5478 10.7645 95.6357 13.7785 98.6497C16.7925 101.664 20.8803 103.357 25.1427 103.357H65.0684C64.8798 102.296 64.7851 101.22 64.7856 100.143V99.264C64.7856 97.5499 65.1233 95.8526 65.7794 94.269C66.4355 92.6854 67.3971 91.2466 68.6094 90.0347C69.8216 88.8229 71.2607 87.8617 72.8445 87.2061C74.4283 86.5506 76.1258 86.2134 77.8399 86.214H81.3027C79.1884 84.3492 77.5665 81.9918 76.5809 79.3504C75.5953 76.7091 75.2763 73.8655 75.6522 71.0714C76.0281 68.2774 77.0872 65.6192 78.7359 63.3323C80.3845 61.0453 82.5717 59.2003 85.1038 57.9606C87.6358 56.7209 90.4345 56.1248 93.2519 56.2251C96.0693 56.3254 98.8186 57.1191 101.256 58.5357C103.693 59.9523 105.744 61.9482 107.226 64.3465C108.708 66.7449 109.575 69.4717 109.751 72.2854C109.79 72.8769 110.266 73.3569 110.857 73.3569C111.141 73.3569 111.414 73.244 111.615 73.043C111.816 72.8421 111.928 72.5696 111.928 72.2854V33.714C111.928 29.4516 110.235 25.3638 107.221 22.3498C104.207 19.3358 100.119 17.6426 95.857 17.6426H25.1427ZM105.5 73.3569C105.5 76.7668 104.145 80.0371 101.734 82.4482C99.3229 84.8594 96.0527 86.214 92.6427 86.214C89.2328 86.214 85.9625 84.8594 83.5513 82.4482C81.1402 80.0371 79.7856 76.7668 79.7856 73.3569C79.7856 69.9469 81.1402 66.6767 83.5513 64.2655C85.9625 61.8543 89.2328 60.4997 92.6427 60.4997C96.0527 60.4997 99.3229 61.8543 101.734 64.2655C104.145 66.6767 105.5 69.9469 105.5 73.3569ZM116.214 100.143C116.214 108.148 109.481 116.214 92.6427 116.214C75.8042 116.214 69.0713 108.178 69.0713 100.143V99.264C69.0713 94.4212 72.997 90.4997 77.8399 90.4997H107.45C112.293 90.4997 116.214 94.4212 116.214 99.264V100.143Z" fill="#E8E8EA" />
                            </svg>
                        </div>
                        <div className="ew-presenter-title flex flex-center">
                            <div className="name">You are sharing your screen</div>
                            <button type='button' className='flex flex-center button'
                                onClick={stopScreenShare}>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.038 16.2188H1.89136C1.77303 16.2188 1.67386 16.1788 1.59386 16.0988C1.51441 16.0193 1.47469 15.9204 1.47469 15.8021C1.47469 15.6832 1.51441 15.5841 1.59386 15.5046C1.67386 15.4252 1.77303 15.3854 1.89136 15.3854H14.1997L13.3664 14.5521H3.84553C3.46219 14.5521 3.14219 14.4235 2.88553 14.1663C2.6283 13.9096 2.49969 13.5893 2.49969 13.2054V5.03544C2.49969 4.85378 2.54775 4.691 2.64386 4.54711C2.73997 4.40266 2.86275 4.28766 3.01219 4.20211L1.44969 2.63544C1.37191 2.55378 1.33025 2.45794 1.32469 2.34794C1.31914 2.23794 1.36247 2.13711 1.45469 2.04544C1.54358 1.95655 1.64191 1.91211 1.74969 1.91211C1.85747 1.91211 1.9558 1.95655 2.04469 2.04544L17.9547 17.9554C18.0325 18.0332 18.0741 18.1288 18.0797 18.2421C18.0852 18.3554 18.0436 18.4566 17.9547 18.5454C17.8658 18.6343 17.7675 18.6788 17.6597 18.6788C17.5519 18.6788 17.4536 18.6343 17.3647 18.5454L15.038 16.2188ZM8.39636 9.57128L7.74136 8.91044C7.62914 9.03544 7.52886 9.19933 7.44053 9.40211C7.35164 9.60544 7.30719 9.83683 7.30719 10.0963V11.3463C7.30719 11.4652 7.34719 11.5643 7.42719 11.6438C7.50664 11.7232 7.60553 11.7629 7.72386 11.7629C7.84275 11.7629 7.94191 11.7232 8.02136 11.6438C8.1008 11.5643 8.14053 11.4652 8.14053 11.3463V10.2604C8.14053 10.1104 8.16191 9.98211 8.20469 9.87544C8.24747 9.76878 8.31136 9.66739 8.39636 9.57128ZM16.7689 14.3946L11.6997 9.32544L12.3364 8.67378C12.4086 8.601 12.4447 8.52239 12.4447 8.43794C12.4447 8.3535 12.4086 8.27489 12.3364 8.20211L11.3472 7.21378C11.2889 7.15489 11.2258 7.14017 11.158 7.16961C11.0902 7.19905 11.0569 7.25183 11.058 7.32794V8.01294H10.3872L6.09303 3.71878H16.1539C16.5372 3.71878 16.8572 3.84711 17.1139 4.10378C17.3711 4.361 17.4997 4.68128 17.4997 5.06461V13.2063C17.4997 13.4385 17.4416 13.6635 17.3255 13.8813C17.21 14.0985 17.0244 14.2696 16.7689 14.3946Z" fill="white" />
                                </svg>
                                Stop Share
                            </button>
                        </div>
                    </div>
                } */}
      </div>
    </div>
  );
};
