import { useEffect, useState } from "react";
import emptyPollsIcon from "../../assets/images/empty-polls.svg";
import pollsIcon from "../../assets/images/polls-icon-blue.svg";
import deleteIcon from "../../assets/images/delete-icon.svg";
import editIcon from "../../assets/images/edit-icon.svg";
import backIcon from "../../assets/images/close-icon.svg";
import { setAuthToken } from "../../libs/APIs/auth";
import { postRequest, postRequestWithFormdata } from "../../libs/APIs/apiCall";
import { pollStatus } from "./poll-enum";
import { usePubNub } from "pubnub-react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { updateIsSideBar } from "../../Store/Actions/PublishStreamAction";

interface props {
  createPollHandler: () => void;
  updatePollHandler: (value: any) => void;
}
interface PollOption {
  id: number;
  val: string;
}
interface PollData {
  poll_id: number;
  status: string;
  poll_question: string;
  options: PollOption[];
}

interface PollResult {
  [key: number]: number;
}
interface FinalResult {
  pollId: string;
  results: PollResult;
}

export const Polls = ({ createPollHandler, updatePollHandler }: props) => {
  // Settings popup tabs

  const [params, setParams] = useSearchParams();
  const session_id = params.get("sid");

  const stateData = useSelector((state: any) => state.PublishStreamReducer);
  const isAdmin = stateData.publishStreamObj.isAdmin;
  const isModerator = stateData.publishStreamObj.isModerator;
  

  const [activeTab, setActiveTab] = useState(1);
  const [activePoll, setActivePoll] = useState<PollData | null>(null);
  const [pollResult, setPollResult] = useState<FinalResult>({
    pollId: "",
    results: {}
  });
  const [showLoader, setShowLoader] = useState(false)
  const [closedPollsResultPub, setClosedPollsResultPub] = useState([])

  
  const closedPollData = JSON.parse(localStorage.getItem(`closedPolls_${stateData.publishStreamObj.sessionId}`))
  const closedPollResult = JSON.parse(localStorage.getItem(`pollResult_${stateData.publishStreamObj.sessionId}`))

  const handleTabClick = (tabIndex: React.SetStateAction<number>) => {
    setActiveTab(tabIndex);
  };

  const [selectedOption, setSelectedOption] = useState("");

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  function createPollHandle() {
    createPollHandler();
  }

  function updatePoll(poll) {
    updatePollHandler(poll);
  }

  //Pubnub start
  const pubnubobj = usePubNub();
  const [pollsData, setPollsData] = useState([]);
  const pollIds = [];

  const [resultChannelSubscribeAdmin,setResultChannelSubscribeAdmin]=useState(null);

  console.log("pollResult >>", pollResult)
  useEffect(() => {
    let activePoll:any = pollsData.filter(poll => poll.status === pollStatus.open).filter((obj1, i, arr) => 
      arr.findIndex(obj2 => (obj2.id === obj1.id)) === i
    )
    setActivePoll(activePoll[0])
  }, [pollsData])

  useEffect(() => {
    console.log('result channel admin ', resultChannelSubscribeAdmin);
    if(resultChannelSubscribeAdmin !== null) {
      pubnubobj.subscribe({
        channels: [resultChannelSubscribeAdmin]
      });
    }

    const subscribeToResultsForActivePoll = async () => {
      try {
        const globalPollChannel = "Polls." + stateData.publishStreamObj.webinarid + "." + stateData.publishStreamObj.sessionId;
        const res = await pubnubobj.objects.getChannelMetadata({ channel: globalPollChannel });
       
        if (res.data && res.data.custom.activePoll) {
          const activePollId = res.data.custom.activePoll;
          const resultChannel = "results." + stateData.publishStreamObj.sessionId + "." + activePollId;    
          pubnubobj.subscribe({
            channels: [resultChannel]
          });
        }
      } catch (error) {
        console.error("Error fetching poll data:", error);
      }
    };

    subscribeToResultsForActivePoll();

    pubnubobj.addListener({
      message: function(event) {
        const { message } = event;
        console.log('listened messages on attendee side event admin', event.message)
        if (message.type == 'result_publish') {
          console.log("message >>", message)
         
          // if(activePoll && activePoll.poll_id && activePoll.poll_id == message.pollId) {
            let pollResultUpdated =  {
              pollId: message.pollId,
              results: message.results 
            };
            setPollResult(pollResultUpdated);
          // }
        }
      }
    });


  }, [resultChannelSubscribeAdmin])

  useEffect(() => {
    setShowLoader(true)
    pubnubobj.objects
      .getChannelMetadata({
        channel:
          "Polls" +
          "." +
          stateData.publishStreamObj.webinarid +
          "." +
          stateData.publishStreamObj.sessionId,
      })
      .then((res) => {
        // console.log("main Polls channel ", res);
        // setShowLoader(false)
        if (res.data) {
          // console.log('res.data.custom.assignedPoll.', res.data.custom.assignedPoll);
          let assignedPolls: string = res.data.custom.assignedPoll + "";
          if (assignedPolls !== undefined) {
            assignedPolls = JSON.parse(assignedPolls) || [];
            for (let i = 0; i < assignedPolls.length; i++) {
              pollIds.push(Number(assignedPolls[i]));
            }
          }

          // console.log("Assigned Polls :: ", pollIds);

          const transformPollData = (data) => ({
            poll_id: data.custom.poll_id,
            status: data.status,
            poll_question: data.description,
            options: JSON.parse(data.custom.options || "[]"), // Default to empty array if options is undefined
          });
          
          // Function to fetch and update poll data
          const fetchAndUpdatePollsData = async (pollIds, pubnubobj, stateData) => {
            try {
              // Construct the list of promises for fetching poll data
              const pollPromises = pollIds.map((pollId) =>
                pubnubobj.objects.getChannelMetadata({
                  channel: `Polls.${stateData.publishStreamObj.sessionId}.${pollId}`,
                })
              );
          
              // Resolve all promises
              const responses = await Promise.all(pollPromises);
          
              // Transform and update state with all poll data
              setPollsData((prevPollsData) => [
                ...prevPollsData,
                ...responses.map((res) => transformPollData(res.data)),
              ]);
          
              // Hide the loader after data is fetched and state is updated
              setShowLoader(false);
            } catch (error) {
              console.error("Error fetching poll data:", error);
              // Handle error case if needed
              setShowLoader(false);
            }
          };
          
          // Usage example
          fetchAndUpdatePollsData(pollIds, pubnubobj, stateData);
        }
      });
  }, []);
  //Pubnub end

  useEffect(() => {
    if (pollsData.length) {
      const fetchResults = async () => {
        try {
          const results = await Promise.all(
            pollsData
              .filter(poll => poll.status === pollStatus.closed)
              .map(async (poll) => {
                const resultChannel = `results.${stateData.publishStreamObj.sessionId}.${poll.poll_id}`;
                try {
                  const response = await new Promise((resolve, reject) => {
                    pubnubobj.history({ channel: resultChannel, count: 1 }, (status, response) => {
                      if (status.error) {
                        console.error('History Error:', status);
                        reject(status);
                      } else {
                        console.log('Message History:', response);
                        resolve(response.messages[0].entry);
                      }
                    });
                  });
                  return response;
                } catch (error) {
                  console.error(`Failed to fetch result for poll ${poll.poll_id}`, error);
                  return null;
                }
              })
          );
          const successfulResults = results.filter(result => result !== null);
          setClosedPollsResultPub(successfulResults);
        } catch (error) {
          console.error('Error fetching results:', error);
        }
      };
      fetchResults();
    }
  }, [pollsData]);

  
  console.log("closedPollsResultPub >>>>>", closedPollsResultPub)
  
  const publishPoll = (poll) => {


    let sessionId = stateData.publishStreamObj.sessionId;
    let closedPolls = JSON.parse(localStorage.getItem(`closedPolls_${sessionId}`)) || [];
    const updatedClosedPolls = closedPolls.filter(closedPoll => closedPoll.poll_id !== poll.poll_id);
    localStorage.setItem(`closedPolls_${sessionId}`, JSON.stringify(updatedClosedPolls));

    /**
     * updating main channel metadata for active poll update
     */
    let globalPollChannel =
      "Polls" +
      "." +
      stateData.publishStreamObj.webinarid +
      "." +
      stateData.publishStreamObj.sessionId;

    setShowLoader(true)
    pubnubobj.objects
      .getChannelMetadata({
        channel: globalPollChannel,
      })
      .then((response) => {
        setShowLoader(false)
        let activePoll = response.data.custom.activePoll;
        if (activePoll) {
          toast.error(`Only one poll can be active at once`, {
            autoClose: 3000,
          });
        } else {
          let pollIds = response.data.custom.assignedPoll;
        // console.log('saved poll ids in main channel' , pollIds);
        setShowLoader(true)
        pubnubobj.objects
          .setChannelMetadata({
            channel: globalPollChannel,
            data: {
              custom: {
                assignedPoll: pollIds,
                activePoll: poll.poll_id,
              },
            },
          })
          .then((res) => {
            const updatePollStatus = (pollsData, updatedPoll) => 
              pollsData.map((pollItem) =>
                pollItem.poll_id === updatedPoll.poll_id
                  ? { ...pollItem, status: pollStatus.open }
                  : pollItem
              );
            const updatedPollsData = updatePollStatus(pollsData, poll);
            setPollsData(updatedPollsData);
            
            setShowLoader(false)
            // console.log('updated polls data', pollsData);

            pubnubobj.publish({
              channel: globalPollChannel,
              message: { pollId: poll.poll_id, type: "activate_poll" },
            });

            toast.success(`Poll has been activated`, {
              autoClose: 3000,
            });

            setActiveTab(1);
            

            // admin is going to subscribe results channel for latest result
            const resultChannel = "results." + stateData.publishStreamObj.sessionId + "." + poll.poll_id;    
            // pubnubobj.subscribe({ channels: [resultChannel] });
            setResultChannelSubscribeAdmin(resultChannel);

            // console.log('publishing poll channel', `Polls.${stateData.publishStreamObj.sessionId}.${poll.poll_id}`);
            setShowLoader(true)
            pubnubobj.objects
              .setChannelMetadata({
                channel: `Polls.${stateData.publishStreamObj.sessionId}.${poll.poll_id}`,
                data: {
                  status: pollStatus.open,
                },
              })
              .then((response) => {
                setShowLoader(false)
                // console.log("Poll published successfully:", response);
              })
              .catch((error) => {
                setShowLoader(false)
                console.log("channel data get Error:", error);
              });


              
              try {
                let payload = {
                  webinar_id: stateData.publishStreamObj.webinarid,
                  poll_action: 1,
                  sid: stateData.publishStreamObj.sessionId,
                  poll_id: poll.poll_id
                }
                let headers : {
  
                }
                axios({
                  method: "post",
                  url: "https://test-v2.easywebinar.com/v1/easywebinar/v1/admin/session-poll-analytics",
                  data: payload,
                  headers: headers,
                }); 
              } catch (error) {
                
              }

          })
          .catch((err) => {
            setShowLoader(false)
            console.log("Error updating channel data ::: ", err);
          });
        }
      })
      .catch((error) => {
        setShowLoader(false)
        console.error("Error publishing poll:", error);
      });
  };

  const endPoll = (poll, pollResult) => {


    let sessionId = stateData.publishStreamObj.sessionId;

    // Update local storage with closed polls
    let closedPolls = JSON.parse(localStorage.getItem(`closedPolls_${sessionId}`)) || [];
    let updatedClosedPolls = [...closedPolls, poll];
    localStorage.setItem(`closedPolls_${sessionId}`, JSON.stringify(updatedClosedPolls));
    
     // Update local storage with poll results
    let pollResults = JSON.parse(localStorage.getItem(`pollResult_${sessionId}`)) || [];
    let updatedPollResults = [...pollResults, pollResult];
    localStorage.setItem(`pollResult_${sessionId}`, JSON.stringify(updatedPollResults));

    try {
      setResultChannelSubscribeAdmin(null);
      const resultChannel = "results." + stateData.publishStreamObj.sessionId + "." + poll.poll_id;    
      pubnubobj.unsubscribe({ channels: [resultChannel] }); 
    } catch (error) {
      
    }

    setShowLoader(true)
    pubnubobj.objects
      .setChannelMetadata({
        channel: `Polls.${stateData.publishStreamObj.sessionId}.${poll.poll_id}`,
        data: {
          status: pollStatus.closed,
        },
      })
      .then((response) => {
        setShowLoader(false)
        console.log("Poll closed successfully:", response);

        /**
         * updating main channel metadata for active poll update
         */
        let globalPollChannel =
          "Polls" +
          "." +
          stateData.publishStreamObj.webinarid +
          "." +
          stateData.publishStreamObj.sessionId;

        setShowLoader(true)
        pubnubobj.objects
          .getChannelMetadata({
            channel: globalPollChannel,
          })
          .then((response) => {
            setShowLoader(false)
            let pollIds = response.data.custom.assignedPoll;
            // console.log('saved data for poll ids in main channel' , pollIds);
            setShowLoader(true)
            pubnubobj.objects
              .setChannelMetadata({
                channel: globalPollChannel,
                data: {
                  custom: {
                    assignedPoll: pollIds,
                    activePoll: "",
                  },
                },
              })
              .then((res) => {
                const updatedPollsData = pollsData.map((pollItem) =>
                  pollItem.poll_id === poll.poll_id
                    ? { ...pollItem, status: pollStatus.closed }
                    : pollItem
                );
                setPollsData(updatedPollsData);
                setShowLoader(false)

                pubnubobj.publish({
                  channel: globalPollChannel,
                  message: { pollId: poll.poll_id, type: "deactivate_poll" },
                });

                toast.success(`Poll has been deactivated`, {
                  autoClose: 3000,
                });

                setActiveTab(3);
                
                try {
                  let payload = {
                    webinar_id: stateData.publishStreamObj.webinarid,
                    poll_action: 0,
                    sid: stateData.publishStreamObj.sessionId,
                    poll_id: poll.poll_id
                  }
            
                  axios({
                    method: "post",
                    url: "https://test-v2.easywebinar.com/v1/easywebinar/v1/admin/session-poll-analytics",
                    data: payload,
                  }); 
                } catch (error) {
                  
                }

                // console.log('updated polls data', pollsData)
              })
              .catch((err) => {
                console.log("Error updating channel data ::: ", err);
                setShowLoader(false)
              });
          })
          .catch((error) => {
            console.log("channel data get Error:", error);
            setShowLoader(false)
          });
      })
      .catch((error) => {
        console.error("Error publishing poll:", error);
        setShowLoader(false)
      });
  };


  const totalVotes = pollResult && pollResult.results ? Object.values(pollResult.results).reduce((acc, votes) => acc + votes, 0) : 0;
  const dispatch = useDispatch();
  return (
    <div className="Polls-area">
       <div
          className="sidebar-header"
          onClick={() => {
            dispatch(updateIsSideBar(false));
          }}
        >
          <a href="#">
            <img src={backIcon} alt="" />
          </a>
          Polls
        </div>
      <div className="sidebar-tabs-header">
        <div className="tabs flex sidebar-tabs">
          <button
            className={`flex flex-center ${activeTab === 1 ? "active" : ""}`}
            onClick={() => handleTabClick(1)}
          >
            Active
          </button>
          <button
            className={`flex flex-center ${activeTab === 2 ? "active" : ""}`}
            onClick={() => handleTabClick(2)}
          >
            Saved
          </button>
          <button
            className={`flex flex-center ${activeTab === 3 ? "active" : ""}`}
            onClick={() => handleTabClick(3)}
          >
            Closed
          </button>
        </div>
      </div>

      <div className="polls-content-area">
        <div className="tab-content">
          {activeTab === 1 && (
            <div className="polls-inner-container thin-scrollbar">
              {/* empty Polls */}

              {showLoader ? 
              <div className="flex flex-center loader-bg">
                  <div className='blur-bg'></div>
                  <div className='flex flex-center section-loader'>
                      <div className="circle-loader"></div>
                      <span>Loading...</span>
                  </div>
              </div>
              
              : activePoll ? <div>
                <h4 className="tabs-inner-heading">Active Polls</h4>
                
                    <div className="polls-info-main">
                      <h3>
                        {activePoll?.poll_question} <span></span>
                      </h3>
                      <ul>
                      {activePoll?.options?.map((option, opindex) => {
                        const voteCount = pollResult.results?.[option.id] ?? 0;
                        const percentage = totalVotes > 0 ? (voteCount / totalVotes) * 100 : 0;
                        return(
                        <li className="flex" key={option.id || opindex}>
                          <p>
                            {option.val}
                            <div className="polls-percntage">
                              <span style={{ width: `${percentage}%` }}></span>
                            </div>
                          </p>
                          <span>{percentage.toFixed(2)}% Voted</span>
                        </li>
                      )})}
                      </ul>
                      <div className="flex votes-info">
                        <span className="flex flex-center total-views">
                          {totalVotes} voted
                        </span>
                      </div>

                      <button
                        type="button"
                        // className={isAdmin ? "btn warning-button": "btn disabled-button"}
                        // disabled={!isAdmin}
                        className={(isAdmin || isModerator) ? "btn warning-button": "btn disabled-button"}
                        disabled={!(isAdmin || isModerator)}

                        onClick={() => endPoll({...activePoll, session_id}, pollResult)}
                      >
                        End Poll
                      </button>
                    </div>
                  
                </div>
              : pollsData && pollsData.filter((poll) => poll.status === pollStatus.open).length === 0 && <div className="empty-area-msg flex-center">
              <img src={emptyPollsIcon} alt="" />
              <h4>Active Polls</h4>
              <p>
                You’ve no active polls right now. Please publish from the
                existing polls
                {/* <a href="#">create new</a> */}
              </p>
              {/* <button
                onClick={createPollHandle}
                // className="btn primary-button"
                className={(isAdmin || isModerator) ? "btn primary-button" : "btn disabled-button"}
                disabled={!(isAdmin || isModerator)}
              >
                Create Poll
              </button> */}
            </div>}
            </div>
          )}

          {activeTab === 2 && (
            <div className="polls-inner-container thin-scrollbar">
              {/* <h4 className="tabs-inner-heading">Saved Polls</h4> */}
              {pollsData.filter((poll) => poll.status === pollStatus.saved).length > 0 ? (
                pollsData
                  .filter((poll) => poll.status === pollStatus.saved)
                  .map((poll, index) => (
                    <div className="saved-polls-outer" key={index}>
                      <div className="saved-polls-listing">
                        <div className="flex saved-poll">
                          <div className="polls-icon">
                            <img src={pollsIcon} alt="polls icon" />
                          </div>
                          <div className="polls-question-box">
                            <p className="flex">
                              {poll.poll_question}
                              <span className="flex">
                                {/* <a href="#">
                                  <img src={deleteIcon} alt="delete icon" />
                                </a> */}
                                {/* <a
                                  onClick={() => {
                                    updatePoll(poll);
                                  }}
                                >
                                  <img src={editIcon} alt="edit icon" />
                                </a> */}
                              </span>
                            </p>
                            <div className="flex polls-action">
                              <button
                                type="button"
                                // className={`flex flex-center button ${isAdmin ? "primary-button" : "disabled-button"} btn-sm`}
                                // disabled={!isAdmin}
                                className={`flex flex-center button ${
                                  isAdmin || isModerator ? 'primary-button' : 'disabled-button'
                                } btn-sm`}
                                disabled={!((isAdmin || isModerator) && !activePoll)}
                                onClick={() => publishPoll(poll)}
                              >
                                Publish Now
                              </button>
                              {/* <button
                                type="button"
                                className="flex flex-center button outlined-button btn-sm"
                              >
                                Preview
                              </button> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
              ) : (
                <div className="empty-area-msg flex-center">
                <img src={emptyPollsIcon} alt="" />
                <h4>Saved Polls</h4>
                <p>
                  You’ve no polls available right now.
                </p>
                </div>
              )}
              {/* <h4 className="tabs-inner-heading">
                <img src={backIcon} alt="" /> Preview Polls
              </h4>
              <div className="polls-info-main polls-preview">
                <h3>How would you rob a bank</h3>
                <ul>
                  <li className="flex">
                    <label className="radio-input">
                      <input
                        type="radio"
                        value="option1"
                        checked={selectedOption === "option1"}
                        onChange={handleOptionChange}
                      />
                    </label>
                    <p>
                      Go straight in with a gun
                      <div className="polls-percntage"></div>
                    </p>
                  </li>
                  <li className="flex">
                    <label className="radio-input">
                      <input
                        type="radio"
                        value="option2"
                        checked={selectedOption === "option2"}
                        onChange={handleOptionChange}
                      />
                    </label>
                    <p>
                      Seduce the lady clerk to do your bidding
                      <div className="polls-percntage"></div>
                    </p>
                  </li>
                  <li className="flex">
                    <label className="radio-input">
                      <input
                        type="radio"
                        value="option3"
                        checked={selectedOption === "option3"}
                        onChange={handleOptionChange}
                      />
                    </label>
                    <p>
                      Hack the bank managers phone and blackmail him
                      <div className="polls-percntage"></div>
                    </p>
                  </li>
                  <li className="flex">
                    <label className="radio-input">
                      <input
                        type="radio"
                        value="option4"
                        checked={selectedOption === "option4"}
                        onChange={handleOptionChange}
                      />
                    </label>
                    <p>
                      Get back to your 9-5 job
                      <div className="polls-percntage"></div>
                    </p>
                  </li>
                </ul>
                <button type="button" className="btn disabled-button">
                  Submit
                </button>
              </div> */}
            </div>
          )}

          {activeTab === 3 && (
            <div className="polls-inner-container thin-scrollbar">
              {/* <h4 className="tabs-inner-heading">Closed Polls</h4> */}

              {pollsData.filter((poll) => poll.status === pollStatus.closed).length > 0 ? (
                pollsData
                  .filter((poll) => poll.status === pollStatus.closed)
                  .map((poll, index) => {
                    const pollResult = closedPollsResultPub.find((pr) => pr.pollId === poll.poll_id);
                    const totalVotes: any = pollResult
                      ? Object.values(pollResult.results).reduce(
                          (acc: number, votes: number) => acc + votes,
                          0
                        )
                      : 0;
                  return (<div className="polls-info-main" key={index}>
                    <h3>{poll.poll_question}</h3>
                    <ul>
                      {poll.options.map((option, opindex) => {
                        const voteCount = pollResult ? pollResult.results[option.id] || 0 : 0;
                        const percentage = totalVotes > 0 ? (voteCount / totalVotes) * 100 : 0;
                        return (
                          <li className="flex low-vote" key={option.id || opindex}>
                            <p>
                              {option.val}
                              <div className="polls-percntage">
                                <span style={{ width: `${percentage || 0}%` }}></span>
                              </div>
                            </p>
                            <span>{percentage || 0}% Voted</span>
                          </li>
                        );
                      })}
                    </ul>
                    <div className="flex votes-info">
                      <span className="flex flex-center total-views">{totalVotes || 0} votes</span>
                    </div>

                    <button
                      type="button"
                      className={`flex flex-center button ${
                        isAdmin || isModerator ? 'primary-button' : 'disabled-button'
                      } btn-sm`}
                      disabled={!(isAdmin || isModerator)}
                      onClick={() => publishPoll(poll)}
                    >
                      Publish Again
                    </button>
                  </div>)})) : (<div className="empty-area-msg flex-center">
                    <img src={emptyPollsIcon} alt="" />
                    <h4>Closed Polls</h4>
                    <p>
                      You’ve no closed polls.
                    </p>
                    </div>
                )}
              
              {/* using localStorage */}
              {/* {closedPollData?.filter((poll) => poll.session_id === session_id).length > 0 ? (
                closedPollData
                  .filter((poll) => poll.session_id === session_id)
                  .map((poll, index) => {
                    const pollResult = closedPollResult.find((pr) => pr.pollId === poll.poll_id);
                    const totalVotes: any = pollResult
                      ? Object.values(pollResult.results).reduce(
                          (acc: number, votes: number) => acc + votes,
                          0
                        )
                      : 0;

                    return (
                      <div className="polls-info-main" key={index}>
                        <h3>{poll.poll_question}</h3>
                        <ul>
                          {poll.options.map((option, opindex) => {
                            const voteCount = pollResult ? pollResult.results[option.id] || 0 : 0;
                            const percentage = totalVotes > 0 ? (voteCount / totalVotes) * 100 : 0;
                            return (
                              <li className="flex low-vote" key={option.id || opindex}>
                                <p>
                                  {option.val}
                                  <div className="polls-percntage">
                                    <span style={{ width: `${percentage}%` }}></span>
                                  </div>
                                </p>
                                <span>{percentage.toFixed(2)}% Voted</span>
                              </li>
                            );
                          })}
                        </ul>
                        <div className="flex votes-info">
                          <span className="flex flex-center total-views">{totalVotes} votes</span>
                        </div>

                        <button
                          type="button"
                          className={`flex flex-center button ${
                            isAdmin || isModerator ? 'primary-button' : 'disabled-button'
                          } btn-sm`}
                          disabled={!(isAdmin || isModerator)}
                          onClick={() => publishPoll(poll)}
                        >
                          Publish Again
                        </button>
                      </div>
                    );
                  })) : (
                <div className="empty-area-msg flex-center">
                <img src={emptyPollsIcon} alt="" />
                <h4>Closed Polls</h4>
                <p>
                  You’ve no closed polls.
                </p>
                </div>
              )} */}
              

            </div>
          )}
        </div>
      </div>
    </div>
  );
};
// export default Polls;
