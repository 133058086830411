import axios from "axios";

export async function postRequest(url: string, body = {}) {
  try {
    const res = await axios.post(url, body);
    return await res.data;
  } catch (e) {
    console.log(e);
  }
}

export async function postRequestWithFormdata(url: string, form) {
  try {
    const res = await axios({
      method: "post",
      url: url,
      data: form,
      headers: { "Content-Type": "multipart/form-data" },
    });
    return await res;
  } catch (e) {
    console.log(e);
  }
}
