import React from 'react';
import '../../assets/styles/main.scss';
import ewLogo from '../../assets/images/ew-logo-full.svg';
import thankYou from '../../assets/images/thank-you-img.png';

export const ThankYou = () => {

    return (
        <div className='thankyou-page'>
            <div className="container flex flex-center">
                <div className="ew-logo">
                    <img src={ewLogo} alt="" />
                </div>

                <div className="thankyou-info flex flex-center">
                    <div className="thankyou-img">
                        <img src={thankYou} alt="thank you" />
                    </div>

                    <div className="thankyou-content">
                        <h2>Thank You</h2>
                        <p>Thank you for attending the Webinar. We appreciate your time and participation.</p>
                    </div>
                </div>
            </div>
        </div>
    );
};
