// import { useState } from "react";
// import emptyPollsIcon from "../../assets/images/empty-polls.svg";
// import deleteIcon from "../../assets/images/delete-icon.svg";
// import editIcon from "../../assets/images/edit-icon.svg";
// import offerIcon from "../../assets/images/offers-img.svg";
// import CountdownTimer from "../CountdownTimer/CountdownTimer";

// export const Offers = () => {
//   // const [activeTab, setActiveTab] = useState(1);

//   const handleTabClick = (tabIndex: React.SetStateAction<number>) => {
//     setActiveTab(tabIndex);
//   };

//   // Settings popup tabs
//   const [activeTab, setActiveTab] = useState(1);

//   return (
//     <div className="sidebar-info-box">
//       <div className="offers-area">
//         <div className="sidebar-tabs-header">
//           <div className="tabs flex sidebar-tabs offers-tabs-header">
//             <button
//               className={`flex flex-center ${activeTab === 1 ? "active" : ""}`}
//               onClick={() => handleTabClick(1)}
//             >
//               Active
//             </button>
//             <button
//               className={`flex flex-center ${activeTab === 2 ? "active" : ""}`}
//               onClick={() => handleTabClick(2)}
//             >
//               Saved
//             </button>
//           </div>

//           <div className="offers-content-area">
//             <div className="tab-content">
//               {activeTab === 1 && (
//                 <div className="offers-inner-container">
//                   {/* empty offers */}
//                   <div className="empty-area-msg flex-center">
//                     <img src={emptyPollsIcon} alt="" />
//                     <h4>Offers</h4>
//                     <p>
//                       You’ve no active offers right now. Please publish from the
//                       existing offers or <a href="#">create new</a>
//                     </p>
//                     <button className="btn primary-button">
//                       Create Offers
//                     </button>
//                   </div>

//                   <h4 className="tabs-inner-heading">Active Offers</h4>
//                   <div className="offers-info-main">
//                     <div className="offer-preview">
//                       <h3>Offer Headline</h3>
//                       <p>Offer Sub headline</p>
//                       <div className="countdown-timer-offer">
//                         <CountdownTimer />
//                       </div>
//                       <button
//                         type="button"
//                         className="flex flex-center widget-action-registration medium-btn-offer"
//                       >
//                         <span className="material-icons">vpn_key</span>
//                         <span>Get Access Now</span>
//                       </button>

//                       <div className="flex flex-center offers-numbers">
//                         <span className="flex flex-center offer-views">
//                           41 Views
//                         </span>
//                         <span className="offer-clicks">16/41 Clicked</span>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               )}

//               {activeTab === 2 && (
//                 <div className="offers-inner-container">
//                   <h4 className="tabs-inner-heading">Saved Offers</h4>
//                   <div className="offers-info-main">
//                     <div className="saved-offers-listing">
//                       <div className="flex saved-offers">
//                         <div className="offer-icon">
//                           <img src={offerIcon} alt="offer icon" />
//                         </div>
//                         <div className="offers-question-box">
//                           <p className="flex">
//                             How would you rob a bank?
//                             <span className="flex">
//                               <a href="#">
//                                 <img src={deleteIcon} alt="delete icon" />
//                               </a>
//                               <a href="#">
//                                 <img src={editIcon} alt="edit icon" />
//                               </a>
//                             </span>
//                           </p>
//                           <p className="offers-text">
//                             Get free beers delivered to you after this webinar
//                           </p>
//                           <div className="flex offers-action">
//                             <button
//                               type="button"
//                               className="flex flex-center button primary-button btn-sm"
//                             >
//                               Publish Now
//                             </button>
//                             <button
//                               type="button"
//                               className="flex flex-center button outlined-button btn-sm"
//                             >
//                               Preview
//                             </button>
//                           </div>
//                         </div>
//                       </div>

//                       <div className="flex saved-offers">
//                         <div className="offer-icon">
//                           <img src={offerIcon} alt="offer icon" />
//                         </div>
//                         <div className="offers-question-box">
//                           <p className="flex">
//                             How would you rob a bank?
//                             <span className="flex">
//                               <a href="#">
//                                 <img src={deleteIcon} alt="delete icon" />
//                               </a>
//                               <a href="#">
//                                 <img src={editIcon} alt="edit icon" />
//                               </a>
//                             </span>
//                           </p>
//                           <p className="offers-text">
//                             Get free beers delivered to you after this webinar
//                           </p>
//                           <div className="flex offers-action">
//                             <button
//                               type="button"
//                               className="flex flex-center button primary-button btn-sm"
//                             >
//                               Publish Now
//                             </button>
//                             <button
//                               type="button"
//                               className="flex flex-center button outlined-button btn-sm"
//                             >
//                               Preview
//                             </button>
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               )}
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };
// // export default People;


import { useState } from 'react';
import emptyPollsIcon from '../../assets/images/empty-polls.svg';
import deleteIcon from '../../assets/images/delete-icon.svg';
import editIcon from '../../assets/images/edit-icon.svg';
import offerIcon from '../../assets/images/offers-img.svg';
import CountdownTimer from '../CountdownTimer/CountdownTimer';
import closeIcon from '../../assets/images/close-icon.svg';
import { updateIsSideBar } from '../../Store/Actions/PublishStreamAction';
import { useDispatch } from 'react-redux';

export const Offers = () => {
    const dispatch = useDispatch();

// const [activeTab, setActiveTab] = useState(1);

const handleTabClick = (tabIndex: React.SetStateAction<number>) => {
    setActiveTab(tabIndex);
};

// Settings popup tabs
const [activeTab, setActiveTab] = useState(1);

             return (
                <div className="offers-area" >
                    <div className="sidebar-header " onClick={()=>{
                        dispatch(updateIsSideBar(false))
                    }}>
                            <a href="#">
                                <img src={closeIcon} alt="" />
                            </a>
                            Offers
                        </div>
                    <div className="sidebar-tabs-header">
                        <div className="tabs flex sidebar-tabs offers-tabs-header">
                            <button className={`flex flex-center ${activeTab === 1 ? 'active' : ''}`} onClick={() => handleTabClick(1)}>
                                Active
                            </button>
                            <button className={`flex flex-center ${activeTab === 2 ? 'active' : ''}`} onClick={() => handleTabClick(2)}>
                                Saved
                            </button>
                        </div>
                    </div>

                    <div className="offers-content-area">
                        <div className="tab-content">
                            {activeTab === 1 && 
                                <div className='offers-inner-container thin-scrollbar'>
                                    {/* empty offers */}
                                    <div className="empty-area-msg flex-center" style={{display: 'none'}} >
                                        <img src={emptyPollsIcon} alt="" />
                                        <h4>Offers</h4>
                                        <p>You’ve no active offers right now. Please publish from the existing offers or <a href="#">create new</a></p>
                                        <button className='btn primary-button'>Create Offers</button>
                                    </div>

                                    <h4 className="tabs-inner-heading">Active Offers</h4>
                                    <div className="offers-info-main">
                                        <div className="offer-preview">
                                            <h3>Offer Headline</h3>
                                            <p>Offer Sub headline</p>
                                            <div className="countdown-timer-offer">
                                                <CountdownTimer />
                                            </div>
                                            <button type='button' className='flex flex-center widget-action-registration medium-btn-offer'>
                                                <span className='material-icons'>vpn_key</span>
                                                <span>Get Access Now</span>
                                            </button>

                                            <div className="flex flex-center offers-numbers">
                                                <span className='flex flex-center offer-views'>41 Views</span>
                                                <span className='offer-clicks'>16/41 Clicked</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                            {activeTab === 2 && 
                                <div className='offers-inner-container thin-scrollbar'>
                                    <h4 className="tabs-inner-heading">Saved Offers</h4>
                                    <div className="offers-info-main">
                                        <div className="saved-offers-listing">
                                            <div className="flex saved-offers">
                                                <div className='offer-icon'>
                                                    <img src={offerIcon} alt="offer icon" />
                                                </div>
                                                <div className="offers-question-box">
                                                    <p className='flex'>How would you rob a bank?
                                                        <span className='flex'>
                                                            <a href="#"><img src={deleteIcon} alt="delete icon" /></a>
                                                            <a href="#"><img src={editIcon} alt="edit icon" /></a>
                                                        </span>
                                                    </p>
                                                    <p className='offers-text'>
                                                        Get free beers delivered to you after this webinar
                                                    </p>
                                                    <div className="flex offers-action">
                                                        <button type='button' className='flex flex-center button primary-button btn-sm'>Publish Now</button>
                                                        <button type='button' className='flex flex-center button outlined-button btn-sm'>Preview</button>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="flex saved-offers">
                                                <div className='offer-icon'>
                                                    <img src={offerIcon} alt="offer icon" />
                                                </div>
                                                <div className="offers-question-box">
                                                    <p className='flex'>How would you rob a bank?
                                                        <span className='flex'>
                                                            <a href="#"><img src={deleteIcon} alt="delete icon" /></a>
                                                            <a href="#"><img src={editIcon} alt="edit icon" /></a>
                                                        </span>
                                                    </p>
                                                    <p className='offers-text'>
                                                        Get free beers delivered to you after this webinar
                                                    </p>
                                                    <div className="flex offers-action">
                                                        <button type='button' className='flex flex-center button primary-button btn-sm'>Publish Now</button>
                                                        <button type='button' className='flex flex-center button outlined-button btn-sm'>Preview</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            );
        }
// export default Offers;
